import React, { useEffect, useState, useRef } from 'react'
import { getCaller, postCaller } from '../../services/api';
import { motion } from "framer-motion";
import './result.css'
import MatchResultTable from './racingResultTable';
import Button from '../../component/button/Button';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select'
import moment from 'moment';
import Result from '../result/result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const RacingResult = () => {
    const [sportList, setSportList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [marketList, setMarketList] = useState([]);
    const [resultList, setResultList] = useState([]);

    const [venue, setVenue] = useState();
    const [sport, setSport] = useState("");
    const [country, setCountry] = useState("");
    const [eventId, setEventId] = useState("");
    const [marketId, setMarketId] = useState("");
    const [resultId, setResultId] = useState("");
    const [marketExternalId, setMarketExternalId] = useState("");

    const [resultData, setResultData] = useState([]);
    const [scope, setScope] = useState({});
    const [isOpen, setOpen] = useState(false);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [fetchedPages, setFetchedPages] = useState({});
    const perPage = 12;

    const sportRef = useRef();
    const eventRef = useRef();
    const marketRef = useRef();
    const countryRef = useRef();
    const venueRef = useRef();

    useEffect(() => {
        const { result } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({result, userType});

        async function getSport() {
            let sportResponse = await getCaller('api/v1/racing/sports');
            setSportList(sportResponse.data);
        }
        getSport();
    }, []);

    useEffect(() => {
        const found = marketList.find(m => m.id === marketId);
        if (found) {
            setMarketExternalId(found.market_id);
        } else {
            setMarketExternalId("");
        }
    }, [marketId, marketList]);

    const resetSelections = (fields = []) => {
        const fieldActions = {
            market: () => setMarketId(""),
            result: () => setResultId(""),
            sport: () => sportRef.current?.setValue(""),
            country: () => countryRef.current?.setValue(""),
            venue: () => venueRef.current?.setValue(""),
            event: () => eventRef.current?.setValue("")
        };

        fields.forEach(field => fieldActions[field]?.());
    };    

    const handleSport = async (event) => {
        const sport = event.value;
        resetSelections(["country", "venue", "event", "market", "result"]);

        if (sport) {
            setSport(sport);
            const Response = await getCaller(`api/v1/racing/country/all?sportId=${sport}`);
            setCountryList(Response.data);
        }
    };

    const handleCountry = async (event) => {
        const country = event.value;
        resetSelections(["market", "result", "venue", "event"]);

        if (country) {
            setCountry(country);
            const response = await getCaller(`api/v1/racing/venue/country/${sport}?countryCode=${country}`);
            setVenueList(response.data);
        }
    };

    const handleVenue = async (event) => {
        const venue = event.value;
        resetSelections(["market", "result", "event"]);

        if (venue) {
            setVenue(venue);
            const response = await getCaller(`api/v1/racing/events/venue/${venue}`);
            setEventList(response.data);
        }
    };

    const handleEvent = async (event) => {
        const eventValue = event.value;
        resetSelections(["market", "result"]);
        
        if (eventValue) {
            setMarketId(eventValue);
            const marketFindResponse = await getCaller(`api/v1/racing/markets/event/${eventValue}`);
            setMarketList(marketFindResponse.data);

            let eventDetail = eventList.find(event => event.id === eventValue);
            setEventId(eventDetail?.id);
        }
    };

    const handleMarket = async (event) => {
        const market = event.value;
        setResultList([]);
        resetSelections(["result"]);
        setMarketId(market);

        const marketData = marketList.find(m => m.id === parseInt(market));
        setResultList(JSON.parse(marketData?.runners));
    };

    const handleResult = (event) => {
        setResultId(JSON.parse(event.value));
    };

    const addResult = async () => {
        const result = resultId;
        const marketData = marketList.find(m => m.id === parseInt(marketId));

        const data = {
            sport_id: parseInt(sport),
            match_id: eventId,
            market_id: marketId,
            result: JSON.stringify(resultId),
            market_name: marketData.market_name,
            selection: result.RN,
        };

        await showConfirmationDialog(`You want to Submit this Result (${data.selection})`).then(async (responseData) => {
            if (responseData.isConfirmed) {
                const response = await postCaller(`api/v1/results`, data);
                if (response.success === true) {
                    showSuccessMessage('Your Result is Submitted Successfully');
                    setResultId('');
                    setMarketId('');
                    setMarketList((prev) => prev.filter((el) => el.id !== marketId));
                    await allResult();
                } else {
                    showToast (response.message , true);
                }
            }
        })
    };

    const allResult = async (page = 1) => {
        if (fetchedPages[page]) {
            setResultData(fetchedPages[page]);
            if (page !== 1) return;
        }
        const resultResponse = await getCaller(`api/v1/results/racing/all?page=${page}&perPage=${perPage}`);
        setResultData(resultResponse.data.result);
        setTotalPages(resultResponse.data.totalCount);
        setFetchedPages((prev) => ({ ...prev, [page]: resultResponse.data.result }));
    };

    useEffect(() => {
        allResult();
    }, []);
    
    return (
        <>  
            <Result />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content className-fix flexFix-width justify-start">
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Sport </label> <br />
                            <Select placeholder="Select Sport" onChange={(selectedOption) => handleSport(selectedOption)} options={sportList.map((el) => ({ value: el.id, label: el.name }))} ref={sportRef}/>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Country </label> <br />
                            <Select placeholder="Select Country" onChange={(selectedOption) => handleCountry(selectedOption)} options={countryList.map((el) => ({ value: el.code, label: el.code }))} ref={countryRef} />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Venue </label> <br />
                            <Select placeholder="Select Venue" onChange={(selectedOption) => handleVenue(selectedOption)} options={venueList.map((el) => ({ value: el.id, label: el.name }))} ref={venueRef}/>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Event </label> <br />
                            <Select placeholder="Select Event" onChange={(selectedOption) => handleEvent(selectedOption)} options={eventList.map((el) => ({ value: el.id, label: el.event_name }))} ref={eventRef}/>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <Select placeholder="Select Market" value={marketId ? marketList.find(el => el.id === marketId) ? { value: marketId, label: marketList.find(el => el.id === marketId)?.market_name } : null : null}
                            onChange={(selectedOption) => handleMarket(selectedOption)} options={marketList.map((el) => ({ value: el.id, label: `${el.market_name} (${moment.utc(el.market_time).local().format('DD-MM-YYYY HH:mm:ss')})` }))} ref={marketRef}/>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Result </label> <br />
                            <Select placeholder="Select Result" value={resultId ? { value: resultId, label: resultList.find(el => el.SID === resultId.SID)?.RN } : null} onChange={(selectedOption) => handleResult(selectedOption)}
                                options={(resultList?.length > 0) ? [
                                    ...resultList?.map((el) => ({ value: JSON.stringify(el), label: el.RN })),
                                    { value: JSON.stringify({ RN: 'tie', SID: 'tie' }, 1), label: 'Tie' },
                                    { value: JSON.stringify({ RN: 'abandoned', SID: 'abandoned' }, 1), label: 'Abandoned' },
                                ] : []}
                            />
                        </div>
                    </div>
                    <div className="book-maker-btn">
                        <Button className='submit' type='button' name='Submit Result' disabled={!(resultId !== "" && (scope?.userType !== 'manager' || scope?.result?.add))} onClick={() => { setOpen(true) }} />
                    </div>
                </div>
                <MatchResultTable resultData={resultData} setResultData={setResultData} page={page} setPage={setPage} totalPages={totalPages} perPage={perPage} allResult={allResult}/>
            </motion.div>
            <SurveillanceModel isOpen={isOpen} setOpen={setOpen} onCloseModel={addResult} matchID={eventId} marketID={marketExternalId} marketType={1}></SurveillanceModel>
        </>
    )
};

export default RacingResult;