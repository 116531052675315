import React from 'react';

export const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        ref={ref}
        value={value}
        onClick={onClick}
        readOnly
        className="custom-date-picker"
    />
));