import { io } from 'socket.io-client';
import { useEffect, useRef } from 'react';

const SocketComponent = ({ eventID, onDataUpdate }) => {
    const URL = process.env.REACT_APP_BASE_URL;
    const socketRef = useRef(null);

    useEffect(() => {
        if (!URL) return;

        socketRef.current = io(URL, {
            transports: ["websocket"],
            autoConnect: false,
        });

        const socket = socketRef.current;

        const handleConnect = () => {
            console.log('WebSocket connected!');
            if (eventID) {
                socket.emit('sub', eventID);
            }
        }

        const handleDisconnect = () => {
            console.warn("Disconnected from WebSocket");
        };

        const handleError = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);
        socket.on('connect_error', handleError);

        socket.on("App\\\\Events\\\\RejectedBetsBroadcastData", (data) => {
            try {
                onDataUpdate(data);
            } catch (error) {
                console.error("Failed to parse rawData:", data, error);
            }
        });

        const handleBroadcastData = (data, type) => {
            try {                
                onDataUpdate(data, type);
            } catch (error) {
                console.error("Failed to parse rawData:", data, error);
            }
        };
        
        socket.on("App\\\\Events\\\\SportsBroadcastData", (data) => {
            handleBroadcastData(data, 1);
        });

        socket.on("App\\\\Events\\\\BroadcastBookmaker", (data) => {
            handleBroadcastData(data, 2);
        });

        socket.on("App\\\\Events\\\\BroadcastFancy", (data) => {
            handleBroadcastData(data, 3);
        });

        socket.on("App\\\\Events\\\\BroadcastFancyUpdates", (data) => {
            handleBroadcastData(data, "fancy");
        });

        socket.on("App\\\\Events\\\\BroadcastBookmakerUpdates", (data) => {
            console.log("BroadcastBookmakerUpdates ===>",data);
            handleBroadcastData(data, "bookmaker");
        });

        socket.connect();

        return () => {
            if (socket) {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('connect_error', handleError);
                socket.off('App\\\\Events\\\\RejectedBetsBroadcastData');
                socket.off('App\\\\Events\\\\SportsBroadcastData');
                socket.off('App\\\\Events\\\\BroadcastBookmaker');
                socket.off('App\\\\Events\\\\BroadcastFancy');
                socket.off('App\\\\Events\\\\BroadcastFancyUpdates');
                socket.off('App\\\\Events\\\\BroadcastBookmakerUpdates');
                socket.disconnect();
            }
        };
    }, [URL, eventID]);

    return null;
};

export default SocketComponent;

