import moment from 'moment';
import Select from 'react-select';
import Bets from '../bets/betsTab';
import { motion } from "framer-motion";
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import ClientExposure from './ClientExposure';
import { AiOutlineClose } from 'react-icons/ai';
import { MdContentCopy  } from 'react-icons/md';
import CONSTANTS from '../../utilities/constants';
import Button from '../../component/button/Button';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast } from '../../utilities/toastHelper';
import { CustomInput } from '../../utilities/customInput';
import { getCaller, postCaller } from '../../services/api';
import Paginations from '../../component/pagination/Paginations';

const ClientStatements = () => {
    const [selectedType, setSelectedType] = useState({ value: 0, label: 'All' });
    const [startDate, setStartDate] = useState(moment().subtract(14, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [searchInput, setSearchInput] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [canShowDropdown, setCanShowDropdown] = useState(false);
    const [clientStatements, setClientStatements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageEvents, setPerPageEvents] = useState(200);
    const [totalPageEvents, setTotalPageEvents] = useState(0);
    const [currentPageEvents, setCurrentPageEvents] = useState(1);
    const [userId, setUserId] = useState(null);
    const [dynamicHeading, setDynamicHeading] = useState({});
    const [dynamicData, setDynamicData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOperatorType, setSelectedOperatorType] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [copied, setCopied] = useState(false);
    const [exposureModalOpen, setExposureModalOpen] = useState(false);
    const [selectionBetType, setSelectionBetType] = useState('all');
    const [initialClientData, setInitialClientData] = useState([]);
    const operatorList = [{ value: 'CLICKBET', label: 'CLICKBET' }, { value: 'CLICKBETOLD', label: 'CLICKBET OLD' }, { value: 'VICTORY', label: 'VICTORY' }]; //CONSTANTS.OPERATOR_LIST;
    
    const formatDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("YYYY-MM-DD");
    };

    const typeOptions = [
        { value: 0, label: 'All' },
        { value: -1, label: 'Balance Report' },
        { value: 4, label: 'Cricket' },
        { value: 1, label: 'Soccer' },
        { value: 2, label: 'Tennis' },
        { value: 496, label: 'Casino' },
        { value: 5, label: 'Poker' },
        { value: 16, label: 'Matka' },
        { value: 1000, label: 'Virtual' },
        { value: 498, label: 'Sports Book' }
    ];

    const selectionTypeList = [
        { value: 'all', label: 'All' },
        { value: 'back', label: 'Back' },
        { value: 'lay', label: 'Lay' },
    ];

    const handleDatesChange = async (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const fetchUsers = async (query) => {
        if (!query || query.length < 3) {
            setDropdownOptions([]);
            return;
        }

        try {
            const response = await getCaller(`api/v1/client-statement/searchUser/${selectedOperatorType.value}?search=${query}`);
            if (response.success) {
                setDropdownOptions(response.data);
            } else {
                setDropdownOptions([]);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setDropdownOptions([]);
        }
    };

    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        setCanShowDropdown(false);
        setUserId('');
        setUserInfo(null);
    };

    const handleSearchClick = async () => {
        if (!selectedOperatorType) {
            showToast("Please select a Operator!", true);
            return;
        }

        if (searchInput.length < 3) {
            showToast("Enter at least 3 characters!", true);
            return;
        }
    
        await fetchUsers(searchInput);
        setCanShowDropdown(true);
    };

    const handleUserSelect = (user) => {
        setSearchInput(user.userid);
        setCanShowDropdown(false);
        setUserId(user.id);

        const filteredUserInfo = Object.fromEntries(
            Object.entries({
                id: user.userid,
                balance: parseFloat(user.balance).toFixed(2),
                ul: user.ul,
                bl: user.bl,
                wl: user.wl
            }).filter(([key, value]) => value === "1" || key === "id" || key === "balance")
        );
    
        setUserInfo(filteredUserInfo);
        setPerPageEvents(200);
        setCurrentPageEvents(1);
        setTotalPageEvents(0);
    };

    const handleChangeOperator = async (selectedOperator) => {
        setSelectedOperatorType(selectedOperator);
        setSearchInput('');
        setDropdownOptions([]);
        setUserInfo(null);
        setUserId(null);
        setPerPageEvents(200);
        setCurrentPageEvents(1);
        setTotalPageEvents(0);
        setClientStatements([]);
    };

    const fetchClientStatements = async (page = 1, limit = perPageEvents) => {
        const requestData = {
            from: formatDate(startDate),
            to: formatDate(endDate),
            ...(selectedType && { type: selectedType.value }),
            user_id: userId
        };
    
        try {            
            const response = await postCaller(`api/v1/client-statement/fetchClientStatements/${selectedOperatorType.value}?page=${page}&limit=${limit}`, requestData);
            if (response.success) {
                setClientStatements(response.data);                
                const { last_page = 0, current_page = 1, per_page = 200 } = response?.pagination;
                setPerPageEvents(per_page);
                setCurrentPageEvents(current_page);
                setTotalPageEvents(last_page);
            } else {
                setClientStatements([]);
                setTotalPageEvents(0);
            }
        } catch (error) {
            console.error('Error fetching client statements:', error);
            setClientStatements([]);
        }
    };

    const openModal = async (statementId) => {
        try {
            const response = await getCaller(`api/v1/client-statement/fetchStatementsInfo/${selectedOperatorType.value}?id=${statementId}`);
            if (response.success) {
                setDynamicHeading(response.data.keys || {});
                setDynamicData(response.data.statement_info || []);
                setIsModalOpen(true);
                setInitialClientData(response.data.statement_info || []);
            }
        } catch (error) {
            console.error("Failed to fetch client statement.", error);
        }
    };

    const handleBetTypeChange = (selectedOption) => {
        const selectedValue = selectedOption?.value ?? 'all';
        setSelectionBetType(selectedValue);
    
        if (selectedValue === 'all') {
            setDynamicData(initialClientData);
        } else {
            setDynamicData(initialClientData.filter(item => item.selection_type === selectedValue));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectionBetType("all");
    };
    
    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPageEvents) {
            setCurrentPage(page);
            await fetchClientStatements(page);
        }
    };

    let hierarchyChain = dropdownOptions.find(user => user.id === userId)?.ancestors || [];
    const formattedHierarchy = [...hierarchyChain, userInfo?.id].join('  ->  ');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(formattedHierarchy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        });
    }; 

    const handleExposureButtonClick = () => {
        setExposureModalOpen(true);
    };

    return (
        <>
            <Bets />
            <div className="result-sport-container">
                <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px', alignItems: 'flex-end' }}>
                    <div className="select-container" style={{ maxWidth: '280px' }}>
                        <label htmlFor="sport"> Select Operator </label>
                        <Select placeholder="Select Operator" options={operatorList} value={selectedOperatorType} onChange={handleChangeOperator} isClearable={true}/>
                    </div>

                    <div className="select-container" style={{ position: 'relative', maxWidth: '280px' }}>
                        <label htmlFor="search">Search User</label>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}> 
                            <input type="search" autoComplete="off" className="result-input" name="user" placeholder="Search User" value={searchInput} onChange={handleSearchInputChange}/>
                            <div className="custom-btn">
                                <Button className={'submit'} name={'Search'} type="button" onClick={handleSearchClick} />
                            </div>
                        </div>

                        {canShowDropdown && dropdownOptions.length > 0 && (
                            <motion.ul className="dropdown-list" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                                {dropdownOptions.map((user) => (
                                    <li className="dropdown-item" key={user.id} onClick={() => handleUserSelect(user)} > 
                                        {user.userid} 
                                    </li>
                                ))}
                            </motion.ul>
                        )}
                    </div>

                    <div className="select-container" style={{ maxWidth: '280px' }}>
                        <label htmlFor="sport">Select Type</label> <br />
                        <Select placeholder="Select Type" options={typeOptions} value={selectedType} onChange={handleTypeChange} isClearable={true} />
                    </div>

                    <div className="select-container containerStyle" style={{ maxWidth: '240px' }}>
                        <label htmlFor="date" id="date-label">Select Date</label> <br />
                        <DatePicker
                            selected={startDate}
                            onChange={handleDatesChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            dateFormat="dd/MM/yyyy"
                            dropdownMode="select"
                            customInput={<CustomInput />}
                        />
                    </div>

                    <div className="wrapper-content">
                        <div className="custom-btn">
                            <Button className={'submit'} name={'Submit'} type="button" onClick={() => fetchClientStatements(1)} disabled={!selectedType || !startDate || !endDate || !searchInput || !selectedOperatorType || !userId} />
                        </div>
                    </div>
                </div>
            </div>
                
            {userInfo && (
                <motion.div className="user-info" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                    <div className="info-box">User ID: {userInfo.id}</div>
                    <div className="info-box">Balance: {userInfo.balance}</div>

                    {userInfo.ul && <div className="info-box locked">User Lock</div>}
                    {userInfo.bl && <div className="info-box locked">Bet Lock</div>}
                    {userInfo.wl && <div className="info-box locked">Withdrawal Lock</div>}

                    <button className="exposure-btn" onClick={handleExposureButtonClick}>Exposure</button>
                    <ClientExposure isOpen={exposureModalOpen} onClose={() => setExposureModalOpen(false)} userName={userInfo?.id} operator={selectedOperatorType.value}/>

                    <div className='hierarchy-wrap'>
                        <div className="hierarchy-box">
                            <span className="hierarchy-text"><span className="hierarchy-label">Hierarchy:</span>  {formattedHierarchy}  </span>
                            <button className="copy-buttons" onClick={copyToClipboard}>
                                {copied ? 'Copied' : <MdContentCopy style={{ fontSize: '1.2rem' }} />}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )}

            <div className='tableResponsive-wrap borderStyle' style={{whiteSpace: 'nowrap', margin: '10px'}}>
                <table width="100%" className="odds-gap-table-client">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th style={{ textAlign: 'center' }}>Date</th>
                            <th style={{ textAlign: 'center' }}>Description</th>
                            <th>P&L</th>
                            <th>Balance</th>
                            <th>Remark</th>
                            <th>Remark Image & UTR</th>
                            <th>Code Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientStatements.length > 0 ? (
                            clientStatements.map((statement, index) => (
                                <tr key={index}>
                                    <td>{index + 1 + (currentPage - 1) * perPageEvents}</td>
                                    <td>{statement.date}</td>
                                    <td style={{ textAlign: 'center' }}> { !statement.is_txn_id && !statement.is_clickable ? <span style={{ color: '#2b2a2a' }}> {statement.des}</span> : <button className="link-button-sta" onClick={() => openModal(statement.statement_id)}>{statement.des}</button>}</td>
                                    <td style={{ color: statement.pl < 0 ? 'red' : 'green' }}>{Math.abs(statement.pl).toFixed(2)}</td>
                                    <td style={{ color: statement.balance < 0 ? 'red' : 'green' }}>{Math.abs(statement.balance).toFixed(2)}</td>
                                    <td>{statement.remark}</td>
                                    <td></td>
                                    <td>{statement.masterCode}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8"><ErrorBoundary text='Statement' /></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Paginations page={currentPageEvents} perPage={perPageEvents} setPage={handlePageChange} setPerPage={setPerPageEvents} maximo={totalPageEvents} />

            <>
                {isModalOpen && (
                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.6)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1050 }}>
                        <div className="modal-content" style={{ minHeight: '50vh', position: 'relative', display: 'flex', flexDirection: 'column', minWidth: '60vw', background: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                            <div className="wrapper-body">
                                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h2>Statement Info</h2>

                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        {Object.keys(dynamicHeading).includes('selection') &&
                                            <div style={{ width: '200px' }}>
                                                <Select value={selectionTypeList.find(option => option.value === selectionBetType)} onChange={handleBetTypeChange} options={selectionTypeList} />
                                            </div>
                                        }
                                        <button className="ai-close closeBtn-modal" onClick={closeModal} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>
                                            <AiOutlineClose />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='tableResponsive-wrap' style={{ overflowY: 'auto', maxHeight: '50vh' }}>
                                <table className="odds-gap-table table-border-1">
                                    <thead>
                                        <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '15px', backgroundColor: '#e0ebeb' }}>
                                            {Object.values(dynamicHeading).map((heading, index) => (
                                                dynamicHeading['selection_type'] !== heading ? <th key={index} style={{ textAlign: 'center' }}>{heading}</th> : null
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dynamicData.map((data, index) => (
                                            <tr key={index} className={data?.selection_type + ' custom-row'}>
                                                {Object.keys(dynamicHeading).map((key) => (
                                                    key !== 'selection_type' ? <td key={key}>{key === 'bet_timestamp_date' ? moment.unix(data[key]).format('YYYY-MM-DD HH:mm:ss') : data[key]}</td> : null
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};

export default ClientStatements;