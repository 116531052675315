import moment from 'moment';
import '../addEvent/addevent.css';
import Select from 'react-select';
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../error/ErrorBoundary';
import { CustomInput } from '../../utilities/customInput';
import { getCaller, postCaller } from '../../services/api';
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../utilities/toastHelper';

const AddRacingEvent = () => {
    const [countryList, setCountryList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().add(5, 'days').startOf('day').toDate());
    const [selectedCountry, setSelectedCountry] = useState("");
    const [venue, setVenue] = useState("");
    const [eventList, setEventList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState("");
    const [checkError, setCheckError] = useState('');

    const minDate = moment().subtract(3, 'days').startOf('day').toDate();

    const handleDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("MM/DD/YYYY");
    };

    useEffect(() => {
        async function getSport() {
            let sportResponse = await getCaller('api/v1/racing/sports');
            setSportList(sportResponse?.data);
        }
        getSport();
    }, []);

    async function handleSport(event) {
        setSport(event.value);
        setSelectedCountry("");
        setVenue("");
        setCountryList([]);
        setVenueList([]);
        setEventList([]);
        setChecked([]);

        if (!event.value) {
            return;
        } else {
            await getCountryList(event.value);
        }
    }

    async function getCountryList(sportId) {
        try {
            if (startDate && endDate && sportId) {
                const data = {
                    from: handleDate(startDate),
                    to: handleDate(endDate),
                    sportId
                }
                const response = await postCaller(`api/v1/racing/events/countrylist`, data);
                setCountryList(response?.data.filter((el) => el.marketCount !== 0));
            }
        } catch (error) {
            setCountryList([]);
        }
    }

    async function handleVenue(selectedOption) {
        setSelectedCountry(selectedOption);
        setVenue("");
        setVenueList([]);
        setEventList([]);
        setChecked([]);
        if (selectedOption.value === "") {
            return;
        } else {
            
            await getVenueList(selectedOption.value);
        }
    }
    async function getVenueList(selectedCountry) {
        try {
            let data = {
                from: handleDate(startDate),
                to: handleDate(endDate),
                countryCode: selectedCountry,
                sportId: sport
            }
            const leagueResponse = await postCaller(`api/v1/racing/events/venuelist`, data);
            setVenueList(leagueResponse.data);
        } catch (error) {
            setVenueList([]);
        }
    }
    async function handleLeague(selectedOption) {
        
        setVenue(selectedOption);
        if (selectedOption.value === "") {
            return;
        } else {
            await getEvents(selectedOption.value);
        }
    }


    async function getEvents(selectedVenue) {
        try {
            setChecked([]);
            let leagueData = {
                from: handleDate(startDate),
                to: handleDate(endDate),
                countryCode: selectedCountry.value,
                venue: selectedVenue,
                sportId: sport
            }
            setLoading(true);

            const eventResponse = await postCaller(`api/v1/racing/events`, leagueData);
            setEventList(eventResponse.data)
            setChecked(eventResponse.data.map(e => parseInt(e.event.id)))
            if (eventResponse.success === true) {
                setLoading(false);
            }
            else {
                showToast(eventResponse.message, true);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }
    const handleMatchCheck = (event, value) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
        }
        setChecked(updatedList);
    };

    const handleAddMatch = async (event) => {
        event.preventDefault();

        if (!checked.length){
            setCheckError('Please Checked');
            return false;
        }

        let body = {
            from: handleDate(startDate),
            to: handleDate(endDate),
            events: checked ?? []
        }
        
        await showConfirmationDialog(`You want to Add these Matches?`)
        .then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const res = await postCaller(`api/v1/racing/events/management/addEvents?countryCode=${selectedCountry.value}&venue=${venue.value}&sportId=${sport}`, body);
                if (res.success) {
                    showSuccessMessage('Your Match Added Successfully');
                    //setSport("");
                    setSelectedCountry("");
                    setVenue("");
                    setLoading(false);
                    setChecked([]);
                    setEventList([]);
                    setVenueList([]);
                }
            }
        }).catch(err => console.error(err))
    }

    const handleDatesChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSelectedCountry("");
        setVenue("");
        setSport("");
        setCountryList([]);
        setVenueList([]);
        setEventList([]);
        setChecked([]);
    };

    return (
            <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="wrapper-head">
                    <h3> ADD RACING EVENTS </h3>
                </div>
                <div className="result-sport-container">
                    <div className="result-sport-content" style={{ 'justifyContent': 'flex-start', gap: '15px' }}>
                        <div className="select-container containerStyle" style={{ maxWidth: '240px' }}>
                            <label htmlFor="date" id="date-label"> Select Date </label> <br />
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatesChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                minDate={minDate}
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                customInput={<CustomInput />}
                            />
                        </div>

                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="sport"> Select Racing Sport </label> <br />
                            <Select placeholder="Select Racing Sport" value={sport ? { value: sport, label: sportList.find(e=> e.id === sport)?.name} : null} options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                onChange={async (selectedOption) =>  {
                                    setSport(selectedOption);
                                    await handleSport(selectedOption);
                                }}
                            />
                        </div>

                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="selectedCountry">Select Country</label> <br />
                            <Select id="selectedCountry" placeholder="Select Country" value={selectedCountry} onChange={handleVenue} isSearchable={true}
                                options={countryList.map(country => ({
                                    value: country.countryCode,
                                    label: country.countryCode
                                }))}
                            />
                        </div>

                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="venue"> Select Venue </label> <br />
                            <Select id="venue" placeholder="Select Venue" value={venue} onChange={handleLeague} isSearchable={true}
                                options={venueList.map(el => ({
                                    value: el.venue,
                                    label: el.venue
                                }))}
                            />
                        </div>
                    </div>
                </div>
                {
                    loading ? <div className="loader-wrapper">
                        <div className='loader'>
                        </div>
                    </div> : <div className="table-border">
                        {eventList && <div className="wrapper-body">
                            <div className='wrapper-content' >
                                <h2> Manage <span className='event-head'> { } </span> Matches <span className='ten'> {eventList.length > 0 ? eventList.length : 0} </span>
                                    <span className='zero'> {checked.length} </span> </h2>
                                {eventList?.length === 0 ? '' :
                                    <div className="">
                                        <Button type='button' name='Add Matches' className='green' onClick={handleAddMatch} disabled={checked.length === 0}/>
                                    </div>
                                }
                            </div>
                        </div>}
                        {eventList?.length === 0 && <ErrorBoundary />}
                        <table width="100%" style={{ overflowX: "auto" }}>
                            <tbody>
                                {eventList?.length > 0 &&
                                    eventList?.map(el =>
                                        <tr className='custom-row' key={el.event.id}>
                                            <td>
                                                <input type="checkbox"
                                                    checked={checked.includes(parseInt(el.event.id))}
                                                    value={el.event.id}
                                                    onChange={(event) => handleMatchCheck(event, el.id)} className='check-container' />
                                                {checked.length? null: (<span style={{ color: "red", display :"block"}}>{checkError}</span>)}
                                            </td>
                                            <td className='name-container' > {el.event.name} </td>
                                            <td className='date-container' >{(moment(el.event.openDate).format("DD/MM/YYYY hh:mm") )} </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        {/* <Pagination page={page} setPage={setPage} maximo={maximo}/>    */}
                    </div>
                }

            </motion.div>

    )
}
export default AddRacingEvent