import React from 'react';
import '../customGames/custom.css';
import { useLocation, useNavigate } from 'react-router-dom';

const LogTab = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="tab-container" style={{ padding: "5px 0 13px 0" }}>
            <div  className={`tab-box ${location.pathname === '/logs' ? 'active-result-tab' : null}`} onClick={() => navigate('/logs')}>
                <h4> User Logs </h4>
            </div>

            <div className={`tab-box ${location.pathname === '/logs/matchLogs' ? 'active-result-tab' : null}`} onClick={() => navigate('/logs/matchLogs')}>
                <h4> Match Logs </h4>
            </div>
        </div>
    )
};

export default LogTab;