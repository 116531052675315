import React, { useEffect, useState } from "react";
import "../downline.css";
import "../../../component/toggleButton/ToogleButton";
import { getCaller, updateCaller } from "../../../services/api";
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import { callInInterval } from "../../../services/utils";
import SocketComponent from "../../../socket/socketConnection";
import TopBetsBox from "./topBets";
import { showToast} from '../../../utilities/toastHelper';
import ErrorBoundary from "../../../error/ErrorBoundary";

const AgentGetMainMarket = ({
    marketAssignData,
    setMarketAssignData,
    setUpdateTime,
    isSus
}) => {
    const [topBets, setTopBets] = useState([]);
    const [fetchBets, setFetchBets] = useState(1);

    const fetchBetInitializer = async () => {
        try {
            const res = await getCaller(`api/v1/void-bets/"1,2,3,4,5,6"/${marketAssignData.sport_id}/${marketAssignData.match_id}/${marketAssignData.market_type}/${marketAssignData.market_id}?page=${1}&=${20}`);
            setTopBets(res?.data?.data || []);
        } catch (error) {
            console.error('Error fetching bets:', error);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFetchBets((prevFetchBets) => (prevFetchBets === 1 ? 2 : 1));
        }, 2000);

        fetchBetInitializer();
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        fetchBetInitializer();
    }, [fetchBets]);

    let marketID = marketAssignData?.market?.id;
    if (typeof marketAssignData?.market?.odds != 'object') {
        const newData = { ...marketAssignData, market: { ...marketAssignData.market, odds: JSON.parse(marketAssignData?.market?.odds) } };
        setMarketAssignData(newData);
    }

    const [data, setData] = useState({
        inplay_status: marketAssignData.market.inplay_status,
        inplay_stake_limit: marketAssignData.market.inplay_stake_limit,
        max_stake_limit: marketAssignData.market.max_stake_limit,
        odd_diff: marketAssignData.market.odd_diff,
        msg: marketAssignData.market.msg,
        odd_gap: marketAssignData.market.odd_gap,
        bet_delay: marketAssignData.market.bet_delay,
        is_sus: marketAssignData.market.is_sus,
        is_active: marketAssignData.market.is_active,
        odds: []
    });

    const [odds, setOdds] = useState([]);

    const [oddsVal, setOddsVal] = useState({});


    useEffect(() => {
        setData(oldData => ({ ...oldData, odds }));
    }, [odds]);

    useEffect(() => {
        if (isSus) {
            setData(oldData => ({ ...oldData, is_sus: true }));
        } else if(isSus === null){
            setData(oldData => ({ ...oldData, is_sus: false }));
        }
    }, [isSus]);

    useEffect(() => {
        setOdds(oldOdds => {
            const newOdds = [];
            const size = data.inplay_status ? data.inplay_stake_limit : data.max_stake_limit;
            for (const oldOdd of oldOdds) {
                const newOdd = { ...oldOdd };
                const updatedOdds = oddsVal[newOdd.si];

                const backVal = updatedOdds.b;
                newOdd.ex.b = [
                    { p: ((parseFloat(backVal).toFixed(2) - (parseFloat(data.odd_diff) * 2)).toFixed(2)) || 0, s: size },
                    { p: ((parseFloat(backVal) - parseFloat(data.odd_diff)).toFixed(2)) || 0, s: size },
                    { p: parseFloat(backVal).toFixed(2) || 0, s: size }
                ];

                const layVal = updatedOdds.l;
                newOdd.ex.l = [
                    { p: parseFloat(layVal).toFixed(2) || 0, s: size },
                    { p: ((parseFloat(layVal) + parseFloat(data.odd_diff)).toFixed(2)) || 0, s: size },
                    { p: ((parseFloat(layVal) + (parseFloat(data.odd_diff) * 2)).toFixed(2)) || 0, s: size }
                ];

                newOdds.push(newOdd)
            }
            return newOdds;
        });
    }, [oddsVal, data.odd_diff, data.max_stake_limit, data.inplay_stake_limit, data.inplay_status]);

    useEffect(() => {
        const newOdds = marketAssignData?.market?.odds || [];
        setOdds(newOdds);
        const newOddsVal = {};
        for (const odd of newOdds) {
            newOddsVal[odd.si] = {
                b: (odd.ex.b.sort((a, b) => b.p - a.p))[0].p,
                l: (odd.ex.l.sort((a, b) => a.p - b.p))[0].p
            };
        }
        setOddsVal(newOddsVal);
    }, [marketAssignData]);

    useEffect(() => {
        setOddsVal(oldOddsVal => {
            const newOddsVal = {};
            for (const si in oldOddsVal) {
                const backVal = oldOddsVal[si].b;
                const layVal = ((parseFloat(backVal) || 0) + (parseFloat(data.odd_gap) || 0)).toFixed(2);
                const oddVal = { ...oldOddsVal[si] };
                oddVal.l = layVal;
                newOddsVal[si] = oddVal;
            }
            return newOddsVal;
        });
    }, [data.odd_gap])

    useEffect(() => {
        callInInterval(updateData, { marketID, data })
    }, [data, marketID]);

    const updateData = async ({ marketID, data }) => {
        try {
            setUpdateTime();
            const res = await updateCaller(`api/v1/markets/${marketID}`, data);
            if (res.success) {
                showToast("Main Market Updated Successfully", false)
            } else {
                showToast (res.message , true)
            }

        } catch (error) {
            showToast (error.message , true)
        }
    }

    return (
        <div>
            <SocketComponent eventID={marketAssignData.event_id} />
            <div className="book-maker-market">
                <div className="market-match-container">
                    <div className="book-maker-match">
                        <div className="flex-box">
                            <div className="span-book">

                                <p> MAIN MARKET

                                </p>
                                <span className="match-name">

                                    {marketAssignData?.event_name}
                                </span>
                            </div>
                            <TopBetsBox data={topBets} />

                        </div>
                    </div>

                    <div>

                        <div>
                            <div className="" style={{ overflowX: "auto" }}>
                                <div className="match-name-time">
                                    {data.msg}
                                </div>
                                <div className="market-odds-body">
                                    <div className="">
                                        <p> {marketAssignData?.market?.market_name} </p>
                                    </div>
                                    <div className="market-odds-back">

                                        <div style={{ marginRight: ".5rem" }}>
                                            <p>BACK</p>
                                        </div>
                                        <div>
                                            <p>LAY</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="boder-odd">
                                    {data.is_sus === 1 ? (
                                        <div className="suspend-box"> Suspended </div>
                                    ) : null}
                                    {data?.odds?.map((e, i) => (
                                        <div className="odds-container" key={i}>
                                            <div className="box-odd-name" data-label="#">
                                                <h3> {e.sln} </h3>
                                            </div>

                                            <div className="odds-container-1">
                                                <div>
                                                    {e?.ss === 'ACTIVE' ? null : (
                                                        <div className="suspend-box"> Suspended </div>
                                                    )}
                                                </div>
                                                <div
                                                    className="back-box-container-one"
                                                    data-label="#"
                                                >
                                                    {
                                                        e?.ex?.b?.map(
                                                            (o, i) => (
                                                                <div className="box" key={i} style={i === 2 ? { backgroundColor: "#88cdf4" } : {}}>
                                                                    <p>{o.p}</p>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                <div className="back-box-container-one">
                                                    {
                                                        e?.ex?.l?.map(
                                                            (o, i) => (
                                                                <div className="box" key={i} style={i === 0 ? { backgroundColor: "#88cdf4" } : {}}>
                                                                    <p>{o.p}</p>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="market-book-maker-border">
                                <div
                                    className="market-bookmaker-popup"
                                    style={{ overflowX: "auto" }}
                                >
                                    <div>
                                        <div className="suspend-container">
                                            <div className="suspend-container-body">
                                                <p className="suspend-para"> Suspend </p>
                                                <ToogleButton
                                                    defaultChecked={marketAssignData.market.is_sus}
                                                    onChange={(val) => {
                                                        setData(oldData => ({ ...oldData, is_sus: val === true ? 1 : 0 }));
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="suspend-container">
                                            <div className="suspend-container-body">

                                                <p className="suspend-para"> Active </p>   &nbsp;&nbsp; &nbsp;
                                                <ToogleButton
                                                    defaultChecked={marketAssignData.market.is_active}
                                                    onChange={(val) => {
                                                        setData(oldData => ({ ...oldData, is_active: val === true ? 1 : 0 }));
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="suspend-container">
                                            <div className="suspend-container-body">

                                                <p className="suspend-para"> Odd Gap </p> &nbsp; &nbsp;
                                                <input
                                                    type="number" autoComplete="off" name="odd_gap" id="" className="book-maker-column-input" placeholder="Odd gap" value={data.odd_gap}
                                                    onChange={(event) => {
                                                        setData(oldData => ({ ...oldData, odd_gap: event.target.value }));
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="suspend-container">
                                            <div className="suspend-container-body">

                                                <p className="suspend-para"> Difference </p>  &nbsp;
                                                <input
                                                    type="number" autoComplete="off" name="difference " id="" className="book-maker-column-input" placeholder="Difference" value={data.odd_diff}
                                                    onChange={(event) => {
                                                        setData(oldData => ({ ...oldData, odd_diff: event.target.value }));
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="suspend-container-body" >

                                                <p className="suspend-para"> Message </p>  &nbsp;
                                                <input
                                                    type="text" autoComplete="off" name="message" id="" style={{ width: "300px" }} className="book-maker-column-input" placeholder="Message" value={data.msg}
                                                    onChange={(event) => {
                                                        setData(oldData => ({ ...oldData, msg: event.target.value }));
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="bookmaker-table-container"
                            style={{ overflowX: "auto" }}
                        >
                            <table width="100%">
                                <thead id="fancy-head-coloumn">
                                    <tr className="">
                                        <th align="left" style={{ paddingLeft: '68px' }}> TEAM NAME </th>
                                        <th align="left" > BACK </th>
                                        <th align="left" > LAY </th>
                                        <th align="left"> SUSPEND </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.odds?.length > 0 ? (
                                        odds?.map((e, i) => (
                                            <tr className="fancy-head-row" key={i}>
                                                <td>
                                                    <input type="text" autoComplete="off" disabled id="" name="selectionName" value={e.sln} className="book-maker-column-input-1" placeholder="Team Name" />
                                                </td>
                                                <td>
                                                    <input type="number" autoComplete="off" name="backOdds" id="" className="book-maker-column-input" placeholder="Price"
                                                        onChange={(event) => {
                                                            setOddsVal(oddsVal => {
                                                                const newOddsVal = { ...oddsVal };
                                                                newOddsVal[e.si].b = event.target.value;
                                                                newOddsVal[e.si].l = ((parseFloat(event.target.value) || 0) + (parseFloat(data.odd_gap) || 0)).toFixed(2);
                                                                return { ...newOddsVal, [e.si]: { ...newOddsVal[e.si] } };
                                                            })
                                                        }
                                                        }
                                                        value={oddsVal[e.si]?.b}
                                                        onBlur={(event) => {

                                                        }}
                                                    />

                                                </td>
                                                <td>
                                                    <input type="number" autoComplete="off" name="layOdds" id="" className="book-maker-column-input" placeholder="Price"
                                                        onChange={(event) => {
                                                            setOddsVal(oddsVal => {
                                                                const newOddsVal = { ...oddsVal };
                                                                newOddsVal[e.si].l = event.target.value;
                                                                newOddsVal[e.si].b = ((parseFloat(event.target.value) || 0) - (parseFloat(data.odd_gap) || 0)).toFixed(2);
                                                                return { ...newOddsVal, [e.si]: { ...newOddsVal[e.si] } };
                                                            })
                                                        }
                                                        }
                                                        value={oddsVal[e.si]?.l}
                                                        onBlur={(event) => {
                                                            setOddsVal(oddsVal)
                                                        }}
                                                    />
                                                </td>
                                                <td align="left">
                                                    <ToogleButton
                                                        defaultChecked={e.ss === "SUSPENDED"}
                                                        onChange={(val) => {
                                                            setOdds(oldOdds => {
                                                                const newOdds = [...oldOdds];
                                                                newOdds[i] = { ...newOdds[i], ss: val === true ? "SUSPENDED" : "ACTIVE" };
                                                                return newOdds;
                                                            });
                                                        }
                                                        }
                                                    />{" "}
                                                </td>
                                            </tr>
                                        ))
                                    ) : <ErrorBoundary />}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )

}
export default AgentGetMainMarket;