import React, { useState, useEffect, useRef } from 'react';
import { getCaller, postCaller } from '../../services/api';
import Pagination from '../../component/pagination/Pagination';
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline, } from 'react-icons/io5'
import ResultTab from './ResultTab';
import '../result/result.css';
import { motion } from "framer-motion";
import Button from '../../component/button/Button';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select';
import Result from './result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const PendingMatch = ({ submitResult }) => {
  const [pendingMatchData, setPendingMatchData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedPages, setFetchedPages] = useState({});
  const [sportList, setSportList] = useState([]);
  const [sport, setSport] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventList, setEventList] = useState([]);
  const [ResultSelected, setResultSelected] = useState({});
  const [selectedResult, setSelectedResult] = useState({});
  const limit = 100;
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const intervalRef = useRef(null);
  const [searchField, setSearchField] = useState('');
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState({});
  const [scope,setScope] = useState({})

  useEffect(() => {
    const {result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({result, userType})
    
    async function getSport() {
      let sportResponse = await getCaller('api/v1/sports/management/getSport');
      setSportList(sportResponse.data);
    }
    getSport();
  }, []);

  const handleSport = async (event) => {
    setEventList([]);
    setEventId("");
    const sport = event.value;
    setSport(sport);
    const eventResponse = await getCaller(`api/v1/events/findEvents?id=${sport}`);
    setEventList(eventResponse.data);
    setPendingMatchData([]);
  };

  const handleEvent = async (event) => {
    const newEventId = event.value;
    if (newEventId !== eventId) {
      setEventId(newEventId);
      setFetchedPages({});
      clearInterval(intervalRef.current);
      getPendingMatchResult(newEventId);
    }
  };

  useEffect(() => {
    getPendingMatchResult(eventId);
    return () => clearInterval(intervalRef.current);
  }, [eventId]);

  const getPendingMatchResult = async (currentEventId = eventId, page = 1) => {
    setPendingMatchData([]);
    if (fetchedPages[page]) {
      setPendingMatchData(fetchedPages[page]);
      if (currentEventId === eventId) return;
    }
    if (!currentEventId && !sport) return;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const initialOperator = {};
    const result = async (currentEventId) => {
      if (currentEventId) {
        const resultResponse = await getCaller(`api/v1/markets/undeclared?event=${currentEventId}&sport=${sport}&page=${page}&limit=${limit}`);
        if (JSON.stringify(resultResponse.data) !== JSON.stringify(pendingMatchData)) {
          const fetchPendingData = resultResponse.data;
          setPendingMatchData(fetchPendingData);
          setTotalPage(resultResponse?.pagination?.totalPages);
          setFetchedPages((prev) => ({ ...prev, [page]: fetchPendingData }));
         
          fetchPendingData.forEach(e => {
            initialOperator[e.id] = operatorList.map(el => ({ value: el, label: el }));
          });             
               
        }
      }
    };
    setOperator(initialOperator);
    result(currentEventId);

    intervalRef.current = setInterval(async () => {
      result(currentEventId);
    }, 15000);
       
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    await getPendingMatchResult(eventId, page);
  };
  
  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/results/operatorList`)
        const fetchedOperatorList = response?.data?.operatorList
        setOperatorList(fetchedOperatorList)
    }
    getData()
  },[])

  async function handleMatchResult(fieldName, index, value) {
    let newValue = JSON.parse(JSON.stringify(pendingMatchData));
    newValue[index][fieldName] = value;
    setPendingMatchData(newValue);
    setResultSelected(prevState => ({ ...prevState, [index]: value ? true : false }));
    setSelectedResult(prevState => ({...prevState, [newValue[index].id]: value}));
  }

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions,id) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator(prevState => ({ ...prevState, [id]: [] }));
      } else {
        setOperator(prevState => ({ ...prevState, [id]: operatorList.map(el => ({ value: el, label: el })) }));
      }
    } else {
  
      setOperator(prevState => ({ ...prevState, [id]: selectedOptions }));
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  }; 

  const handleSubmitResult = async () => {
    if (index === -1) { return; }
    const i = index;
    try {
      const runnerData = pendingMatchData[i]?.runners;
      if (!runnerData) throw new Error("Runner data is missing");
      const values = operator[pendingMatchData[i].id]?.map(item => item.value) || [];
      const result = JSON.parse(runnerData);
      const data = {
        sport_id: pendingMatchData[i].sport_id,
        match_id: pendingMatchData[i].match_id,
        market_id: pendingMatchData[i].id,
        result: selectedResult[pendingMatchData[i].id],
        market_name: pendingMatchData[i].market_name,
        selection: (JSON.parse(selectedResult[pendingMatchData[i].id])).RN,
        operators_list:values
      };

      await showConfirmationDialog(`You want to Submit this Result (${data.selection})?`)
      .then(async (responseData) => {
      if (responseData.isConfirmed) {
        const response = await postCaller(`api/v1/results`, data);
        if (response.success) {
          showSuccessMessage('Your Result is Submitted Successfully')
          setResultSelected({});
          setPendingMatchData(prevState => prevState.filter(item => item.id !== pendingMatchData[i].id))
        } else {
          showToast (response.message , true)
        }
      }})
    } catch (error) {
      showToast (`An error occurred: ${error.message}` , true)
    }
  };

  const filteredManagers = pendingMatchData.filter((market) => {
    const marketName = market.market_name ? market.market_name.toLowerCase() : "";
    const eventName = market.event_name ? market.event_name.toLowerCase() : "";
    return marketName.includes(searchField.toLowerCase()) || eventName.includes(searchField.toLowerCase());
  });

  return (
    <>
      <Result />
      <ResultTab />
      <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
        <div className="result-sport-content className-fix flexFix-width justify-start">
          <div className="result-event-select">
            <label htmlFor="date"> Select Sport </label> <br />
            <Select placeholder="Select Sport" onChange={handleSport} options={sportList.map((el) => ({ value: el.id, label: el.name }))}/>
          </div>

          <div className="result-event-select">
            <label htmlFor="date"> Select Events </label> <br />
            <Select placeholder="Select Event" onChange={handleEvent}
              value={eventId ? {  
                value: eventId, 
                label: `${eventList.find(el => el.event_id === eventId)?.event_name} (${eventList.find(el => el.event_id === eventId)?.event_date.split('.')[0]})`
              } : null}
              options={eventList.map((el) => ({ value: el.event_id, label: `${el.event_name} (${((el.event_date).split('.')[0])})` }))}
            />
          </div>
        </div>

        <div className="table-border fancy-result-container" style={{ padding: '0px' }}>
          <div className="wrapper-body">
            <div className="wrapper-content" style={{ width: "100%" }}>
              <div className='searchcard'>
                <input type="text" autoComplete="off" name="" id="" placeholder='Search' className='result-input' onChange={(event) => setSearchField( event.target.value ) } value={searchField}/>
                {searchField ? <div className="ai-close">
                  <AiOutlineClose onClick={(event) => setSearchField('')}/>
                  </div> : <div className='ai-close'> <IoSearchOutline /></div>
                }
              </div>
            </div>
          </div>

          {filteredManagers.length > 0 ? (
            <div className="table-scrollable-containers fancy-result-table-new">
              <table width="100%" className="odds-gap-table-client fancy-result-table table-center">
                <thead>
                  <tr className='custom-row'>
                    <th style={{ whiteSpace: 'nowrap' }}>MARKET ID</th>
                    <th>SPORT</th>
                    <th>EVENT NAME</th>
                    <th>MARKET NAME</th>
                    <th style={{ width: '250px' }}>RESULT</th>
                    <th style={{ width: '250px' }}>OPERATOR</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredManagers.map((e, i) => (
                    <tr className='custom-row' key={e.id}>
                      <td>{e.id}</td>
                      <td style={{ textTransform: "capitalize" }}> {e.sport_name} </td>
                      <td>{e.event_name}</td>
                      <td>{e.market_name}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        <div className="select-pending-container">
                          <select name="" value={e?.runner} id="" className='result-input' onChange={(event) => handleMatchResult("runner", i, event.target.value)}>
                            <option value=""> Select Result </option>
                            {
                              (JSON.parse(e?.runners) || [])?.map((el, i) => (
                                <option style={{ textTransform: "capitalize" }} key={i} value={JSON.stringify(el)}>{el.RN}</option>
                              ))
                            }
                            <option style={{ textTransform: "capitalize" }} key='tie' value={JSON.stringify({ 'RN': 'tie', 'SID': 'tie' })}>Tie</option>
                            <option style={{ textTransform: "capitalize" }} key='abandoned' value={JSON.stringify({ 'RN': 'abandoned', 'SID': 'abandoned' })}>Abandoned</option>
                          </select>
                        </div>
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        <div className="agent-input-body selectWrap-main operator-dropdown-container-new">
                          <Select
                            tabIndex={-1}
                            onChange={selectedOptions => handleOperator(selectedOptions, e.id)}
                            options={getOptions(operator[e.id])} 
                            value={operator[e.id] || ''}  
                            placeholder="Select Operators"
                            isMulti
                            components={{ 
                              DropdownIndicator: () => null, 
                              IndicatorSeparator: () => null 
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='pending-result-btn'>
                          <Button className='submit' name="Set Result" disabled={!(ResultSelected[i] && operator[e.id]?.length) || (scope?.userType === 'manager' && !scope?.result?.add)} type='button' onClick={() => { setIndex(i); setOpen(true); }} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : <ErrorBoundary />}
        </div>
        <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
      </motion.div>
      <SurveillanceModel isOpen={isOpen} setOpen={setOpen} onCloseModel={handleSubmitResult} matchID={pendingMatchData[index]?.event_id} marketID={pendingMatchData[index]?.market_id} marketType={1} />
    </>
  );
};

export default PendingMatch;
