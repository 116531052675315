
const handleKeyDown = (e) => {
  const invalidKeys = ['+', '-', 'e', 'E', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '=', '{', '}', '[', ']', '\\', '|', ':', ';', '"', "'", '<', '>', '?', '/', '~'];
  if (invalidKeys.includes(e.key)) {
    e.preventDefault();
  }
};

const handleNumericKeyPress = (e) => {
  if (
    !/[\d]/.test(e.key) &&
    e.key !== "Backspace" &&
    e.key !== "Delete" &&
    e.key !== "ArrowLeft" &&
    e.key !== "ArrowRight" &&
    e.key !== "Tab"
  ) {
    e.preventDefault();
  }
};

module.exports = { handleKeyDown, handleNumericKeyPress }