import React, { useState, useEffect } from 'react'
import { getCaller, postCaller } from '../../services/api'
import Pagination from '../../component/pagination/Pagination'
import ResultTab from './WorliMatkaResultTab'
import '../result/result.css'
import { motion } from "framer-motion";
import Select from 'react-select';
import Button from '../../component/button/Button';
import CONSTANTS from '../../utilities/constants'
import ServerToBrowserTime from './serverToBrowser'; 
import Result from '../result/result';
import ErrorBoundary from '../../error/ErrorBoundary';
import getLocalData from '../../utilities/LocalStorageData.utilities'
import { showToast, showSuccessMessage } from '../../utilities/toastHelper';

const WorliMatkaPendingMatchResult = ({ submitResult }) => {
  const [pendingMatchData, setPendingMatchData] = useState([])
  const [results, setResults] = useState({});
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState('');
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedPages, setFetchedPages] = useState({});
  const [scope, setScope] = useState({})
  const  limit = 100;

  useEffect(() => {
    const {result}= getLocalData('scope')
    const userType= getLocalData('user_role')
    setScope({result,userType})

    async function getSport() {
      await getPendingMatchResult()
    }
    getSport()
  }, [operatorList])

  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/worli/operatorList`)
      const fetchedOperatorList = response?.data?.operatorList
      setOperatorList(fetchedOperatorList)
    }
    getData()
  },[])

  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/worli/operatorList`)
        const fetchedOperatorList = response?.data?.operatorList
        setOperatorList(fetchedOperatorList)
    }
    getData()
  },[])


  const getPendingMatchResult = async (page =1) => {
    if (fetchedPages[page]) {
      setPendingMatchData(fetchedPages[page]);
      if(page !==1) return
    }

    const initialOperator = {} 
    const resultResponse = await getCaller(`api/v1/worli/matches/pendingresult?page=${page}&limit=${limit}`)
    const filteredData =resultResponse?.data
    setPendingMatchData(filteredData)
    setTotalPage(resultResponse?.pagination?.totalPages)
    setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
    filteredData.forEach(e => {
      initialOperator[e.market_id] = operatorList.map(el => ({ value: el, label: el }));
    });
    setOperator(initialOperator);
  }

  const handlePageChange = async(page) => {
    setCurrentPage(page);
    await getPendingMatchResult(page)
  };

  const handleAddResult = async (i, e) => {
    if (CONSTANTS.WORLI.ALLOWED_RESULT.length > 0 && (!CONSTANTS.WORLI.ALLOWED_RESULT.includes(parseInt(results[`result-${i}`])))) {
      const title = 'Invalid Result Value';
      showToast(title, true);
      return;
    }

    const values = operator[e.market_id].map(item => item.value);

    const data = {
      matchTime: ((e.match_time).split('T')[0]),
      result: results[`result-${i}`],
      operators_list: values,
    }

    const response = await postCaller(`api/v1/worli/matches/result/${e.market_id}`, data)

    if (response.success) {
      showSuccessMessage("Match result has been added");
      await getPendingMatchResult();
    } else {
      showToast(response.message, true);
    }
    setResults({});
  }

  const handleResultChange = (e, id) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue)) {
        const newResults = { ...results, [id]: inputValue };
        setResults(newResults);
    }
  };
  
  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions, id) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator(prevState => ({ ...prevState, [id]: [] }));
      } else {
        setOperator(prevState => ({ ...prevState, [id]: operatorList.map(el => ({ value: el, label: el })) }));
      }
    } else {
      setOperator(prevState => ({ ...prevState, [id]: selectedOptions }));
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  };

  let completeData = pendingMatchData?.length && pendingMatchData?.filter((el) => true);

  return (
    <> 
      <Result />
      <ResultTab />
      <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}>

        <div className="result-sport-content">
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
          <div className="result-event-select"></div>
        </div>

        <div className="table-border" style={{ padding: '0px' }}>
          {/* <div className="wrapper-body"> */}
            <div className="wrapper-content-1">
            {/* </div> */}
          </div>
          <div className='tableResponsive-wrap'>
            {completeData?.length > 0 ? (
            <table width="100%">
              <thead>
                <tr custom-row style={{ whiteSpace: 'nowrap' }}>
                  <th>Match Id</th>
                  <th> Match Title </th>
                  <th> Match Type </th>
                  <th> Market Time </th>
                  <th> Result</th>
                  <th> Operator </th>
                  <th> Action </th>
                </tr>
              </thead>
              <tbody>
                { completeData?.map((e, i) => (
                      <tr className='custom-row-result' key={i}>
                        <td>{e.id}</td>
                        <td style={{ textTransform: "capitalize", width: "25px" }}> {e.match_title} </td>
                        <td >{e.match_type}  </td>
                        {<td > 
                           <ServerToBrowserTime serverTime={e.match_time} />   
                        </td>}
                        {/*<td>{moment(e.match_time).format('YYYY-MM-DD HH:mm')}</td> */}
                        <td> <input id={`result-${i}`} type='text' placeholder='Enter Result' style={{ height: "35px", paddingLeft: "4px", borderRadius: '3px' }} value={results[`result-${i}`] || ''} onChange={(e) => handleResultChange(e, `result-${i}`)} ></input>  </td>
                        <td style={{ textTransform: "capitalize" }}>
                          <div className="select-pending-container">
                            <Select
                                tabIndex={-1}
                                onChange={selectedOptions => handleOperator(selectedOptions, e.market_id)}
                                options={getOptions(operator[e.market_id])}
                                value={operator[e.market_id] || ''} 
                                placeholder="Select Operators"
                                isMulti
                            />
                          </div>
                        </td>

                        <td ><Button className='submit' name='Set Result' type='submit'  onClick={() => handleAddResult(i, e)} disabled={(scope?.userType==='manager' && !scope?.result?.add) || (results[`result-${i}`]==='' || !results[`result-${i}`]) || !operator[e.market_id]?.length} /> </td>
                      </tr>
                  ))
                }
              </tbody>
            </table>) : <ErrorBoundary text='Matches'/>}
          </div>
        </div>
        <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
      </motion.div>
    </>
  )
};

export default WorliMatkaPendingMatchResult;