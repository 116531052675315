import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  background: "black",
  color: "white",
  showConfirmButton: false,
  timer: 2000,
  width: 500,
  padding: ".5rem",
});

export const showToast = (title, isError = false) => {
  Toast.fire({
    icon: !isError ? "success" : "error",
    title: title || (isError ? "Failed" : "Success"),
    background: "white",
    color: isError ? "red" : "green",
  });
};

export const showConfirmationDialog = async (text) => {
  return await Swal.fire({
    title: "Are you sure?",
    text: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    allowOutsideClick: false
  });
};

export const showDeleteMessage = (text) => {
  Swal.fire({
    title: "Deleted",
    text: text,
    icon: "success",
    showConfirmButton: false,
    timer: 1000,
    background: "white",
    color: "green",
  });
};

export const showSuccessMessage = (text) => {
  Swal.fire({
    title: 'Submitted',
    text: text,
    icon: 'success',
    showConfirmButton: false,
    timer: 2000,
    background: 'white',
    color: 'green',
  });
};

