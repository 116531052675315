import moment from 'moment';
import Bet from './betsTab';
import Select from 'react-select';
import { motion } from "framer-motion";
import { MdDelete } from 'react-icons/md';
import DatePicker from "react-datepicker";
import CONSTANTS from '../../utilities/constants';
import React, { useState, useEffect } from 'react';
import Button from '../../component/button/Button';
import "react-datepicker/dist/react-datepicker.css";
import BigModal from '../../component/modal/BigModal';
import ErrorBoundary from '../../error/ErrorBoundary';
import { CustomInput } from '../../utilities/customInput';
import { getCaller, updateCaller } from '../../services/api';
import Paginations from '../../component/pagination/Paginations';
import { handleNumericKeyPress } from '../../utilities/handleKeyDown';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showSuccessMessage, showToast } from '../../utilities/toastHelper';
import newTimestampConverterForFormat from '../../utilities/newTimeConversion';

const Bets = () => {
    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState(4);
    const [event, setEvent] = useState("");
    const [eventList, setEventList] = useState([]);
    const [betType, setBetType] = useState("1");
    const [marketType, setMarketType] = useState("");
    const [marketList, setMarketList] = useState([]);
    const [marketID, setMarketID] = useState("");
    const [selected, setSelected] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [betList, setBetList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(200);
    const [total, setTotal] = useState(1);
    const [deleteType, setDeleteType] = useState("");
    const [openDeleteBetModal, setOpenDeleteBetModal] = useState(false);
    const [openReasonModal, setReasonModal] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [user, setUser] = useState(null);
    const [ipAddress, setIpAddress] = useState("");
    const [selectionType, setSelectionType] = useState('all');
    const [searchField, setSearchField] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [operator, setOperator] = useState({ value: 'CLICKBET', label: 'CLICKBET' });
    const [scope, setScope] = useState({});
    const [isMarketDeclared, setIsMarketDeclared] = useState(false);
    const [minStake, setMinStake] = useState('');
    const [maxStake, setMaxStake] = useState('');
    const startIndex = (page - 1) * perPage + 1;

    const operatorList = CONSTANTS.OPERATOR_LIST;

    const marketTypeList = [
        { value: 'main_market', label: 'Main Market' },
        { value: 'bookmaker', label: 'Bookmaker' },
        { value: 'fancy', label: 'Fancy' },
    ];

    const betTypeList = [
        { value: '1', label: 'Open' },
        { value: '2,3', label: 'Settled' },
        { value: '4', label: 'Void' },
        { value: '5', label: 'Delete' },
        { value: '6', label: 'Invalid' },
    ];

    const selectionTypeList = [
        { value: 'all', label: 'All' },
        { value: 'back', label: 'Back' },
        { value: 'lay', label: 'Lay' },
    ];

    const timeRange = { startTime: startTime, endTime: endTime };

    useEffect(() => {
        const { bets } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({ bets, userType });

        async function getSport() {
            const sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data);
        }
        getSport();
    }, []);

    const handleBetType = async (event) => {
        setBetType(event.value);
        setBetList([]);
    };

    const handleSport = async (event) => {
        const sport = event.value;        
        setSport(sport);
        setEvent("");
        setEventList([]);
        setMarketType("");
        setMarketID("");
        setBetList([]);
    };

    const handleDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("YYYY-MM-DD");
    };

    useEffect(() => {
        async function fetchEventList() {
            if (sport !== "" && startDate && endDate) {
                const response = await getCaller(`api/v1/events/active/sports/${sport}?start_date=${handleDate(startDate)}&end_date=${handleDate(endDate)}`)
                setEventList(response.data)
            }
        }

        fetchEventList();
    }, [startDate, endDate, sport]);

    useEffect(() => {
        async function getMarkets(sport, event, betType, marketType) {
            setMarketList([]);
            if (sport && event && betType && marketType) {
                const eventId = (JSON.parse(event))?.event_id;    
                try {
                    const route = marketType === 'main_market' ? 'markets' : marketType === 'bookmaker' ? 'bookmakers' : 'fancies';
                    const response = await getCaller(`api/v1/${route}/*/events/${eventId}`);
                    setMarketList(response.data);
                } catch (error) {
                    console.error("Error fetching markets:", error);
                }
            }
        }
    
        getMarkets(sport, event, betType, marketType);
    }, [sport, event, betType, marketType]);
      
    const fetchBets = async ({ otherPage }) => {
        try {
            const matchId = (JSON.parse(event))?.id;
            const result = await getCaller(`api/v1/void-bets/${betType}/${sport}/${matchId}/${marketType}/${marketID}?page=${otherPage || page}&limit=${perPage}&user=${user}&ip=${ipAddress}&selectionType=${selectionType==='all' ? '' :  selectionType}&startTime=${timeRange.startTime}&endTime=${timeRange.endTime}&selection=${searchField}&operator=${operator?.value || ''}&stakeMin=${minStake}&stakeMax=${maxStake}`);
            setBetList(result.data);
            const { pages: total, page: pageNo, per_page } = result.pagination;
            setPage(pageNo);
            setPerPage(per_page);
            setTotal(total);
        } catch (error) {
            console.error(error);
        }
    };

    const setPageLocal = async (page) => {
        try {
            setPage(page);
            await fetchBets({ otherPage: page });
        } catch (error) {
            console.error(error);
        }
    };

    /*
    useEffect(() => {
        if (allSelected) {
            const betIds = betList.map(m => parseInt(m.id));
            setSelected(betIds);
        } else {
            setSelected([]);
        }
    }, [ allSelected]);
    */

    const manageChecked = (event) => {
        const value = event.target.checked;
        setAllSelected(value);

        if (value) {
            const betIds = betList.map(m => parseInt(m.id));
            setSelected(betIds);
        } else {
            setSelected([]);
        }
    };

    const markCheck = (event) => {
        let updatedList = [...selected];
        if (event.target.checked) {
            updatedList = [...updatedList, parseInt(event.target.value)];
            if(updatedList.length === betList.length){
                setAllSelected(true);
            }
        } else {
            updatedList.splice(updatedList.indexOf(parseInt(event.target.value)), 1);
            if(allSelected && updatedList.length < betList.length){
                setAllSelected(false);
            }
        }
        setSelected(updatedList);
    };

    const deleteBets = async () => {
        try {
            setOpenDeleteBetModal(true);
            setDeleteType("");
        } catch (error) {
            console.error(error);
        }
    };

    const setDelete = (type) => {
        setDeleteType(type);
        setOpenDeleteBetModal(false);
        setReasonModal(true);
    };

    const voidBets = async (reason) => {
        const body = {
            reason,
            ids: selected,
            type: deleteType,
            marketType,
            marketID,
            betType
        };

        const res = await updateCaller(`api/v1/void-bets/${operator?.value || ''}`, body);

        if (res.success) {
            showSuccessMessage(`Success`);
            setReasonModal(false);
            setSelected([]);
            await fetchBets({ otherPage: 1 });
        } else {
            showToast( res.message, true)
        }
    };

    const onSearchChange = (e) => {
        setSearchField(e.target.value);
    };
    
    const handleEndTime = (event) => {
        const currentTime = event.target.value;
        const formattedTime = currentTime.substring()||"";
        setEndTime(formattedTime);
    };

    const handleStartTime = (event) => {
        const currentTime = event.target.value;
        const formattedTime = currentTime.substring()||"";
        setStartTime(formattedTime);    
    };

    const handleOperator = async (event) => {
        setOperator(event);
        setBetList([]);
    };

    const handleChangeEvent = async (event) =>{
        setEvent(event);
        setMarketType("");
        setMarketID("");
        setBetList([]);
    }

    const handleDateChange = async (dates) => {        
        const [start, end] = dates;
        setEvent("");
        setEventList([]);
        setMarketType("");
        setMarketID("");
        setBetList([]);
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
            <Bet />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content className-fix flexFix-width justify-start">
                        <div className="result-event-select">
                            <label htmlFor="date"> Bet Type </label> <br />
                            <Select placeholder="Select bet type" value={betType ? { value: betType, label: `${betTypeList.find(el => el.value === betType)?.label}` } : null}
                                onChange={(selectedOption) => handleBetType(selectedOption)} options={betTypeList}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Sport </label> <br />
                            <Select placeholder="Select sport" value={sport ? { value: sport, label: `${sportList.find(el => el.id === sport)?.name}` } : null}
                                onChange={(selectedOption) => handleSport(selectedOption)} options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                            />
                        </div>

                        <div className="select-container containerStyle" style={{ maxWidth: '270px'}}>
                            <label htmlFor="date" id="date-label" className=''> Select Date </label> <br />
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                className="date-picker-container"
                                customInput={<CustomInput />}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Event </label> <br />
                            <Select placeholder="Select Event" value={event ? { value: JSON.parse(event)?.event_id, label: `${eventList.find(el => el.event_id === JSON.parse(event).event_id)?.event_name}` } : null}
                                onChange={(selectedOption) => handleChangeEvent(selectedOption.value)}
                                options={eventList.map((el) => ({ value: JSON.stringify(el), label: `${el.event_name} (${moment.utc(el.event_date).local().format('HH:mm:ss')})` }))}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Market Type </label> <br />
                            <Select placeholder="Select Market Type" value={marketType ? { value: marketType, label: `${marketTypeList.find(el => el.value === marketType)?.label}` } : null}
                                onChange={(selectedOption) => {
                                    setMarketType(selectedOption.value);
                                    setMarketID("");
                                    setBetList([]);
                                }}
                                options={event !== "" ? marketTypeList : []}
                            />
                        </div>
                    </div>

                    <div className="result-sport-content className-fix flexFix-width justify-start" style={{ marginTop: "10px" }}>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <Select placeholder="Select Market" value={marketID ? { value: marketID, label: `${marketList.find(el => el.marketId === marketID)?.market_name}` } : null}
                                onChange={(selectedOption) => {
                                    setMarketID(selectedOption.value);
                                    setAllSelected(false);
                                    setBetList([]);
                                    const isMarketDeclared = (betType === '1' && selectedOption.is_declared) || false;
                                    setIsMarketDeclared(isMarketDeclared);
                                }}
                                options={marketList.map((el) => ({ value: el.marketId, label: el.market_name, is_declared: el.is_declared }))}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Operator  </label> <br />
                            <Select placeholder="Select Operator" value={operator} onChange={handleOperator} options={operatorList} isClearable={true} />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Selection Type </label> <br />
                            <Select placeholder="Select Market"
                                value={selectionType ? { value: selectionType, label: `${selectionTypeList.find(el => el.value === selectionType)?.label}` } : null}
                                onChange={(selectedOption) => setSelectionType(selectedOption?.value ?? '')} options={selectionTypeList} isClearable={true}
                            />
                        </div>

                        <div className="result-event-select widthFix" id='bookmaker-select'>
                            <p style={{ display: 'flex', justifyContent: "space-around" }}>Stake</p>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <input type="search" autoComplete="off" id="" className="select-field" placeholder="Min" style={{ width: "120px" }} onKeyDown={handleNumericKeyPress} value={minStake} onChange={(e) => setMinStake(e.target.value)} />
                                <input type="search" autoComplete="off" id="" className="select-field" placeholder="Max" style={{ width: "120px" }} onKeyDown={handleNumericKeyPress} value={maxStake} onChange={(e) => setMaxStake(e.target.value)} />
                            </div>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Time Range</label> <br />
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div className='add-sport-input '>
                                    <input type="time" autoComplete="off" step="1" id="startTime" name="startTime" onChange={handleStartTime} />
                                </div>

                                <div className='add-sport-input '>
                                    <input type="time" autoComplete="off" step="1" id="endTime" name="endTime" onChange={handleEndTime} />
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className="result-sport-content className-fix flexFix-width justify-start" style={{ marginTop: "10px" }}>
                        <div className="result-event-select">
                            <label htmlFor="date"> Search Selection  </label> <br />
                            <input type="search" autoComplete="off" name="search" className='select-field' id="search" placeholder="SEARCH" onChange={onSearchChange} />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date">USER ID </label> <br />
                            <input type="search" autoComplete="off" className='select-field' value={user || ""} onChange={(e) => setUser(e.target.value)} name="user" placeholder="USER ID" />
                        </div>
                            
                        <div className="result-event-select">
                            <label htmlFor="date"> IP ADDRESS </label> <br />
                            <input type="search" autoComplete="off" className='select-field' value={ipAddress||""} onChange={(e) => setIpAddress(e.target.value)} name="ip_address" placeholder="IP Address"/>
                        </div>
                    </div>
                    
                    <div className="book-maker-btn">
                        <Button className='submit' name='Search' type='button' disabled={!(sport && event && betType && marketType && marketID && operator)} onClick={fetchBets} />
                    </div>
                </div>

                {betList?.length > 0 ? ( 
                <div className="table-border">
                    <div style={{ overflowX: "auto" }} >
                        <table width="100%" >
                            <thead>
                                <tr className='custom-row' >
                                    <th>
                                        <input type="checkbox" checked={allSelected} onChange={(event) => manageChecked(event)} className='check-container' />
                                        <button
                                            style={{background:'transparent', border:'none', outline:'none', cursor: (!selected.length || isMarketDeclared || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? 'not-allowed':'pointer'}} 
                                            disabled={!selected.length || isMarketDeclared || (scope?.userType?.toLowerCase() === 'manager' && !(scope?.bets?.delete))}
                                            >
                                            <MdDelete style={{ fontSize: "1.5rem", color: (!selected.length || isMarketDeclared || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? "#ccc" : "red" }}
                                                onClick={deleteBets}/>
                                        </button>
                                    </th>
                                    <th> S.no</th>
                                    <th> # </th>
                                    <th>Parent</th>
                                    <th>User</th>
                                    <th>Selection</th>
                                    <th>Odds</th>
                                    <th>Stake</th>
                                    <th>Rate</th>
                                    <th>Date</th>
                                    <th>Profit</th>
                                    <th>Loss</th>
                                    <th>Commission</th>
                                    <th>Total P&L</th>
                                    <th>IP</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    betList.map((e, i) => (
                                        <React.Fragment key={i}>
                                            <tr key={e.id} className={e.selection_type + ' custom-row'}>
                                                <td><input type="checkbox" checked={selected.includes(parseInt(e.id))} value={e.id} onChange={markCheck} className='check-container' /></td>
                                                <th>{startIndex + i}</th>
                                                <td>{e.id}</td>
                                                <td>{e.parent}</td>
                                                <td>{e.child}</td>
                                                <td>{e.selection}</td>
                                                <td>{e.odds}</td>
                                                <td>{e.stake}</td>
                                                <td>{marketType !== "fancy"
                                                    ? e?.odds
                                                    : e?.selection_type === "back"
                                                        ? (e?.profit / e?.stake)
                                                        : (e?.loss / e?.stake)
                                                }</td>
                                                <td>{newTimestampConverterForFormat(e.created_at, 'YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{e.profit}</td>
                                                <td>{e.loss}</td>
                                                <td>{e.commission}</td>
                                                <td>{e.p_l}</td>
                                                <td>{e.ip}</td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> 
                    <Paginations page={page} perPage={perPage } setPage={setPageLocal} setPerPage={setPerPage} maximo={total} />
                </div>
                ) : <ErrorBoundary text='Bets' />}

            </motion.div>
            <BigModal isOpen={openDeleteBetModal} onClose={() => setOpenDeleteBetModal(false)}>
                <>
                    <h3>DELETE BET</h3>
                    <Button className={"green"} style={{ margin: "3px" }} name="VOID" onClick={() => setDelete('void')}></Button>
                    <Button className={"yellow"} name="INVALID" onClick={() => setDelete('invalid')}></Button>
                    <Button className={"red"} name="DELETE" onClick={() => setDelete('delete')}></Button>
                </>
            </BigModal>
            <BigModal isOpen={openReasonModal} onClose={() => setReasonModal(false)} style={{ width: "400px" }}>
                <>
                    <h3>REASON?</h3>
                    <Button className={"green"} style={{ margin: "3px" }} name="Late no ball" onClick={() => voidBets(1)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Reduced over" onClick={() => voidBets(2)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="Wrong odd" onClick={() => voidBets(3)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Technical issue" onClick={() => voidBets(4)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Agent mistake" onClick={() => voidBets(5)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="Cheating" onClick={() => voidBets(6)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Late Suspended" onClick={() => voidBets(7)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Wrong result" onClick={() => voidBets(8)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="2nd inning bet" onClick={() => voidBets(9)}></Button>
                    <Button className={"green"} style={{ margin: "3px" }} name="Player Not Opened" onClick={() => voidBets(10)}></Button>
                    <Button className={"yellow"} style={{ margin: "3px" }} name="Wicket Not Fall" onClick={() => voidBets(11)}></Button>
                    <Button className={"red"} style={{ margin: "3px" }} name="Over Not Completed" onClick={() => voidBets(12)}></Button>
                </>
            </BigModal>
        </>
    );
}

export default Bets;