import './ClientStatements.css';
import { getCaller } from '../../services/api';
import { AiOutlineClose } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';

const ClientExposure = ({ isOpen, onClose, userName, operator }) => {
    const [exposureData, setExposureData] = useState([]);
    const [selectedGameType, setSelectedGameType] = useState("sports");

    const gameType = ["sports", "matka", "casino", "virtual_sports", "sports_book", "withdrawal_exposure"];

    useEffect(() => {
        if (isOpen) {
            const fetchExposureListDetails = async () => {        
                try {
                    const response = await getCaller(`api/v1/client-statement/showExposureListByUser/${operator}/${userName}/${selectedGameType}`);
                    if (response?.success) {
                        setExposureData(response?.data || []);
                    } else {
                        setExposureData([]);
                    }
                } catch (error) {
                    console.error("Error fetching exposure list:", error);
                }
            };
            fetchExposureListDetails();
        } else {
            setSelectedGameType("sports");
        }
    }, [selectedGameType, userName, isOpen]);

    const handleGameTypeClick = (type) => {
        setSelectedGameType(type);
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                <div className="modal-content" style={{ minHeight: '50vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                    <div className="wrapper-body bgstyle">
                        <div className="modal-header">
                            <h3>Exposure of {userName}</h3>
                            <div className="ai-close closeBtn-modal closeIcon" onClick={onClose} tabIndex={0}>
                                <AiOutlineClose />
                            </div>
                        </div>
                    </div>
        
                    <div style={{ margin: 'auto' }}>
                        {gameType.map((type) => (
                            <button key={type} className={`linkButtonStyle ${selectedGameType === type ? 'active' : ''}`} onClick={() => handleGameTypeClick(type)}>
                                {type.toUpperCase().replace('_', ' ')}{type !== 'withdrawal_exposure' ? ' EXPOSURE' : ''}
                            </button>
                        ))}
                    </div>


                    <div className='table-responsive'>
                        <table className="odds-gap-table table-border-1">
                            <thead>
                                <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>S.No</th>
                                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>User ID</th>
                                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Match Name</th>
                                    <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Total Exposure</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exposureData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ textAlign: 'center' }}>{item.user_name}</td>
                                        <td style={{ textAlign: 'center' }}>{item.match_name}</td>
                                        <td style={{ textAlign: 'center' }}>{item.total_expo}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientExposure;