import { io } from 'socket.io-client';
import { useEffect, useState, useRef } from 'react';

const SocketComponent = ({ eventID, bookmakerOddsData, onDataReceived }) => {
    const URL = process.env.REACT_APP_BASE_URL;
    const socketRef = useRef(null);
    const [marketData, setMarketData] = useState(null);
    const [socketMarketData, setSocketMarketData] = useState(null);

    useEffect(() => {
        const getAssignMarket = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/events/matchDetails/${eventID}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setMarketData(data.data);
                } else {
                    const error = await response.json();
                    console.error('Error fetching market data:', error.message);
                }
            } catch (error) {
                console.error('Error fetching market data:', error.message);
            }
        };

        getAssignMarket();

        const intervalId = setInterval(getAssignMarket, 30000);

        return () => clearInterval(intervalId);
    }, [eventID]);

    useEffect(() => {
        if (!URL) return;

        socketRef.current = io(URL, {
            transports: ["websocket"],
            autoConnect: false,
        });

        const socket = socketRef.current;

        const handleConnect = () => {
            if (marketData?.match?.matchOddData[0]?.mid) {
                socket.emit('subMarket', marketData.match.matchOddData[0].mid);
            }

            if(onDataReceived?.bookmaker?.id){
                socket.emit('subMarket', onDataReceived?.bookmaker?.id.toString());
            }
        };

        const handleDisconnect = () => {
            console.warn('Disconnected from WebSocket');
        };

        const handleError = (error) => {
            console.error('Connection error:', error);
        };

        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);
        socket.on('connect_error', handleError);

        socket.on('App\\\\Events\\\\SportsBroadcastData', (data) => {
            setSocketMarketData(data);
        });

        socket.on('App\\\\Events\\\\BroadcastBookmaker', (data) => {
            bookmakerOddsData(data)
        });
        
        socket.connect();

        return () => {
            if (socket) {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('connect_error', handleError);
                socket.off('App\\\\Events\\\\SportsBroadcastData');
                socket.off('App\\\\Events\\\\BroadcastBookmaker');
                // socket.disconnect();
            }
        };
    }, [URL, marketData]);


    useEffect(() =>{
        if (socketMarketData?.length) {
            let updatedMatchOddData = marketData?.match?.matchOddData || [];
        
            const marketMap = new Map(updatedMatchOddData.map(m => [m.mid, m]));
        
            for (const market of socketMarketData) {
                const sportsMarketData = marketMap.get(market.mid);
                if (!sportsMarketData || !market.runners?.length) continue;
        
                if (market.st) sportsMarketData.st = market.st;
        
                const runnerMap = new Map(sportsMarketData.runners.map(r => [r.sid, r]));
        
                for (const runner of market.runners) {
                    const marketRunner = runnerMap.get(runner.sid);
                    if (!marketRunner || (!runner.ex && !runner.s)) continue;
        
                    if (runner.s) marketRunner.s = runner.s;
                    if (runner.ex?.b) marketRunner.ex.b = runner.ex.b;
                    if (runner.ex?.l) marketRunner.ex.l = runner.ex.l;
                }
            }
        
            setMarketData(prevState => ({
                ...prevState,
                match: {
                    ...prevState.match,
                    matchOddData: Array.from(marketMap.values()),
                },
            }));
        }
    },[socketMarketData]);

    const convertToK = (value) => {
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value ? value.toString() : null;
    };

    return (
        <div>
            <div className="book-maker-market">
                <div className="market-match-container">
                    <div>
                        <div>
                            <div className="" style={{ overflowX: "auto" }}>
                                <div className="match-name-time"></div>
                                <div className="market-odds-body">
                                    <div className="span-book">
                                        <p>MAIN ODDS</p>
                                    </div>
                                    <div className="market-odds-body"></div>
                                    <div className="market-odds-back">
                                        <div style={{ marginRight: ".5rem" }}><p>BACK</p></div>
                                        <div><p>LAY</p></div>
                                    </div>
                                </div>
                                <div className="boder-odd">
                                    {marketData?.match?.matchOddData.filter(mod => mod.marketName === "Match Odds").map((market, marketIndex) => (
                                        market?.runnerName?.map((m, i) => (
                                            !['No', 'Yes'].includes(m.RN) ? (
                                                <div key={i} className="odds-container">
                                                    <div className="box-odd-name" data-label="#">
                                                        <h3>{m.RN}</h3>
                                                    </div>
                                                    {(market?.runners).map((runner, runnerIndex) => (
                                                        m.SID === runner.sid ? ( 
                                                            <div key={runnerIndex} className="odds-container-1">
                                                                {runner?.ex?.b && (
                                                                    <div className="back-box-container-one" data-label="#">
                                                                        <div className="box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                                            <p>{runner?.ex?.b?.length ? runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).p : null}</p>
                                                                            <p style={{ fontSize: "10px" }}>{convertToK(runner?.ex?.b?.length ? runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).s : null)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="back-box-container-one">
                                                                    <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                                                        <p>{runner?.ex?.l?.length ? runner.ex.l.reduce((maxObj, obj) => (obj.p < maxObj.p ? obj : maxObj), runner.ex.l[0]).p : null}</p>
                                                                        <p style={{ fontSize: "10px" }}>{convertToK(runner?.ex?.l?.length ? runner.ex.l.reduce((maxObj, obj) => (obj.p < maxObj.p ? obj : maxObj), runner.ex.l[0]).s : null)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    ))}
                                                </div>
                                            ) : null
                                        ))
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocketComponent;
