import '../../index.css';
import moment from 'moment';
import Bets from '../bets/betsTab';
import { motion } from "framer-motion";
import { AiOutlineClose } from 'react-icons/ai';
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';
import { MdDelete, MdContentCopy  } from 'react-icons/md';
import SocketComponent from '../../socket/socketBroadcast';
import { getCaller, deleteCaller } from '../../services/api';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import Pagination from '../../component/pagination/Pagination';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showConfirmationDialog } from '../../utilities/toastHelper';

const RejectedBets = () => {
    const [searchUserID, setSearchUserID] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [originalUserList, setOriginalUserList] = useState([]);
    const [searchTerms, setSearchTerms] = useState({});
    const [userId, setUserId] = useState(null);
    const [copiedUserId, setCopiedUserId] = useState(null);
    const [rejectedEventList, setRejectedEventList] = useState([]);
    const [expandedEventId, setExpandedEventId] = useState(null);
    const [scope, setScope] = useState({});
    const [selected, setSelected] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [loading, setLoading] = useState(false);

    const [betListCurrentPage, setBetListCurrentPage] = useState(1);
    const [betListTotalPages, setBetListTotalPages] = useState(0);

    const [expandedListCurrentPage, setExpandedListCurrentPage] = useState(1);
    const [expandedListTotalPages, setExpandedListTotalPages] = useState(0);

    const [userBetListCurrentPage, setUserBetListCurrentPage] = useState(1);
    const [userBetListTotalPages, setUserBetListTotalPages] = useState(0);

    const limit = 25;

    useEffect(() => {
        const { bets } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({ bets, userType });
        rejectedEventsList(1);
    }, []);
    
    const rejectedEventsList = async (page = 1, user_id = '') => {
        try {
            const response = await getCaller(`api/v1/bets/rejectionBetEventList?user_id=${encodeURIComponent(user_id)}&page=${page}&limit=${limit}`);
            if (response?.success) {
                setRejectedEventList(response.data);              
                setBetListTotalPages(response?.pagination?.totalPages || 0);
                setBetListCurrentPage(response?.pagination?.currentPage || 0);
            } else {
                setBetListTotalPages(0);
                setRejectedEventList([]);
                console.error('Failed to fetch rejected events:', response?.message);
            }
        } catch (error) {
            console.error('Error fetching rejected events:', error);
            setRejectedEventList([]);
        }
    };
         
    const handleEventExpand = async (eventId, page = 1) => {
        try {
            setSelected([]);
            setAllSelected(false);

            if (!eventId) {
                console.warn("Error: eventId is missing, skipping API call.");
                return;
            }
    
            if (expandedEventId === eventId) {
                setExpandedEventId(null);
                setFilteredData([]);
                return;
            }
    
            setExpandedEventId(eventId);
            setLoading(true);

            const response = await getCaller(`api/v1/bets/rejectionBetList/${eventId}?page=${page}&limit=${limit}`);
            
            if (response?.success) {
                setFilteredData(response.data);
                setExpandedListTotalPages(response?.pagination?.totalPages || 0);
                setExpandedListCurrentPage(response?.pagination?.currentPage || 0);
            } else {
                setFilteredData([]);
                console.error("Failed to fetch rejected bets:", response?.message);
            }
        } catch (error) {
            console.error("Error fetching rejected bets:", error);
            setFilteredData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleRBListPage = async (page) => {
        if (page >= 1 && page <= betListTotalPages) {
            setBetListCurrentPage(page);
            await rejectedEventsList(page, searchUserID);
        }
    };

    const handleExpandPageChange = async (page) => {
        if (page >= 1 && page <= expandedListTotalPages && expandedEventId) {
            setExpandedListCurrentPage(page);
            setLoading(true);
            
            try {
                const response = await getCaller(`api/v1/bets/rejectionBetList/${expandedEventId}?page=${page}&limit=${limit}`);
                
                if (response?.success) {
                    setFilteredData(response.data);
                    setExpandedListTotalPages(response?.pagination?.totalPages || 0);
                    setExpandedListCurrentPage(response?.pagination?.currentPage || 0);
                } else {
                    setFilteredData([]);
                    console.error("Failed to fetch rejected bets:", response?.message);
                }
            } catch (error) {
                console.error("Error fetching rejected bets:", error);
                setFilteredData([]);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleUserBetPageChange = async (page) => {
        if (page >= 1 && page <= userBetListTotalPages) {
            setUserBetListCurrentPage(page);
            await openModal(userId, expandedEventId, page);
        }
    };

    const handleInputChange = (e) => {
        setSearchUserID(e.target.value.trim());
    };

    const handleUserIdSearch = async() => {
        await rejectedEventsList(1, searchUserID);
    };

    const openModal = async (userId, eventId, page = 1) => {
        if (!userId || !eventId) {
            console.error("Error: userId or eventId is missing.");
            return;
        }
    
        try {
            setUserId(userId);
    
            const response = await getCaller(`api/v1/bets/rejectionUserBetList/${userId}/${eventId}?page=${page}&limit=${limit}`);

            if (response?.success) {
                setSelectedUser(response.data || []);
                setOriginalUserList(response.data || []);
                setUserBetListTotalPages(response?.pagination?.totalPages || 0);
                setUserBetListCurrentPage(response?.pagination?.currentPage || 0);
            } else {
                setUserBetListTotalPages(0);
                setSelectedUser([]);
                console.error('Failed to fetch user-specific rejected bets.', response?.message);
            }
        } catch (error) {
            console.error("Failed to fetch user-specific rejected bets.", error);
        }
    };

    const handleOnClose = async () => {
        setSelectedUser(null);
    
        if (expandedEventId) {
            const betListResponse = await getCaller(`api/v1/bets/rejectionBetList/${expandedEventId}?page=${expandedListCurrentPage}&limit=${limit}`);
            if (betListResponse?.success) {
                setFilteredData(betListResponse.data);
            } else {
                setFilteredData([]);
            }
        }
    };

    const handleSearch = () => {
        const searchArr = Object.values(searchTerms).filter(Boolean);
    
        if (searchArr.length > 0) {
            const updatedList = originalUserList.filter((item) =>
                searchArr.every((term) =>
                    Object.values(item).some((value) =>
                        String(value).toLowerCase().includes(term.toLowerCase())
                    )
                )
            );
            setSelectedUser(updatedList);
        } else {
            setSelectedUser(originalUserList);
        }
    };    

    const onChangeSearch = async (value, id) => {
        setSearchTerms((prev) => ({ ...prev, [id]: value.trim() }));
    };

    const handleDeleteClient = async (userList, eventId) => {
        const result = await showConfirmationDialog("You want to delete these rejected bets?");
    
        if (result.isConfirmed) {
            const res = await deleteCaller(`api/v1/bets/deleteRejectedBet/${eventId}`, { user_list: userList.map(user => `"${user}"`).join(',') });
            if (res.success === true) {
                showToast("Rejected bet has been successfully deleted!");
                await rejectedEventsList(betListCurrentPage, searchUserID);
    
                if (expandedEventId === eventId) {
                    const response = await getCaller(`api/v1/bets/rejectionBetList/${eventId}?page=${expandedListCurrentPage}&limit=${limit}`);
                    if (response?.success) {
                        setFilteredData(response.data);
                    } else {
                        setFilteredData([]);
                    }
                }
            } else {
                showToast(res.message, true);
            }
        }
    };

    const copyToClipboard = (userId) => {
        navigator.clipboard.writeText(userId).then(() => {
            setCopiedUserId(userId);
            setTimeout(() => setCopiedUserId(null), 300);
        })
    };

    const handleSocketDataUpdate = (updatedSocketBets) => {
        if(userId === updatedSocketBets?.user_id && expandedEventId === updatedSocketBets?.event_id) {
            setSelectedUser((prev) => [updatedSocketBets, ...prev]);
        }
    };

    const manageChecked = (event) => {
        const value = event.target.checked;
        setAllSelected(value);

        if (value) {
            const userIds = filteredData.map(m => m.user_id);
            setSelected(userIds);
        } else {
            setSelected([]);
        }
    };

    const markCheck = (event) => {
        let updatedList = [...selected];
        if (event.target.checked) {
            updatedList = [...updatedList, event.target.value];
            if(updatedList.length === filteredData.length){
                setAllSelected(true);
            }
        } else {
            updatedList.splice(updatedList.indexOf(event.target.value), 1);
            if(allSelected && updatedList.length < filteredData.length){
                setAllSelected(false);
            }
        }
        setSelected(updatedList);
    };

    return (
        <>
            <Bets />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="result-sport-container" style={{marginBottom: '15px'}}>
                    <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px', alignItems: 'flex-end' }}>
                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="user">User ID</label>
                            <input type="search" autoComplete="off" className="result-input" name="user" placeholder="User ID" value={searchUserID} onChange={handleInputChange}/>
                        </div>

                        <div className="wrapper-content">
                            <div className="custom-btn" style={{ marginTop: '20px' }}>
                                <Button className={'submit'} name={'Search'} type="button" onClick={handleUserIdSearch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="league-setting-container-newCon">
                    <div className="market-section">
                        <div className="wrapper-body-sty" style={{backgroundColor: '#d1cfcf', color: '#000000'}}>
                            <h3>List of Rejected Bets</h3>
                        </div>

                        {rejectedEventList.length > 0 ? (
                            rejectedEventList.map((event) => (
                                <div key={event.event_id} className="market-section sty">
                                    <div onClick={() => handleEventExpand(event.event_id)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                        {expandedEventId === event.event_id ? <BsChevronDown /> : <BsChevronRight />}&nbsp;
                                        <p>{event.event_name} - {moment(event.event_date).format('DD/MM/YYYY HH:mm')}</p>
                                    </div>
                                    
                                    {expandedEventId === event.event_id && (
                                        <>
                                            {
                                                loading ?
                                                    <div className="loader-wrapper">
                                                        <div className='loader'></div>
                                                    </div>
                                                    :
                                                    <div style={{ border: '1px solid #a0a3a3', margin: '13px' }}>
                                                        {filteredData.length > 0 ? (
                                                            <table className="odds-gap-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <input type="checkbox" checked={allSelected} onChange={(event) => manageChecked(event)} className='check-container' />
                                                                            <button
                                                                                style={{ background: 'transparent', border: 'none', outline: 'none', cursor: (!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? 'not-allowed' : 'pointer' }}
                                                                                disabled={!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !(scope?.bets?.delete))}
                                                                            >
                                                                                <MdDelete style={{ fontSize: "1.5rem", color: (!selected.length || (scope?.userType?.toLowerCase() === 'manager' && !scope?.bets?.delete)) ? "#ccc" : "red" }}
                                                                                    onClick={() => handleDeleteClient(selected, expandedEventId)} />
                                                                            </button>
                                                                        </th>
                                                                        <th>User ID</th>
                                                                        <th style={{ textAlign: 'center' }}>Rejected Bet Count</th>
                                                                        <th style={{ width: '50%' }}></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {filteredData.map((data) => (
                                                                        <tr key={data.user_id}>
                                                                            <td><input type="checkbox" checked={selected.includes(data.user_id)} value={data.user_id} onChange={markCheck} className='check-container' /></td>
                                                                            <td>
                                                                                <button className="link-button" onClick={() => openModal(data.user_id, expandedEventId, 1)}>{data.user_id}</button>
                                                                                <button className="copy-button" onClick={() => copyToClipboard(data.user_id)}>{copiedUserId === data.user_id ? 'Copied' : <MdContentCopy style={{ fontSize: '1.5rem' }} />}</button>
                                                                            </td>
                                                                            <td style={{ textAlign: 'center' }}>{data.bets_count}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        ) : (
                                                            <ErrorBoundary text='Rejected Bets' />
                                                        )}
                                                    </div>
                                            }
                                            <Pagination currentPage={expandedListCurrentPage} totalPages={expandedListTotalPages} onPageChange={handleExpandPageChange} />
                                        </>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div>
                                <span><ErrorBoundary text='Rejected Bets'></ErrorBoundary></span>
                            </div>
                        )}
                    </div>
                </div>

                <Pagination currentPage={betListCurrentPage} totalPages={betListTotalPages} onPageChange={handleRBListPage} />
            </motion.div>

            {selectedUser && (
                <>
                    <SocketComponent eventID={userId} onDataUpdate={handleSocketDataUpdate}/>
                    
                    <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                        <div className="modal-content" style={{ minHeight: '50vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <div className="wrapper-body">
                                <div className="modal-header">
                                    <h2>Rejected Bets for {userId}</h2>
                                    <div className="ai-close closeBtn-modal" onClick={handleOnClose}>
                                        <AiOutlineClose />
                                    </div>
                                </div>
                            </div>

                            <div className='flex-wrap-between'>
                                <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-1')} className='result-input' style={{ width: '20%' }} />
                                <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-2')} className='result-input' style={{ width: '20%' }} />
                                <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-3')} className='result-input' style={{ width: '20%' }} />
                                <div className="custom-btn" >
                                    <Button className='submit' type='button' name='Search' onClick={handleSearch}/>
                                </div>
                            </div>
                            
                            <div className='tableResponsive-wrap'>
                                <table className="odds-gap-table table-border-1">
                                    <thead>
                                        <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                            {/* <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Event</th> */}
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Market</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Selection</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Stake (INR)</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Current Odds</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Screen Odds</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Requested Odds</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Bet Time</th>
                                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>IP</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {selectedUser.length > 0 ? (
                                            selectedUser.map((bet, index) => (
                                                <tr key={index} className={bet.selection_type + ' custom-row'}>
                                                    {/* <td>{bet.event_name}</td> */}
                                                    <td>{bet.market_name}</td>
                                                    <td>{bet.selection_name}</td>
                                                    <td>{bet.stake}</td>
                                                    <td>{bet.current_odds}</td>
                                                    <td>{bet.screen_odds}</td>
                                                    <td>{bet.requested_odds}</td>
                                                    <td>{moment.utc(bet.bet_time).local().format('DD-MM-YYYY HH:mm:ss')}</td>
                                                    <td>{bet.ip}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8"><ErrorBoundary /></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            
                            <Pagination currentPage={userBetListCurrentPage} totalPages={userBetListTotalPages} onPageChange={handleUserBetPageChange} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default RejectedBets;