import React from 'react';
import '../result/result.css';
import { useLocation, useNavigate } from 'react-router-dom';

const ResultTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const resultTabData = [
    { name: "Match Result", route: "/results/match_result" },
    { name: "Fancy Result", route: "/results/fancy_result" },
    { name: "Bookmaker Result", route: "/results/bookmaker_result" },
    { name: "Pending Match", route: "/results/pending_match" },
    { name: "Pending Fancy", route: "/results/pending_fancy" },
    { name: "Pending Bookmaker", route: "/results/pending_bookmaker" },
    { name: "Completed Fancy", route: "/results/completed_fancy" },
  ];

  return (
    <div className="wrapper" >
      <ul className="tab-container">
        {
          resultTabData.map((el, i) => (
            <li key={i} className={`tab-box ${location.pathname === el.route ? 'active-result-tab' : null}`} onClick={() => navigate(el.route)} >
              {el.name}
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default ResultTab;