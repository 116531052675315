import moment from 'moment';
import '../result/result.css';
import { AiOutlineClose } from 'react-icons/ai';
import { updateCaller } from '../../services/api';
import React,{ useState, useEffect } from 'react';
import { IoSearchOutline, } from 'react-icons/io5';
import Button from '../../component/button/Button';
import ErrorBoundary from '../../error/ErrorBoundary';
import Pagination from '../../component/pagination/Pagination';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showConfirmationDialog } from '../../utilities/toastHelper';

const MatchResultTable = ({ resultData, setResultData, page, setPage, totalPages, perPage, allResult }) => {
  const [inputValue, setInputValue] = useState("");
  const [rowOpen, setRowOpen] = useState(null);
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [scope, setScope] = useState();

  const handlePageChange = async(page) => {
    setPage(page);
    await allResult(page);
  };

  let completeData = resultData;

  useEffect(() => {
    const { rollback } = getLocalData('scope');
    const userType = getLocalData('user_role');
    setScope({ rollback, userType });
  }, []);

  const filteredData = completeData?.filter(e =>
    e.event_name?.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleRollBack = async (e) => {
    const resultID = e.id;
    const result = await showConfirmationDialog('You want to Rollback the Result?');
    if (result.isConfirmed) {
      const response = await updateCaller(`api/v1/results/${resultID}/rollback`);
      if (response.success) {
        showToast(response.message);
        setResultData(resultData.map(e => e.id !== resultID ? e : { ...e, is_rollback: 1 }));
      } else {
        showToast(response.message, true);
      }
    }
  };

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null);
    } else {
      setRowOpen(index);
      setShowRollbackStatus(false);
      setShowResultStatus(false);
    }
  };

  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus);
    setShowRollbackStatus(false);
  };

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus);
    setShowResultStatus(false);
  };

  return (
    <>
      <div className="table-border" style={{ padding: '0px' }}>
        <div className="wrapper-body">
          <div className="wrapper-content-1">
            <div className='searchcard'>
              <input type="text" autoComplete="off" placeholder='Search By Event Name' className='result-inputBorder' style = {{fontSize: 'medium'}} onChange={e => setInputValue(e.target.value)} value={inputValue}/>
              <div className="ai-close" onClick={() => setInputValue('')}>
                {inputValue ? <AiOutlineClose /> : <IoSearchOutline />}
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
        {filteredData?.length > 0 ? (
          <table width="100%">
            <thead>
              <tr className='custom-row'>
                <th>#</th>
                <th><div style={{ width: "60px" }}>MARKET</div></th>
                <th><div style={{ width: "105px" }}>DECLARED BY</div></th>
                <th><div>SPORT</div></th>
                <th><div>EVENT NAME</div></th>
                <th><div>RESULT</div></th>
                <th><div>DATE</div></th>
                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <th><div>ROLLBACK</div></th>
                }
              </tr>
            </thead>
            <tbody>
              {filteredData.map((e, i) => (
                <React.Fragment key={i}>
                  <tr className='custom-row' onClick={() => toggleRow(i)}>
                    <td>
                      <div className='result_status'>
                        {rowOpen === i ? (
                          <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                        ) : (
                          <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                        )} 
                        {e?.id ?? "Auto"}
                      </div>
                    </td>
                    <td>{e?.market_name ?? "Auto"}</td>
                    <td>{e?.created_by ?? "Auto"}</td>
                    <td style={{ textTransform: "capitalize" }}>{e.sport_name ?? "No Data"}</td>
                    <td><div style={{ width: "250px" }}>{e.event_name ?? "No Data"}</div></td>
                    <td><div style={{ width: "150px", textTransform: "capitalize" }}>{e.selection}</div></td>
                    <td><div style={{ width: "190px" }}>{(new Date(e.created_at)).toLocaleString()}</div></td>
                    {(scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) &&
                      <td>
                        <Button
                          className='submit'
                          type='button'
                          disabled={e.is_rollback || (scope?.userType === 'manager' && !scope?.rollback?.add)}
                          name='RollBack'
                          onClick={() => handleRollBack(e)}
                        />
                      </td>
                    }
                  </tr>
                  {rowOpen === i && (
                    <>
                      <tr className='custom-row' onClick={toggleResultStatus}>
                        <td colSpan={8}>
                          <div className='result_status'>&nbsp; &nbsp;
                            {showResultStatus ? (
                              <>&nbsp;<BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" , marginBottom:"-3px"}} />&nbsp;</>
                            ) : (
                              <>&nbsp;<BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom:"-3px" }} />&nbsp;</>
                            )} Result Status
                          </div>
                        </td>
                      </tr>
                      {e.result_status && showResultStatus && (
                        <tr className='custom-row'>
                          <td colSpan={9}>
                            <div className='result_status'>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Market</th>
                                    <th>Status</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Process Count</th>
                                    <th>User Count</th>
                                    <th>Bet Count</th>
                                    <th>End Point</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                    <tr key={index}>
                                      <td>{market}</td>
                                      <td>{data.status}</td>
                                      <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                      <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                      <td>{data.processCount}</td>
                                      <td>{data.userCount}</td>
                                      <td>{data.betCount}</td>
                                      <td>{data.endpoint}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                      {e.rollback_status && (
                        <tr className='custom-row' onClick={toggleRollbackStatus}>
                          <td colSpan={8}>
                            <div className='result_status'>&nbsp; &nbsp;
                              {showRollbackStatus ? (
                                <>&nbsp;<BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom:"-3px" }} />&nbsp;</>
                              ) : (
                                <>&nbsp;<BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" , marginBottom:"-3px"}} />&nbsp;</>
                              )} Rollback Status
                            </div>
                          </td>
                        </tr>
                      )}
                      {e.rollback_status && showRollbackStatus && (
                        <tr className='custom-row'>
                          <td colSpan={9}>
                            <div className='result_status'>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Market</th>
                                    <th>Status</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Process Count</th>
                                    <th>User Count</th>
                                    <th>Bet Count</th>
                                    <th>End Point</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                    <tr key={index}>
                                      <td>{market}</td>
                                      <td>{data.status}</td>
                                      <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                      <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td> 
                                      <td>{data.processCount}</td>
                                      <td>{data.userCount}</td>
                                      <td>{data.betCount}</td>
                                      <td>{data.endpoint}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ): <ErrorBoundary />}
        </div>
      </div>
      {/* <Pagination page={page} setPage={setPage} totalPages={totalPages} maximo={maximo} /> */}
      <Pagination currentPage={page} totalPages={totalPages || 0} onPageChange={handlePageChange}/>
    </>
  );
};

export default MatchResultTable;
