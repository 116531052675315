import React from 'react';
import { MdSave } from 'react-icons/md';
import { updateCaller } from '../../services/api';
import '../manageOutsideMarket/manageskymarket.css';
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast } from '../../utilities/toastHelper';
import { handleKeyDown } from '../../utilities/handleKeyDown';
import ToogleButton from '../../component/toggleButton/ToogleButton';

const ManageBookmakerTable = ({ getBookmakerList, setGetBookmakerList }) => {

  const handleChange = (index, fieldName, value) => {
    let newFormValues = [...getBookmakerList.data];
    newFormValues[index][fieldName] = value;
    newFormValues[index] = { ...newFormValues[index] };
    setGetBookmakerList({ data: newFormValues });
  };

  const handleToggle = (fieldName, value, index, eventID, bookmakerID) => {
    const updatedBookmakers = [...getBookmakerList.data];
    updatedBookmakers[index][fieldName] = value ? 1 : 0;
    updatedBookmakers[index] = { ...updatedBookmakers[index] };
    setGetBookmakerList({ data: updatedBookmakers });
  };

  const handleUpdateBookmaker = async (eventID, bookmakerID, index) => {
    const bookmaker = getBookmakerList.data[index];
    // delete bookmaker.eventID;
    const response = await updateCaller(`api/v1/bookmakers/${eventID}/${bookmakerID}`, bookmaker);
    if (response.success) {
      showToast(response.message, false);
    } else {
      showToast(response.message, true);
    }
  };
 
  return (
    <div className="sky-fancy-market" style={{ overflowX: "auto" }}>
      <div>
        {getBookmakerList.data.length > 0 && (
          <div className="sky-fancy-head">
            <p>BOOKMAKER MARKET</p>
          </div>
        )}

        {getBookmakerList.data.length > 0 ? (
          <table width="100%" className='sky-fancy-table'>
            <thead>
              <tr id='fancy-head-coloumn'>
                <th> TITLE </th>
                <th> MAX STAKE </th>
                <th> MAX MARKET LIMIT </th>
                <th> BET DELAY </th>
                <th> SUSPEND </th>
                <th> COMMISSION </th>
                <th> ACTIVE </th>
                <th> ACTIONS </th>
              </tr>
            </thead>
            <tbody>
              {
                getBookmakerList.data.map((bookmaker, index) => (
                  <tr className="sky-fancy-row" key={bookmaker.id}>
                    <td>
                      <input type="text" name="market_name" placeholder="Bookmaker Name" className="fancy-stake-input-1" style={{ width: "inherit" }} value={bookmaker.market_name || ""} onChange={(event) => handleChange(index, "market_name", event.target.value)}/> <br />
                      <input type="text" name="msg" placeholder="Message" className="fancy-stake-input-1" style={{ width: "inherit" }} value={bookmaker.msg || ""} onChange={(event) => handleChange(index, "msg", event.target.value)}/>
                    </td>

                    <td>
                      <input type="number" name="stakelimit" autoComplete="off" placeholder="Stake Limit" className="sky-bookmaker" style={{ width: "inherit" }} value={bookmaker.stake_limit || ""} onChange={(event) => handleChange(index, "stake_limit", event.target.value)} onKeyDown={handleKeyDown} />
                    </td>

                    <td>
                      <input type="number" autoComplete="off" name="yes_outcome" placeholder='Max Stake Limit' className='sky-bookmaker' style={{ width: "inherit" }} value={bookmaker.max_market_limit || ""} onChange={(event) => handleChange(index, "max_market_limit", event.target.value)} onKeyDown={handleKeyDown} />
                    </td>

                    <td>
                      <input type="number" autoComplete="off" name="no_outcome" placeholder='Bet Delay' className='sky-bookmaker' style={{ width: "inherit" }} value={bookmaker.bet_delay || ""} onChange={(event) => handleChange(index, "bet_delay", event.target.value)} onKeyDown={handleKeyDown} />
                    </td>

                    <td>
                      <div className="toggle-password">
                        <ToogleButton onChange={(value) => handleToggle("is_sus", value, index, bookmaker.eventID, bookmaker.bookmakerID)} defaultChecked={bookmaker.is_sus ? true : false}/>
                      </div>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <ToogleButton onChange={(value) => handleToggle("is_com", value, index, bookmaker.eventID, bookmaker.bookmakerID)} defaultChecked={bookmaker.is_com ? true : false}/>
                      </div>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <ToogleButton onChange={(value) => handleToggle("is_active", value, index, bookmaker.eventID, bookmaker.bookmakerID)} defaultChecked={bookmaker.is_active ? true : false}/>
                      </div>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <div className="actions_tab">
                          <div className="fancy-submit">
                            <MdSave className="icon" onClick={() => handleUpdateBookmaker(bookmaker.eventID, bookmaker.bookmakerID, index)} tabIndex={0} 
                              onKeyDown={(e) => {
                                if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                                  e.preventDefault(); handleUpdateBookmaker(bookmaker.eventID, bookmaker.bookmakerID, index)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) }
            </tbody>
          </table>
        ) : <ErrorBoundary text='Bookmaker Data'/>}
      </div>
    </div>
  )
};

export default ManageBookmakerTable;