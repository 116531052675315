import { useEffect } from "react";

const useSingleTab = () => {
  useEffect(() => {
    const tabKey = "unique_tab_key";

    const checkTab = () => {
      if (localStorage.getItem(tabKey)) {
        alert("This app is already open in another tab.");
        window.location.href = "about:blank";
        window.close(); // Close the tab (may not work in all browsers)
      } else {
        localStorage.setItem(tabKey, "active");
      }
    };

    checkTab();

    const handleUnload = () => {
      localStorage.removeItem(tabKey);
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      localStorage.removeItem(tabKey);
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);
};

export default useSingleTab;