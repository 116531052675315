import './custom.css';
import '../customGames/custom.css';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import Button from '../../component/button/Button';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';
import CONSTANTS from '../../../src/utilities/constants';
import Pagination from '../../component/pagination/Pagination';
import ToggleButton from '../../component/toggleButton/ToogleButton';
import { deleteCaller, getCaller, updateCaller } from '../../services/api';
import { showToast, showConfirmationDialog, showDeleteMessage } from '../../utilities/toastHelper';

const ManageWorliMatka = () => {
    const [matchList, setMatchList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const navigate = useNavigate();
    const [fetchedPages, setFetchedPages] = useState({});
    const  limit = 100;

    const getMatchList = async (page = 1) => {
        if (fetchedPages[page]) {
            setMatchList(fetchedPages[page]);
            if (page !== 1) return;
        }
        const res = await getCaller(`api/v1/worli/matches/all?page=${page}&limit=${limit}`);
        setMatchList(res.data.data);
        setTotalPage(res?.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: res.data.data }));
    };

    useEffect(() => {
        getMatchList();
    }, []);

    const handlePageChange = async(page) => {
        setCurrentPage(page);
        await getMatchList(page);
    };

    const deleteCustomSport = async (e) => {
        const confirmation = await showConfirmationDialog(`You want to delete this match (${e.match_title})`);
        
        if (confirmation.isConfirmed) {
            const res = await deleteCaller(`api/v1/worli/matches/${e.id}`);
            
            if (res.success === true) {
                showDeleteMessage("Your sport was deleted successfully!");
                
                setMatchList(matchList.filter((el) => el.id !== e.id));
                setFetchedPages((prev) => {
                    const updatedPages = { ...prev };
                    Object.keys(updatedPages).forEach((page) => {
                        updatedPages[page] = updatedPages[page].filter((el) => el.id !== e.id);
                    });
                    return updatedPages;
                });
            }
        }
    };

    const handleInputChange = (e, index) => {
        const updatedList = [...matchList];
        updatedList[index].message = e.target.value;
        setMatchList(updatedList);
    };

    const updateMatchData = async (e, event, key) => {
        const matchData = {
            id: e.id,
            categoryId: e.category_id.toString(),
            matchTitle: e.match_title,
            matchType: JSON.parse(e.match_type),
            openBids: e.open_bids,
            closeBids: e.close_bids,
            message: key === "message" ? event  : e.message ?? "",
            isActive: e.is_active,
            isDelete: 0,
            openSuspend: e.open_suspend,
            closeSuspend: e.close_suspend,
            suspend: e.suspend,
            closed_date: JSON.parse(e.closed_date),
            closed_day: JSON.parse(e.closed_day),
            action: key,
        };

        if (key !== 'message') {
            matchData[key] = event === true ? 1 : 0;
        };

        let title = "";
        let shouldUpdate = true;
        
        switch (key) {
            case "isActive":
                title = matchData[key] ? "Active" : "Inactive";
                break;
            case "message":
                title = "Message Updated";
                break;
            default:
                title = CONSTANTS.MATCH_FIELDS[key].concat(matchData[key] ? " Enabled" : " Disabled");
                break;
        };
      
        if(shouldUpdate){
            const res = await updateCaller(`api/v1/worli/matches/${matchData.id}`, matchData);
            if (res.success === true) {
                await getMatchList();
                navigate('/manage/worli-matka');
                showToast(title, false);
            }
            else {
                showToast(res.message, true);
            }
        }
    };
  
    return (
        <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
            <div className='table-border'>
                <div className="wrapper-body">
                    <div className="wrapper-content">
                        <h3> Manage Worli Matka </h3>
                        <div className="custom-btn">
                            <Button className='submit' name='ADD WORLI' type='type' onClick={() => navigate('/add/worli-matka/match')}/>
                        </div>
                    </div>
                </div>

                <table width="100%">
                    <thead>
                        <tr className='custom-row'>
                            <th > # </th>
                            <th > TITLE </th>
                            <th > CATEGORY </th>
                            <th > BIDS OPEN TIME </th>
                            <th> BIDS CLOSE TIME </th>
                            <th> MESSAGE </th>
                            <th> OPEN SUSPEND </th>
                            <th> CLOSE SUSPEND </th>
                            <th> SUSPEND </th>
                            <th> ACTIVE </th>
                            <th> ACTION </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            matchList?.length > 0 ?
                                matchList?.map((e, i) => (
                                    <tr className='custom-row' key={e?.id} >
                                        <td data-label="#"> {i+1} </td>
                                        <td data-label="MATCH NAME" style={{ textTransform: "capitalize" }}> {e?.match_title} </td>
                                        <td data-label="CATEGORY NAME" style={{ textTransform: "capitalize" }}> {e?.category_name} </td>
                                        <td data-label="OPEN BIDS" style={{ textTransform: "capitalize" }}> {e?.open_bids} </td>
                                        <td data-label="CLOSE BIDS" style={{ textTransform: "capitalize" }}> {e?.close_bids} </td>
                                        <td >
                                            <input className="input-field" type="text" autoComplete="off" value={e.message || ""} onChange={(event) => handleInputChange(event, i)}
                                            onBlur={(event) => updateMatchData(e, event.target.value, "message")}/>
                                        </td>
                                        <td data-label="OPEN SUSPEND">
                                            <ToggleButton defaultChecked={e.open_suspend ? true : false} onChange={(event) => { updateMatchData(e, event, "openSuspend") }} />
                                        </td>
                                        <td data-label="CLOSE SUSPEND">
                                            <ToggleButton defaultChecked={e.close_suspend ? true : false} onChange={(event) => { updateMatchData(e, event, "closeSuspend") }} />
                                        </td>
                                        <td data-label="SUSPEND">
                                            <ToggleButton defaultChecked={e.suspend ? true : false} onChange={(event) => { updateMatchData(e, event, "suspend") }} />
                                        </td>
                                        <td data-label="ACTIVE">
                                            <ToggleButton defaultChecked={e.is_active ? true : false} onChange={(event) => { updateMatchData(e, event, "isActive") }} />
                                        </td>
                                        <td >
                                            <div className='user-btn-action'>
                                                <div className='edit-btn'
                                                    onClick={() => navigate('/add/worli-matka/match', {state: { data: e }})}>
                                                    <MdEdit style={{ fontSize: '1.5rem', color: 'white'}}/>
                                                </div>

                                                <div className='edit-btn delete-btn' onClick={() => deleteCustomSport(e)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white'}}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) 
                            : <ErrorBoundary />
                        }
                    </tbody>
                </table>
            </div>
            
            <Pagination
                currentPage={currentPage}
                totalPages={totalPage}
                onPageChange={handlePageChange}
            />
        </motion.div>
    )
};

export default ManageWorliMatka;