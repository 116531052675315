import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../component/pagination/Pagination'
import { getCaller } from '../../services/api'
import { motion } from "framer-motion";
import '../downline/downline.css'
import getLocalData from '../../utilities/LocalStorageData.utilities';
import ErrorBoundary from '../../error/ErrorBoundary';

function Downline() {
    const [allAgents, setAllAgents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [scope, setScope] = useState();
    const limit = 100;

    useEffect(()=>{
        const {markets} = getLocalData('scope');
        setScope(markets);
    }, []);

    const showAllAgents = async (page=1) => {
        if (fetchedPages[page]) {
            setAllAgents(fetchedPages[page]);
            return;
        }

        const agentShowResponse = await getCaller(`api/v1/users/showAgents?page=${page}&limit=${limit}`);
        setAllAgents(agentShowResponse.data);
        setTotalPage(agentShowResponse.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: agentShowResponse.data }));
        setLoading(false);
    };
    
    const handlePageChange = async(page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await showAllAgents(page);
        }
    };

    useEffect(() => {
        showAllAgents();
    }, []);
    
    const navigate = useNavigate();

    const handleNavigate = (item) => {
        if (item.sport_name === null) {
            navigate('/downline/manager_manage_market', { state: { name: item } });
        } else {
            navigate('/downline/assign_market', { state: { name: item } });
        }
    };

    return (
        <>
            {
                loading ? 
                <div className="loader-wrapper">
                    <div className='loader'></div>
                </div> :
                <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                    <div className="wrapper-head">
                        <h3> All Agents </h3>
                    </div>
                    <div className="table-border" style={{ overflowX: "auto" }}>
                        <table width="100%" >
                            <thead>
                                <tr className='custom-row'>
                                    <th> # </th>
                                    <th> UID </th>
                                    <th> Agent Name </th>
                                    <th> SPORT </th>
                                    <th> EVENT NAME </th>
                                    <th> MARKET</th>
                                    <th> STATUS </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allAgents?.length > 0 ?
                                        allAgents?.map((el, i) => (
                                            <tr className='custom-row' key={i}>
                                                <td data-label="#"> {el.id} </td>
                                                <td data-label="UID"> {scope?.read ? <p className='uid_name' onClick={() => handleNavigate(el)}> {el.user_id} </p> : <p> {el.user_id} </p> } </td>
                                                <td data-label="NAME"> {el.name} </td>
                                                <td data-label="SPORT"> {el?.sport_name ?? "-"} </td>
                                                <td data-label="EVENT NAME "> {el?.event_name ?? "-"} </td>
                                                <td data-label="MARKET"> {el?.market_type? el.market_type + (el.market_type === 'fancy'? "("+ el.market_id.toString().split("_").join(" ") +")": "") :"-"} </td>
                                                <td data-label="STATUS">
                                                    <p className={`status_online ${el?.isOnline ? 'online' : 'offline'}`}>
                                                        {el?.isOnline ? "Online" : "offline"}
                                                    </p>
                                                </td>
                                            </tr>
                                        )) 
                                    : <ErrorBoundary />
                                }
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
                </motion.div>
            }
        </>
    )
};

export default Downline;