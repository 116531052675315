import '../customGames/custom.css';
import { motion } from "framer-motion";
import CustomGamesTab from './CustomGamesTab';
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import Button from '../../component/button/Button';
import ErrorBoundary from '../../error/ErrorBoundary';
import { getCaller, deleteCaller } from '../../services/api';
import Pagination from '../../component/pagination/Pagination';
import { showToast, showDeleteMessage, showConfirmationDialog } from "../../utilities/toastHelper";

const CustomSport = () => {
    const [customSport, setCustomSport] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const navigate = useNavigate();
    const limit = 100;

    const getCustomSport = async (page = 1) => {
        if (fetchedPages[page]) {
            setCustomSport(fetchedPages[page]);
            return;
        }
        const res = await getCaller(`api/v1/sports/management/getSport?page=${page}&limit=${limit}`);
        setCustomSport(res.data);
        setTotalPage(res?.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: res.data }));
    };

    useEffect(() => {
        getCustomSport();
    }, []);

    const handlePageChange = async(page) => {
        setCurrentPage(page);
        await getCustomSport(page)
    };

    const deleteCustomSport = async (e) => {
        const result = await showConfirmationDialog(`You want to delete this sport (${e.name})?`);
        
        if (result.isConfirmed) {
            const res = await deleteCaller(`api/v1/sports/deleteSport?id=${e.id}`);
            
            if (res.success === true) {
                showDeleteMessage("Your sport was deleted successfully!");
                setTimeout(() => {
                    setCustomSport((prev) => prev.filter((el) => el.id !== e.id));
                }, 0);
            } else {
                showToast(res.message, true);
            }
        }
    };

    return (
        <>
            <CustomGamesTab/>
            <motion.div className="wrapper"  initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className='table-border'>
                    <div className="wrapper-body">
                        <div className="wrapper-content">
                            <h3> Custom Sport </h3>
                            <div className="custom-btn">
                                <Button className={'submit'} name={'ADD SPORT'} type={'type'} onClick={()=> navigate('/custom_add_sport')}/> 
                            </div>
                        </div>
                    </div>
                    
                    <table width="100%">
                        <thead>
                            <tr className='custom-row'>
                                <th > # </th>
                                <th > SPORT NAME </th>
                                <th> ACTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customSport?.length > 0 ? customSport?.map((e, i) => (
                                    <tr className='custom-row' key={i}>
                                        <td data-label="#"> {e?.id} </td>
                                        <td data-label="SPORT NAME" style={{textTransform:"capitalize"}}> {e?.name} </td>
                                        <td>
                                            <div className='user-btn-action'>
                                                <div className='edit-btn' onClick={()=> navigate('/custom_add_sport', { state: {data: e}}) }>
                                                    <MdEdit style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </div>

                                                <div className='edit-btn delete-btn' onClick={() => deleteCustomSport(e)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) : <ErrorBoundary />
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
            </motion.div>
        </>
    )
};

export default CustomSport;