import { postCaller } from "../../services/api";
import { AiOutlineClose } from 'react-icons/ai';
import ErrorBoundary from '../../error/ErrorBoundary';
import React, { useEffect, useState,useCallback } from "react";

const FancyInfoModal = ({ isOpen, onClose, fancyMarketId, ID, matchID }) => {
    const [fancyInfo, setFancyInfo] = useState({});
    
    const fetchFancyInfoDetails = useCallback(async () => {
        const requestData = {
            fancy_market_id: fancyMarketId,
            id: ID,
            match_id: matchID
        };

        const operator = "CLICKBET";

        try {
            const response = await postCaller(`api/v1/markets/getFancyBooksForSportsMarket/${operator}`, requestData);
            if (response.success) {
                setFancyInfo(response?.data);
            } else {
                setFancyInfo({});
            }
        } catch (err) {
            console.error("Error fetching fancy info details:", err);
        }
    }, [fancyMarketId, ID, matchID]);
    
    useEffect(() => {
        fetchFancyInfoDetails();
    }, [fetchFancyInfoDetails]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
            <div className="modal-content widthMySty" style={{ position: 'relative', display: 'flex', flexDirection: 'column', minWidth: 'fit-content' }}>
                <div className="wrapper-body bgstyle">
                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Fancy Book</span>
                        <button className="ai-close closeBtn-modal crossBtn" onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>
                            <AiOutlineClose />
                        </button>
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className="odds-gap-table table-border-1">
                        <thead>
                            <tr className="hedStyle">
                                <th style={{ textAlign: 'center' }}>Position</th>
                                <th style={{ textAlign: 'center' }}>Profit/Loss</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(fancyInfo).length > 0 ? (
                                Object.entries(fancyInfo).map(([position, profitLoss]) => (
                                    <tr key={position}>
                                        <td style={{ textAlign: 'center' }}>{position}</td>
                                        <td style={{ textAlign: 'center' }}>{profitLoss}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2" style={{ textAlign: "center", padding: "10px" }}>
                                        <ErrorBoundary text="Data" />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FancyInfoModal;
