import moment from 'moment';
import { getCaller } from "../../services/api";
import { AiOutlineClose } from 'react-icons/ai';
import ErrorBoundary from '../../error/ErrorBoundary';
import Pagination from '../../component/pagination/Pagination';
import React, { useEffect, useState, useCallback } from "react";

const BetModal = ({ isOpen, onClose, matchID, marketID }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [bets, setBets] = useState([]);
    const limit = 25;

    const fetchBetDetails = useCallback(async (page = 1) => {
        const type = 1;
        const operator = "CLICKBET";

        try {
            const response = await getCaller(`api/v1/markets/getUserBetsForSportsMarket/${matchID}/${marketID}/${type}/${operator}?page=${page}&limit=${limit}`);
            if (response.success) {
                setBets(response?.data);
                setTotalPages(response?.pagination?.pages || 0);
                setCurrentPage(response?.pagination?.page || 1);
            } else {
                setBets([]);
                setTotalPages(0);
                setCurrentPage(1);
            }
        } catch (err) {
            console.error("Error fetching bet details:", err);
        }
    }, [matchID, marketID, limit]);
    
    useEffect(() => {
        if (isOpen) {
            fetchBetDetails(currentPage);
        }
    }, [isOpen, currentPage, fetchBetDetails]);
    
    const handlePageChange = async (page = 1) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            await fetchBetDetails(page);
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                <div className="modal-content" style={{ position: 'relative', display: 'flex', flexDirection: 'column', minWidth: 'fit-content' }}>
                    <div className="wrapper-body bgstyle">
                        <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>Total Bets: {bets.length}</span>
                            <button className="ai-close closeBtn-modal crossBtn" onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>
                                <AiOutlineClose />
                            </button>
                        </div>
                    </div>

                    <div className='table-responsive'>
                        <table className="odds-gap-table table-border-1">
                            <thead>
                                <tr className="hedStyle">
                                    <th style={{ textAlign: 'center' }}>S.No</th>
                                    <th style={{ textAlign: 'center' }}>Member</th>
                                    <th style={{ textAlign: 'center' }}>Selection</th>
                                    <th style={{ textAlign: 'center' }}>Odds</th>
                                    <th style={{ textAlign: 'center' }}>Requested Odds</th>
                                    <th style={{ textAlign: 'center' }}>Rate</th>
                                    <th style={{ textAlign: 'center' }}>Stake</th>
                                    <th style={{ textAlign: 'center' }}>Date & Time</th>
                                    <th style={{ textAlign: 'center' }}>Parent</th>
                                    <th style={{ textAlign: 'center' }}>IP Address</th>
                                </tr>
                            </thead>

                            <tbody>
                                {bets.length > 0 ? (
                                    bets.map((bet, index) => (
                                        <tr key={bet.id} className={bet.selection_type + ' custom-row'}>
                                            <td style={{ textAlign: 'center' }}>{(currentPage - 1) * limit + index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.bet_user_id}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.selection}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.odds}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.requested_odds}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.p_l}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.stake}</td>
                                            <td style={{ textAlign: 'center' }}>{moment.unix(bet.bet_timestamp_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.parent_user_id}</td>
                                            <td style={{ textAlign: 'center' }}>{bet.ip}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center", padding: "10px" }}><ErrorBoundary text="Bet Data" /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                    {totalPages > 0 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />}
                </div>
            </div>
        </>
    );
};

export default BetModal;
