import "./SportsMarketDetails.css";
import MarketTab from './MarketTab';
import { useNavigate } from "react-router-dom";
import { getCaller } from '../../services/api';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';
import Pagination from '../../component/pagination/Pagination';

const MyMarket = () => {
    const [sportEvents, setSportEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const limit = 25;

    const formatEventDate = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        }).replace(',', '');
    };

    const fetchSportMarketData = async (page = 1) => {
        try {
            setLoading(true);
            const operatorType = "CLICKBET";
            const response = await getCaller(`api/v1/markets/getEventsForSportsMarket/${operatorType}?page=${page}&limit=${limit}`);
            
            if (response.success) {
                setSportEvents(response?.data?.data);
                const totalPage = response?.data?.last_page || 0;
                const currentPage = response?.data?.current_page || 0;
                setTotalPage(totalPage);
                setCurrentPage(currentPage);
            } else {
                setSportEvents([]);
                setTotalPage(0);
                setCurrentPage(1);
            }
        } catch (error) {
            console.error("Error fetching sport market details:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSportMarketData();
    }, []);

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await fetchSportMarketData(page);
        }
    };

    const handleEventClick = (eventId) => {
        navigate(`/my-market/sports-market-details/${eventId}`);
    };

    return (
        <>
            <MarketTab />
            {loading ? <div className="loader-wrapper"><div className='loader'></div></div> :
                <>
                    <div className="table-scrollable-container">
                        <div className='tableResponsive-wrap borderStyle' style={{whiteSpace: 'nowrap', marginTop: '15px', border: '1px solid black'}}>
                            <table width="100%" className="odds-gap-table-client">
                                <thead>
                                    <tr>
                                        <th>Sport</th>
                                        <th>Event</th>
                                        <th>Date</th>
                                        <th>Bets</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sportEvents.length > 0 ? (
                                        sportEvents.map((event, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'row-even' : 'row-odd'}>
                                            <td data-label="Sport">{event.sport}</td>
                                            <td data-label="Event" style={{ textAlign: 'center' }}>
                                                <button className="link-button-sta" onClick={() => handleEventClick(event.event_id)}>
                                                    {event.event_name}
                                                </button>
                                            </td>
                                            <td data-label="Date">{formatEventDate(event.event_timestamp_date)}</td>
                                            <td data-label="Bets">{event.bets_count}</td>
                                        </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4"><ErrorBoundary text='Market Data' /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
                </>
            }
        </>
    );
};

export default MyMarket;