import BetModal from "./BetModal";
import "./SportsMarketDetails.css";
import BookModal from "./BookModal";
import FancyBets from "./FancyBets";
import MatchBets from "./MatchBets";
import PrimiumBets from "./PrimiumBets";
import { useParams } from "react-router-dom";
import FancyInfoModal from "./FancyInfoModal";
import { getCaller } from "../../services/api";
import React, { useEffect, useState, useMemo } from "react";
import SocketComponent from '../../socket/socketBroadcast';

const SportsMarketDetails = () => {
    const { eventId } = useParams();
    const [marketDetails, setMarketDetails] = useState(null);
    const [sortedCategorizedData, setSortedCategorizedData] = useState({});
    const [isBetModalOpen, setIsBetModalOpen] = useState(false);
    const [selectedMarketID, setSelectedMarketID] = useState(null);
    const [isBookModalOpen, setIsBookModalOpen] = useState(false);
    const [isFancyInfoModalOpen, setIsFancyInfoModalOpen] = useState(false);
    const [selectedFancyMarketID, setSelectedFancyMarketID] = useState(null);
    const [selectedFancyID, setSelectedFancyID] = useState(null);
    const [isDataFetched, setIsDataFetched] = useState(false);

    useEffect(() => {
        const fetchMarketDetails = async () => {
            try {
                const response = await getCaller(`api/v1/events/matchDetails/${eventId}`);
                setMarketDetails(response.data);
                setIsDataFetched(true);
            } catch (err) {
                console.error("Error fetching market details:", err);
            }
        };

        if (eventId) {
            fetchMarketDetails();

            const intervalId = setInterval(fetchMarketDetails, 30000);
            return () => clearInterval(intervalId);
        }
    }, [eventId]);

    const convertToK = (value) => {
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + "k";
        }
        return value ? value.toString() : "-";
    };

    const handleBetButtonClick = (marketID) => {
        setSelectedMarketID(marketID);
        setIsBetModalOpen(true);
    };

    const handleBookButtonClick = (marketID) => {
        setSelectedMarketID(marketID);
        setIsBookModalOpen(true);
    };

    const handleFancyInfoClick = (fancyMarketId, Id) => {
        setSelectedFancyMarketID(fancyMarketId);
        setSelectedFancyID(Id);
        setIsFancyInfoModalOpen(true);
    };

    const renderMarketOdds = (marketName, filterCondition) => {
        const markets = marketDetails?.match?.matchOddData ? marketDetails.match.matchOddData.filter(mod => mod.marketName === marketName) : [];

        if (markets.length === 0) return null;

        return (
            <div className="book-maker-market-mkt">
                <div className="boder-odds">
                    <div className="market-odds-backs">
                        <span className="headingStyle">{marketName}</span>
                        <div className="button-group">
                            <button className="book-button" onClick={() => handleBookButtonClick(markets[0].mid)}>Book</button>
                            <button className="bet-button" onClick={() => handleBetButtonClick(markets[0].mid)}>Bet</button>
                        </div>
                    </div>

                    {markets.map((market) => market?.runnerName?.map((m, i) => filterCondition(m.RN) ? (
                        <div key={i} className="odds-container" style={{ marginBottom: '3px' }}>
                            <div className="box-odd-name" data-label="#" style={{ filter: market.runners.some(r => r.sid === m.SID && r.s === 'SUSPEND') ? 'blur(2px)' : 'none' }}>
                                <span>{m.RN}</span>
                            </div>

                            {market.runners.some(r => r.sid === m.SID && r.s === 'SUSPEND') ? (
                                <div className="suspended-box">
                                    <button className="suspended-button">SUSPENDED</button>
                                </div>
                            ) : null}

                            {market?.runners.map((runner, runnerIndex) => m.SID === runner.sid ? (
                                <div key={runnerIndex} className="odds-container-1">
                                    <div className="back-box-container-one" data-label="#">
                                        <div className="box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                            <p>
                                                {runner.s === 'SUSPEND' || !runner?.ex?.b?.length ? " " : runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).p}
                                            </p>

                                            <p style={{ fontSize: "10px" }}>
                                                {runner.s === 'SUSPEND' || !runner?.ex?.b?.length ? " " : convertToK(runner.ex.b.reduce((maxObj, obj) => (obj.p > maxObj.p ? obj : maxObj), runner.ex.b[0]).s)}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="back-box-container-one">
                                        <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                            <p>
                                                {runner.s === 'SUSPEND' || !runner?.ex?.l?.length ? " " : runner.ex.l.reduce((minObj, obj) => (obj.p < minObj.p ? obj : minObj), runner.ex.l[0]).p}
                                            </p>

                                            <p style={{ fontSize: "10px" }}>
                                                {runner.s === 'SUSPEND' || !runner?.ex?.l?.length ? " " : convertToK(runner.ex.l.reduce((minObj, obj) => (obj.p < minObj.p ? obj : minObj), runner.ex.l[0]).s)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : null)}
                        </div>
                    ) : null))}
                </div>
            </div>
        );
    };

    const renderBookmakerOdds = () => {
        const bookmakerData = marketDetails?.match?.bookmakerOddData?.ml || [];

        if (bookmakerData.length === 0) return null;

        return bookmakerData.map((market, i) => {
            return (
                <div key={i} className="book-maker-market-mkt">
                    <div className="boder-odds">
                        <div className="market-odds-backs">
                            <span className="headingStyle">{market.mn}</span>
                            <div className="button-group">
                                <button className="book-button" onClick={() => handleBookButtonClick(market.mi)}>Book</button>
                                <button className="bet-button" onClick={() => handleBetButtonClick(market.mi)}>Bet</button>
                            </div>
                        </div>

                        {market.sl.map((selection, index) => (
                            <div key={index} className="odds-container">
                                <div className="box-odd-name" data-label="#" style={{ filter: selection.ss === "SUSPEND" ? 'blur(3px)' : 'none' }}>
                                    <span>{selection.sln}</span>
                                </div>

                                {selection.ss === "SUSPEND" ? (
                                    <div className="suspended-box">
                                        <button className="suspended-button">SUSPENDED</button>
                                    </div>
                                ) : null}

                                <div className="odds-container-1">
                                    <div className="back-box-container-one" data-label="#">
                                        <div className="box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                            <p>{selection.b === 0 ? " " : selection.b}</p>
                                            <p style={{ fontSize: "10px" }}>{selection.b === 0 ? " " : convertToK(market.ms)}</p>
                                        </div>
                                    </div>

                                    <div className="back-box-container-one">
                                        <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                            <p>{selection.l === 0 ? " " : selection.l}</p>
                                            <p style={{ fontSize: "10px" }}>{selection.l === 0 ? " " : convertToK(market.ms)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });
    };

    const arrayList = useMemo(() => ["line_markets", "session_markets", "over_by_over_session_markets", "ball_by_ball", "total_advance", "fall_of_wicket", "meter_markets", "khado_markets", "odd_even_markets", "other_markets", "exchange_multi_runner_markets", "under_over_multi_runner_markets"], []);

    useEffect(() => {
        if (!marketDetails?.match?.fancyOddData?.ml) return;

        const categorizedData = marketDetails.match.fancyOddData.ml.reduce((acc, item) => {
            const category = item.cat;

            if (!acc[category]) {
                acc[category] = [];
            }

            acc[category].push(item);
            return acc;
        }, {});

        const sortedData = Object.fromEntries(arrayList.filter(category => categorizedData?.[category])
            .map(category => [category, categorizedData[category]])
        );

        setSortedCategorizedData(sortedData);
    }, [marketDetails, arrayList]);

    const renderFancyOdds = ({ sortedCategorizedData }) => {
        const hasMarkets = Object.values(sortedCategorizedData).some(markets => markets.length > 0);

        if (!hasMarkets) return null;

        return (
            <div className="fancy-odds-container">
                <h3>Fancy Market</h3>
                {Object.entries(sortedCategorizedData).map(([category, markets]) => (
                    <div key={category} className="category">
                        <h3 className="category-title">
                            {category.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
                        </h3>
                        <div className="market-list sportJsx-container">
                            {markets.map((market) => (
                                <div key={market.id} className="market-item sportJsx-wrap">
                                    {["under_over_multi_runner_markets", "exchange_multi_runner_markets"].includes(category) ? (
                                        <div className="sportJsx-wrap-new">
                                            <div className="multiRunner-container">
                                                {market.mn}
                                            </div>

                                            <div className="under-over">
                                                {category === "under_over_multi_runner_markets" && (
                                                    <>
                                                        <span className="underBox">Under</span>
                                                        <span className="overBox">Over</span>
                                                    </>
                                                )}
                                            </div>

                                            {market.od?.map((odItem, index) => (
                                                <div key={index} className="sportJsx-wrap-new-full odds-container-1">
                                                    <span>{odItem.sln}</span>

                                                    {(market.sn === "SUSPEND" || market.sn === "BALL_RUN") && (
                                                        <div className="sportJsx-sup-centered">
                                                            <button className="suspended-button-new">
                                                                {market.sn === "SUSPEND" ? "SUSPENDED" : "BALL RUNNING"}
                                                            </button>
                                                        </div>
                                                    )}

                                                    <div className="back-box-container-one">
                                                        {category === "exchange_multi_runner_markets" && (
                                                            <>
                                                                <div className={market.is_sportsbook === 1 ? "box centered-box" : "box"} style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                                    <p>{odItem.ry === "0" ? " " : odItem.ry}</p>
                                                                    {market.is_sportsbook === 0 && <p style={{ fontSize: "10px" }}> {odItem.oy === "0" ? " " : convertToK(odItem.oy)} </p>}
                                                                </div>

                                                                <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                                                    <p>{odItem.rn === "0" ? " " : odItem.rn}</p>
                                                                    {market.is_sportsbook === 0 && <p style={{ fontSize: "10px" }}> {odItem.on === "0" ? " " : convertToK(odItem.on)} </p>}
                                                                </div>
                                                            </>
                                                        )}

                                                        {category === "under_over_multi_runner_markets" && (
                                                            <>
                                                                <div className="box centered-box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                                    <p>{odItem.un === "0" ? " " : odItem.un}</p>
                                                                </div>

                                                                <div className="box centered-box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                                    <p>{odItem.ov === "0" ? " " : odItem.ov}</p>
                                                                </div>
                                                            </>
                                                        )}

                                                        {(odItem.ss === "SUSPEND") && (
                                                            <div className="sportJsx-sup overlap">
                                                                <button className="suspended-button-new">
                                                                    {odItem.ss === "SUSPEND" ? "SUSPENDED" : ""}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <>
                                            <span>{market.mn}</span>
                                            <button className="icon-button"><i className="fas fa-info" onClick={() => handleFancyInfoClick(market.mi, market.mty)}></i></button>

                                            <div className="back-box-container-one">
                                                <div className="box" style={{ backgroundColor: "rgb(136, 205, 244)" }}>
                                                    <p>{market.ry === 0 ? " " : market.ry}</p>
                                                    <p style={{ fontSize: "10px" }}>{market.oy === 0 ? " " : convertToK(market.oy)}</p>
                                                </div>

                                                <div className="box" style={{ backgroundColor: "rgb(215, 120, 125)" }}>
                                                    <p>{market.rn === 0 ? " " : market.rn}</p>
                                                    <p style={{ fontSize: "10px" }}>{market.on === 0 ? " " : convertToK(market.on)}</p>
                                                </div>

                                                {(market.sn === "SUSPEND" || market.sn === "BALL_RUN") && (
                                                    <div className="sportJsx-sup">
                                                        <button className="suspended-button-new">
                                                            {market.sn === "SUSPEND" ? "SUSPENDED" : "BALL RUNNING"}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const handleSocketDataUpdate = async (broadcastData, type) => {
        if (marketDetails?.match) {
            if (type === 1) {
                let updatedMatchOddData = marketDetails?.match?.matchOddData;

                if (broadcastData && broadcastData.length) {
                    for (let market of broadcastData) {
                        let marketData = updatedMatchOddData.find(m => m.mid === market.mid);

                        if (!marketData || (market && !market.runners.length)) continue;

                        if (market.st) marketData.st = market.st;

                        for (let runner of market.runners) {
                            let marketRunners = marketData.runners.find(r => r.sid === runner.sid);

                            if (!marketRunners || (runner && !runner.ex && !runner.s)) continue;

                            if (runner.s) marketRunners.s = runner.s;

                            if (runner.ex.b) marketRunners.ex.b = runner.ex.b;

                            if (runner.ex.l) marketRunners.ex.l = runner.ex.l;

                            marketData.runners = marketData.runners.map(item => item.sid === marketData.sid ? marketRunners : item);
                        }
                        updatedMatchOddData = updatedMatchOddData.map(item => item.mid === marketData.mid ? marketData : item);
                    }
                };

                setMarketDetails((prevState) => ({
                    ...prevState,
                    match: {
                        ...prevState.match,
                        matchOddData: updatedMatchOddData,
                    },
                }));
            } else if (type === 2) {
                const eventData = broadcastData.find(b => b.eid == eventId);

                const updatedList = eventData?.ml?.updated || [];
                const addedList = eventData?.ml?.added || [];
                const deletedList = eventData?.ml?.deleted || [];

                let updatedBookmakerOddData = marketDetails?.match?.bookmakerOddData;

                if (updatedList.length) {
                    updatedBookmakerOddData = {
                        ...updatedBookmakerOddData,
                        ml: updatedBookmakerOddData?.ml?.map((item) => {
                            const matchingUpdate = updatedList.find((update) => update.mi === item.mi);
                            return matchingUpdate ? { ...item, ...matchingUpdate } : item;
                        }),
                    };
                }

                if (deletedList.length) {
                    updatedBookmakerOddData.ml = updatedBookmakerOddData?.ml?.filter(b => !deletedList.includes(b.mi));
                }

                if (addedList.length) {
                    for (let i = 0; i < addedList.length; i++) {
                        const checkExistingBookmaker = updatedBookmakerOddData?.ml?.find(b => b.mi === addedList[i].mi);
                        if (!checkExistingBookmaker) {
                            updatedBookmakerOddData.ml = [...updatedBookmakerOddData.ml, addedList[i]]
                        }
                    }
                }

                setMarketDetails((prevState) => ({
                    ...prevState,
                    match: {
                        ...prevState.match,
                        bookmakerOddData: updatedBookmakerOddData,
                    },
                }));
            } else if (type === 3) {
                const eventData = broadcastData.find(b => b.eid == eventId);
                
                const updatedList = eventData?.ml?.updated || [];
                const addedList = eventData?.ml?.added || [];
                const deletedList = eventData?.ml?.deleted || [];

                let updatedFancyOddData = marketDetails?.match?.fancyOddData;

                if (updatedList.length) {
                    updatedFancyOddData = {
                        ...updatedFancyOddData,
                        ml: updatedFancyOddData?.ml?.map((item) => {
                            const matchingUpdate = updatedList.find((update) => update.mi === item.mi);
                            return matchingUpdate ? { ...item, ...matchingUpdate } : item;
                        }),
                    };
                };

                if (deletedList.length) {
                    updatedFancyOddData.ml = updatedFancyOddData?.ml?.filter(b => !deletedList.includes(b.mi));
                };

                if (addedList.length) {
                    for (let i = 0; i < addedList.length; i++) {
                        const checkExistingFancy = updatedFancyOddData?.ml?.find(b => b.mi === addedList[i].mi);
                        if (!checkExistingFancy) {
                            updatedFancyOddData.ml = [...updatedFancyOddData.ml, addedList[i]]
                        }
                    }
                };

                setMarketDetails((prevState) => ({
                    ...prevState,
                    match: {
                        ...prevState.match,
                        fancyOddData: updatedFancyOddData,
                    },
                }));
            }
        }
    };

    return (
        <>
            {isDataFetched && <SocketComponent eventID={eventId} onDataUpdate={handleSocketDataUpdate} />}

            <div className="wrapper-head-st">
                <h2>Sports Market Details</h2>
            </div>

            <div className="flexContainer-wrap">
                <div className="flexContainer-left">
                    {renderMarketOdds("Match Odds", (RN) => !["No", "Yes"].includes(RN))}
                    {renderMarketOdds("Who Will Win The Match?", () => true)}
                    {renderBookmakerOdds()}
                    {renderFancyOdds({ sortedCategorizedData })}
                    {renderMarketOdds("Completed Match", () => true)}
                    {renderMarketOdds("Tied Match", () => true)}

                    {isBetModalOpen && (
                        <BetModal
                            isOpen={isBetModalOpen}
                            onClose={() => setIsBetModalOpen(false)}
                            matchID={marketDetails.match.matchId}
                            marketID={selectedMarketID}
                        />
                    )}

                    {isBookModalOpen && (
                        <BookModal
                            isOpen={isBookModalOpen}
                            onClose={() => setIsBookModalOpen(false)}
                            matchID={marketDetails.match.matchId}
                            marketID={selectedMarketID}
                            runners={marketDetails.match.selection_runners}
                        />
                    )}

                    {isFancyInfoModalOpen && (
                        <FancyInfoModal
                            isOpen={isFancyInfoModalOpen}
                            onClose={() => setIsFancyInfoModalOpen(false)}
                            fancyMarketId={selectedFancyMarketID}
                            ID={selectedFancyID}
                            matchID={marketDetails.match.matchId}
                        />
                    )}
                </div>

                {marketDetails && (
                    <div className="flexContainer-right">
                        <MatchBets matchID={marketDetails?.match?.matchId} />
                        <FancyBets matchID={marketDetails?.match?.matchId} />
                        <PrimiumBets matchID={marketDetails?.match?.matchId} />
                    </div>
                )}
            </div>
        </>
    );
};

export default SportsMarketDetails;