import moment from 'moment';
import LogTab from './LogTab';
import '../addEvent/addevent.css';
import DatePicker from "react-datepicker";
import { postCaller } from '../../services/api';
import React, { useState, useEffect } from 'react';
import Button from '../../component/button/Button';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast } from '../../utilities/toastHelper';
import { CustomInput } from '../../utilities/customInput';
import Pagination from '../../component/pagination/Pagination';

const MatchLogs = () => {
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [matchLogs, setMatchLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 100;

    const handleDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("YYYY-MM-DD");
    };

    useEffect(() => {
        getMatchLogs(); /* eslint-disable-next-line */
    }, []);

    const getMatchLogs = async (page = 1) => {
        if (page > 1 && fetchedPages[page]) {
            setMatchLogs(fetchedPages[page]);
            return;
        }

        const data = {
            from: handleDate(startDate),
            to: handleDate(endDate),
            type: "add_match"
        };

        const response = await postCaller(`api/v1/user-logs?page=${page}&limit=${limit}`, data);
        if (response.success) {
            setMatchLogs(response.data);
            setTotalPage(response?.pagination?.totalPages);
            setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
        } else {
            showToast(response.message, true);
        }
    };
    
    const handleDatesChange = async (dates) => {        
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await getMatchLogs(page);
        }
    };

    return (
        <>
            <div className="wrapper">
                <LogTab />
                <div className="result-sport-container">
                    <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px' }}>
                        <div className="select-container containerStyle" style={{ marginLeft: '8px', maxWidth: '240px', marginTop: '-3px' }}>
                            <label htmlFor="date" id="date-label">Select Date</label> <br />
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatesChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                customInput={<CustomInput />}
                            />
                        </div>

                        <div className="wrapper-content">
                            <div className="custom-btn" style={{ marginTop: '20px' }}>
                                <Button className='submit' type='button' name='Search' onClick={async() => await getMatchLogs(1)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-border" style={{ padding: '0px' }}>
                    <div className="wrapper-body" >
                        <div className="wrapper-content" style={{ width: "100%" }}>
                            <h3>List of Match Logs</h3>
                        </div>
                    </div>

                    {matchLogs.length === 0 ? (
                        <ErrorBoundary />
                    ) : (
                        <div className='tableResponsive-wrap' >
                            <table width="100%" className="odds-gap-table-client">
                                <thead>
                                    <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                        <th>Added By</th>
                                        <th>League Name</th>
                                        <th>Event Name</th>
                                        <th>Event Date</th>
                                        <th>IP Address</th>
                                        <th>Log Time</th>
                                    </tr>
                                </thead>
                                <tbody style={{whiteSpace: 'nowrap'}}>
                                    {matchLogs.map((log, index) => (
                                        <tr key={log.id}>
                                            <td>{log.manager_id}</td>
                                            <td>{log.league_name}</td>
                                            <td>{log.event_name}</td>
                                            <td>{moment.utc(log.event_date).local().format('DD/MM/YYYY HH:mm:ss')}</td>
                                            <td>{log.ip_address}</td>
                                            <td>{moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
            </div>
        </>
    );
};

export default MatchLogs;