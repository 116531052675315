import { getCaller } from "../../services/api";
import { AiOutlineClose } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import ErrorBoundary from '../../error/ErrorBoundary';

const FancyBets = ({ matchID }) => {
    const [fancyBetsModalOpen, setFancyBetsModalOpen] = useState(false);
    const [fancyBets, setFancyBets] = useState([]);
    
    useEffect(() => {
        const fetchFancyBetsDetails = async () => {
            if (!matchID) return;

            const type = 1;
            const operator = "CLICKBET";

            try {
                // const response = await getCaller(`api/v1/markets/getFancyBetsForSportsMarket/${matchID}/${type}/${operator}`);
    
                // if (response.success) {
                //     setFancyBets(response.data.bets || []);
                // } else {
                    setFancyBets([]);
                // }
            } catch (error) {
                console.error("Error fetching match bets:", error);
            }
        };
        fetchFancyBetsDetails();
    }, [matchID]);

    return (
        <>
            <div className="fancyBet-wrap" style={{ display: "flex", flexDirection: "column", minWidth: "fit-content" }}>
                <div className="wrapper-body bgstyle">
                    <div className="modal-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>Fancy Bets : {fancyBets.length}</span>
                        <button className="book-button eyeStyle" onClick={() => setFancyBetsModalOpen(true)}>
                            <i className="fas fa-eye"></i>
                        </button>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="odds-gap-table table-border-1">
                        <thead style={{ fontSize: "12px", lineHeight: "1", height: "30px" }}>
                            <tr className="hedStyle">
                                <th style={{ textAlign: "center" }}>USER</th>
                                <th style={{ textAlign: "center" }}>SELECTION</th>
                                <th style={{ textAlign: "center" }}>ODDS</th>
                                <th style={{ textAlign: "center" }}>STAKE</th>
                                <th style={{ textAlign: "center" }}>DATE</th>
                            </tr>
                        </thead>

                        <tbody>
                            {fancyBets.length > 0 ? (
                                fancyBets.map((bet, index) => (
                                    <tr key={index} style={{ fontSize: "12px", lineHeight: "1", height: "30px" }}>
                                        <td style={{ textAlign: "center" }}>{bet.user}</td>
                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{bet.selection}</td>
                                        <td style={{ textAlign: "center" }}>{bet.odds}</td>
                                        <td style={{ textAlign: "center" }}>{bet.stake}</td>
                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{bet.date}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5"><ErrorBoundary text='Fancy Bets' /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {fancyBetsModalOpen && (
                <div className="modal-overlay" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
                    <div className="modal-content" style={{ position: "relative", display: "flex", flexDirection: "column", minWidth: "fit-content", background: "#fff", padding: "20px", borderRadius: "8px" }}>
                        <div className="wrapper-body bgstyle">
                            <div className="modal-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span>Total Fancy Bets :</span>
                                <button className="ai-close closeBtn-modal crossBtn" onClick={() => setFancyBetsModalOpen(false)} style={{ background: "none", border: "none", cursor: "pointer", fontSize: "1.5rem" }}>
                                    <AiOutlineClose />
                                </button>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="odds-gap-table table-border-1">
                                <thead>
                                    <tr className="hedStyle">
                                        <th style={{ textAlign: "center" }}>S.No</th>
                                        <th style={{ textAlign: "center" }}>Member</th>
                                        <th style={{ textAlign: "center" }}>Selection</th>
                                        <th style={{ textAlign: "center" }}>Odds</th>
                                        <th style={{ textAlign: "center" }}>Requested Odds</th>
                                        <th style={{ textAlign: "center" }}>Rate</th>
                                        <th style={{ textAlign: "center" }}>Stake</th>
                                        <th style={{ textAlign: "center" }}>Date & Time</th>
                                        <th style={{ textAlign: "center" }}>Parent</th>
                                        <th style={{ textAlign: "center" }}>IP Address</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {fancyBets.length > 0 && (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: "center", padding: "10px" }}><ErrorBoundary text="Fancy Bets" /></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FancyBets;
