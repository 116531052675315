import '../downline.css';
import '../admin-style.css';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import CONSTANTS from '../../../utilities/constants';
import Button from '../../../component/button/Button';
import ErrorBoundary from '../../../error/ErrorBoundary';
import React, { useEffect, useState, useRef } from 'react';
import SocketComponent from '../../../socket/socketBroadcast';
import timeConversion from '../../../utilities/timeConversion';
import { handleKeyDown } from '../../../utilities/handleKeyDown';
import { selectStyles, callInInterval } from '../../../services/utils';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { updateCaller, getCaller, postCaller } from "../../../services/api";
import { callInSequence } from '../../../services/utils';
import { showToast, showSuccessMessage, showConfirmationDialog } from '../../../utilities/toastHelper';
import useSingleTab from '../../../services/preventMultipleTab'; 

const AgentGetFancy = ({ marketAssignData, setMarketAssignData, setUpdateTime, isSus, isChanged }) => {
  useSingleTab();
  const [voidBets, setVoidBets] = useState([]);
  const [result, setResult] = useState("");
  const [runnerType, setRunnerType] = useState("");
  const [fancy, setFancy] = useState("");
  const [localMarketData, setLocalMarketData] = useState(marketAssignData);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSelection, setSearchSelection] = useState('');
  const [minStake, setMinStake] = useState('');
  const [minOdds, setMinOdds] = useState('');
  const [maxStake, setMaxStake] = useState('');
  const [maxOdds, setMaxOdds] = useState('');
  const [selectionType, setSelectionType] = useState('');
  const intervalRef = useRef(null);
  const [isLive, setIsLive] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [totalBets, setTotalBet] = useState(false);
  const [operator, setOperator] = useState('');
  const [fetchOddGapList, setFetchOddGapList] = useState([]);
  const [selectedOperatorType, setSelectedOperatorType] = useState(null);
  const isFirstRender = useRef(true);
  const operatorList = CONSTANTS.OPERATOR_LIST;

  useEffect(() => {
    if (marketAssignData?.market) {
      const markets = [];
      for (const market of marketAssignData.market) {
        if (typeof market.odds !== 'object') {
          market.odds = JSON.parse(market.odds);
        }
        markets.push({ ...market });
      }

      setLocalMarketData({ ...marketAssignData, market: markets });
    }
  }, []);

  useEffect(() => {
    const getAgentDetails = async () => {
      try {
        const agentData = await getCaller(`api/v1/users/${localMarketData.id}`);
      
        if (agentData?.data?.odd_gap_ids) {
          let odd_gap_ids = typeof agentData?.data?.odd_gap_ids === "string" ? JSON.parse(agentData.data.odd_gap_ids) : agentData.data.odd_gap_ids;
          const response = await getCaller(`api/v1/settings/oddGapSettingByIds?odd_gap_ids=${JSON.stringify(odd_gap_ids)}`);
          setFetchOddGapList(response.data);
        }
      } catch (error) {
      }
    };

    getAgentDetails();
  }, []);

  useEffect(() => {
    setOperator(operatorList);
    (localMarketData.is_suspend_all != 1 || (localMarketData.market.filter(m => m.is_sus).length != localMarketData.market.length)) && handleSuspendAll('is_suspend_all', 1);
  }, []);

  useEffect(() => {
    if(isFirstRender.current){
      isFirstRender.current = false;
      return;
    }

    const isSuspended = isSus ? 1 : 0;
    const marketSuspendedCount = localMarketData.market.filter(m => m.is_sus).length;
    const isMarketFullySuspended = marketSuspendedCount === localMarketData.market.length;
    
    if (localMarketData.is_suspend_all !== isSuspended ||
      (isSuspended && !isMarketFullySuspended) ||
      (!isSuspended && marketSuspendedCount !== 0)
    ) {
      handleSuspendAll('is_suspend_all', isSuspended);
    }    
  }, [isChanged]);

  useEffect(() => {
    fetchVoidBets();

    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchVoidBets, 2000);
    }

    return () => clearInterval(intervalRef.current);
  }, [marketAssignData.match_id]);

  const fetchVoidBets = async (isClear = false) => {
    try {
      if (marketAssignData?.match_id && marketAssignData?.market[0]?.external_id && marketAssignData.show_bet === 1) {
        let endpoint = `api/v1/void-bets/3,2,1/4/${marketAssignData.match_id}/fancy/${marketAssignData?.market[0]?.external_id}?isAgent=${true}`;

        if(!isClear){          
          endpoint += `&user=${searchQuery}&operator=${selectedOperatorType?.value || ''}&selectionType=${selectionType === 'all' ? '' : selectionType}&selection=${searchSelection}&stakeMin=${minStake}&stakeMax=${maxStake}&oddsMin=${minOdds}&oddsMax=${maxOdds}`;
        }

        const response = await getCaller(endpoint);
        setTotalBet(response?.pagination?.total || 0);
        setVoidBets(response.data);
      }
    } catch (error) {
    }
  };

  const handleFancyResult = async (event) => {
    setResult('');
    setRunnerType(event.value);
    setFancy(event.value.market_name);

    if (!operator.length) {
      setOperator(operatorList);
    }
  }

  const calculateYesOdds = (noOdds, oddsData) => {
    const found = oddsData.find(item => item.odds_no === noOdds);
    return found ? found.odds_yes : null;
  };

  const toggleVisibility = () => {
    setIsVisible(prevIsVisible => !prevIsVisible);
  };

  const handleResult = (event) => {
    setResult(event); 
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const resultData = JSON.parse(runnerType);
    const data = {
      sport_id: parseInt(marketAssignData?.sport_id),
      match_id: parseInt(marketAssignData?.match_id),
      fancy_id: resultData?.id,
      result: result,
      market_name: resultData?.market_name,
      selection: "",
      operators_list: operator.map(op => op.value)
    };

    await showConfirmationDialog(`You want to Submit this Result (${resultData.market_name})`)
      .then(async (result) => {
        if (result.isConfirmed) {
          const response = await postCaller(`api/v1/results`, data);
          if (response.success) {
            showSuccessMessage(response.message);
            const updatedMarketAssignData = await getCaller(`api/v1/users/showAssignMarketAgent`);
            setMarketAssignData(updatedMarketAssignData.data);
          } else {
            showToast(response.message, true);
          }

          setFancy("");
          setResult("");
          setOperator("");
        }
      })
  }

  const handleSuspendAll = async (field, status) => {
    const updateResponse = await callInSequence(() => updateCaller(`api/v1/users/updateUser/${localMarketData?.id}`, { [field]: status }));

    if (updateResponse.success) {
      if (marketAssignData?.event_id) {
        const updateFancyResponse = await callInSequence(() => updateCaller(`api/v1/fancies/updateFancy/${marketAssignData.event_id}/${localMarketData?.id}`, { ['is_sus']: status }));
        if (updateFancyResponse.success) {
          const markets = [];

          for (const market of localMarketData.market) {
            market.is_sus = status;
            if (typeof market.odds === 'string') {
              market.odds = JSON.parse(market.odds);
            }
            markets.push({ ...market });
          }

          setLocalMarketData(prevState => ({ ...prevState, market: markets, [field]: status }));
          setMarketAssignData(prevState => ({ ...prevState, market: markets, [field]: status }));
          showToast(status ? 'All Markets are Suspended.' : 'All Markets are Opened.');
        } else {
          showToast("Failed", true);
        }
      }
    }
  };

  const updateMarket = async (id, field) => {
    setUpdateTime();

    const updatedMarket = localMarketData.market.find((market) => market.id === id);

    const payload = field == 'odd_gap' ? { 'odd_gap': updatedMarket['odd_gap'] ?? 0, odds: updatedMarket['odds'] } : { [field]: updatedMarket[field] ?? 0 };
    const response = await callInSequence(() => updateCaller(`api/v1/fancies/${id}/agent`, payload));

    if (response.success) {
      let title = 'Market Successfully Updated.';

      if (field === 'is_sus') {
        title = updatedMarket.is_sus ? "Market is Suspended." : "Market is Opened.";
      } else if (field === 'is_active') {
        title = updatedMarket.is_active ? "Market is Active." : "Market is Inactive.";
      } else if (field === 'msg') {
        title = "Message is Updated.";
      }

      showToast(title);
    } else {
      showToast(response.message, true);
    }
  }

  const handleClearField = () => {
    setMinOdds('');
    setMaxOdds('');
    setIsLive(true);
    setMinStake('');
    setMaxStake('');
    setSearchQuery('');
    setSelectionType('');
    setSearchSelection('');
    setSelectedOperatorType(null);

    clearInterval(intervalRef.current);
    intervalRef.current = null;
    
    fetchVoidBets(true);
    intervalRef.current = setInterval(() => fetchVoidBets(true), 2000);
  }

  const handleSearchBet = async () => {
    setIsLive(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    await fetchVoidBets();
  };

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList];

  const handleOperator = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator([]);
      } else {
        setOperator(operatorList);
      }
    } else {
      setOperator(selectedOptions);
    }
  };

  const getOptions = (selectedOptions) => {
    return selectedOptions?.length === operatorList.length ? operatorList : options;
  };

  const handleSocketDataUpdate = async (broadcastData, type = true) => {
    if (type !== 'fancy') return;

    setMarketAssignData((prevState) => {
      if (!prevState) return prevState;

      let marketData = [...prevState.market];

      if (broadcastData?.is_all_fancy) {
        if (
          broadcastData?.event_id != prevState?.event_id ||
          broadcastData?.user_id != prevState?.id
        ) {
          return prevState;
        }

        marketData = marketData.map((item) => ({
          ...item,
          is_sus: broadcastData?.is_sus ?? 1,
        }));
      } else {
        marketData = marketData.map((item) =>
          broadcastData.fancy_id != item.id
            ? item
            : {
              ...item, ...Object.fromEntries(Object.entries(broadcastData).filter(([key]) =>
                ["odds", "is_sus", "is_active", "msg", "odd_gap"].includes(key)
              ))
            }
        );
      }

      const updatedState = { ...prevState, is_suspend_all: broadcastData?.is_all_fancy ? 1 : prevState.is_suspend_all, market: marketData };

      if (!broadcastData?.is_agent) {
        setLocalMarketData(updatedState);
      }

      return updatedState;
    });
  };

  return (
    <div>
      {marketAssignData?.event_id && <SocketComponent eventID={marketAssignData.event_id} onDataUpdate={handleSocketDataUpdate} />}
      {/* <SocketFancy eventID={marketAssignData.event_id}  /> */}
    <div className="book-maker-market pageMain-container">
      <div className="market-match-container pageMain-wrap">
        <div className="pageMain-left">
            {marketAssignData.show_bet === 1 &&
              <div className="bookMaker-container">
                {isVisible && (
                  <div className="book-maker-match bookMaker-header">
                    <div className="span-book">
                      <div className="result-event-select" id='bookmaker-select' >
                        <p>Name</p>
                        <input type="search" autoComplete="off" className="book-maker-column-input" value={searchQuery ?? ''} onChange={(e) => setSearchQuery(e.target.value)} placeholder="User" />
                      </div>
                      <div className="result-event-select" id='bookmaker-select' style={{ maxWidth: '230px' }}>
                        <p> Operator </p>
                        <Select placeholder="Select Operator" options={operatorList} value={selectedOperatorType} onChange={(e) => setSelectedOperatorType(e)} isClearable={true} />
                      </div>
                      <div className="result-event-select" id='bookmaker-select' >
                        <p>Selection</p>
                        <input type="search" autoComplete="off" className="book-maker-column-input" value={searchSelection ?? ''} onChange={(e) => setSearchSelection(e.target.value)} placeholder="selection" />
                      </div>
                      <div className="result-event-select" id='bookmaker-select' >
                        <p>Selection Type</p>
                        <select value={selectionType ?? ''} onChange={(e) => setSelectionType(e.target.value)} className="book-maker-column-input">
                          <option value="all"> All </option>
                          <option value="back"> Back </option>
                          <option value="lay"> Lay </option>
                        </select>
                        {/* <input type="search" autoComplete="off" id="" className="book-maker-column-input" value={selectionType} onChange={(e) => setSelectionType(e.target.value)} placeholder="selection Type"  /> */}
                      </div>
                      <div className="result-event-select widthFix" id='bookmaker-select' >
                        <p style={{ display: 'flex', justifyContent: "space-around" }}>Stake</p>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <input type="number" inputMode="numeric" autoComplete="off" className="book-maker-column-input" value={minStake ?? ''} onKeyDown={handleKeyDown} onChange={(e) => setMinStake(e.target.value)} placeholder="Min " style={{ width: "70px" }} />
                          <input type="number" inputMode="numeric" autoComplete="off" className="book-maker-column-input" value={maxStake ?? ''} onKeyDown={handleKeyDown} onChange={(e) => setMaxStake(e.target.value)} placeholder="Max " style={{ width: "70px" }} />
                        </div>
                      </div>
                      <div className="result-event-select widthFix" id='bookmaker-select' >
                        <p style={{ display: 'flex', justifyContent: "space-around" }}>Odds</p>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <input type="number" inputMode="numeric" autoComplete="off" id="" className="book-maker-column-input" value={minOdds ?? ''} onKeyDown={handleKeyDown} onChange={(e) => setMinOdds(e.target.value)} placeholder="Min " style={{ width: "70px" }} />
                          <input type="number" inputMode="numeric" autoComplete="off" id="" className="book-maker-column-input" value={maxOdds ?? ''} onKeyDown={handleKeyDown} onChange={(e) => setMaxOdds(e.target.value)} placeholder="Max " style={{ width: "70px" }} />
                        </div>
                      </div>
                      <Button type='submit' className='submit' name='Search Bet' onClick={handleSearchBet} />
                      <Button type='submit' className='submit' name='Clear Bet' onClick={handleClearField} />
                    </div>
                  </div>
                )}

                <div className="bookMaker-body">
                  <div className="bookMaker-table">
                    <table width="100%">
                      <thead id="fancy-head-coloumn">
                        <tr className="">
                          <th align="left"> OPERATOR </th>
                          <th align="left"> PARENT </th>
                          <th align="left"> USER </th>
                          <th align="left"> SELECTION </th>
                          <th align="left"> ODDS </th>
                          <th align="left"> STAKE </th>
                          {/* <th align="left"> P & L </th> */}
                          <th align="left"> DATE & TIME </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          voidBets?.length > 0 ? (
                            voidBets?.map((e, i) => (
                              <tr className={e.selection_type + ' custom-row'} key={i}>
                                <td>{e.operator}</td>
                                <td>{e.parent}</td>
                                <td>{e.child}</td>
                                <td>{e.selection}</td>
                                <td>{e.odds}</td>
                                <td>{e.stake}</td>
                                {/* <td>{e.p_l}</td> */}
                                <td>{timeConversion(e.created_at)}</td>
                              </tr>
                            ))
                          ) : <ErrorBoundary />
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          <div className="bookMaker-container">
            <div className="bookMaker-body">
              <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                <table width="100%">
                  <thead id="fancy-head-coloumn">
                    <tr className="">
                      <th align="left"> MARKET NAME </th>
                      {/* <th> END DATE </th> */}
                          {!["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) && (<th> ODD GAP </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> NO (OUTCOME) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> YES (OUTCOME) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> NO (ODDS) </th>)}
                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> YES (ODDS) </th>)}
                          {!["khado_markets"].includes(localMarketData.market_id) ? null : (<th> SIZE </th>)}
                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> BACK </th>)}
                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (<th> LAY </th>)}
                      <th align="left"> SUSPEND 
                      <ToogleButton
                        defaultChecked={Boolean(marketAssignData.is_suspend_all)} onChange={(value) => handleSuspendAll("is_suspend_all", value ? 1 : 0)}
                      /> 
                      </th>
                      <th align="left"> ACTIVE </th>
                    </tr>
                  </thead>
                  <tbody>
                    {localMarketData?.market?.length > 0 ? (
                      localMarketData?.market?.map((e, i) => (
                        <tr className="fancy-head-row" key={i}>
                          <td align="left" className='input-group'>
                            <input type="text" autoComplete="off" disabled name="selectionName" value={e.market_name ?? ''} className="book-maker-column-input-1" placeholder="Team Name" />
                            <input
                              type="text" autoComplete="off" name="msg" className="book-maker-column-input-1" placeholder="Message"
                              onChange={(event) => {
                                const newE = { ...e, msg: event.target.value };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "msg")}
                              value={e.msg ?? ''}
                            />
                          </td>

                          {!["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) && (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="gap" className="book-maker-column-input" placeholder="Gap" onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = null;
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  } else if (value > 10) {
                                    value = 10;
                                  }
                                }

                                const newOdds = {
                                  ...e,
                                  odd_gap: value,
                                  odds: {
                                    ...e.odds,
                                    runYes: parseFloat(e.odds.runNo || 0) + (value || 0)
                                  }
                                };
                                                                
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newOdds;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odd_gap")}
                              value={e.odd_gap ?? ""}
                            />               
                          </td>
                          )}

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="runNo" className="book-maker-column-input" placeholder="NO (OUTCOME)" onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = {
                                  ...e.odds,
                                  runNo: value,
                                  runYes: e.odd_gap != null && e.odd_gap >= 0 ? parseFloat(value || 0) + parseFloat(e.odd_gap || 0) : e.odds.runYes
                                };
                                
                                const newE = { ...e, odds: { ...newOdds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                              value={e.odds.runNo ?? ''}
                            />
                          </td>)} 

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="runYes" id="" className="book-maker-column-input" placeholder="YES (OUTCOME)" value={e.odds.runYes ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = {
                                  ...e.odds,
                                  runYes: value,
                                  runNo: e.odd_gap != null && e.odd_gap >= 0 ? Math.max(0, parseFloat(value || 0) - parseFloat(e.odd_gap || 0)) : e.odds.runNo
                                };

                                const newE = { ...e, odds: { ...newOdds } };

                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                            />
                          </td>)}

                          {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="oddsNo" className="book-maker-column-input" placeholder="NO (ODDS)" value={e.odds.oddsNo ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = { 
                                  ...e.odds, 
                                  oddsNo : value
                                };

                                const updatedOddsYes = calculateYesOdds(value, fetchOddGapList);
                                if (updatedOddsYes !== null) {
                                  newOdds.oddsYes = updatedOddsYes;
                                }

                                const newE = { ...e, odds: { ...newOdds } };
                                
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}

                            />
                          </td>)}

                        {["khado_markets", "odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="oddsYes" className="book-maker-column-input" placeholder="YES (ODDS)" value={e.odds.oddsYes ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = { 
                                  ...e.odds, 
                                  oddsYes : value
                                };

                                const newE = { ...e, odds: { ...newOdds } };

                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                            />
                          </td>)}

                          {!["khado_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="size" className="book-maker-column-input" placeholder="SIZE" value={e.odds.size ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = { 
                                  ...e.odds, 
                                  size : value
                                };

                                const newE = { ...e, odds: { ...newOdds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                            />
                          </td>)}

                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="back" className="book-maker-column-input" placeholder="BACK" value={e.odds.back ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                const newOdds = { 
                                  ...e.odds, 
                                  back : value
                                };

                                const newE = { ...e, odds: { ...newOdds } };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                            />
                          </td>)}

                          {!["odd_even_markets"].includes(localMarketData.market_id) ? null : (
                          <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="lay" className="book-maker-column-input" placeholder="LAY" value={e.odds.lay ?? ''} onKeyDown={handleKeyDown}
                              onChange={(event) => {
                                const rawValue = event.target.value;
                                let value;
                                if (rawValue === '') {
                                  value = '';
                                } else {
                                  value = parseFloat(rawValue);
                                  if (value < 0) {
                                    value = '';
                                  }
                                }

                                // e.odds.lay = value;

                                const newOdds = { 
                                  ...e.odds, 
                                  lay : value
                                };

                                const newE = { ...e, odds: { ...newOdds } };

                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                              }}
                              onBlur={() => updateMarket(e.id, "odds")}
                            />
                          </td>)}

                          <td align="left">
                            <ToogleButton
                              defaultChecked={ e?.is_sus === 1 }
                              onChange={(event) => {
                                e.is_sus = event ? 1 : 0;
                                const newE = { ...e, is_sus: e.is_sus };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                updateMarket(e.id, "is_sus");
                              }}
                            />
                          </td>
                          <td align="left">
                            <ToogleButton
                              defaultChecked={Boolean(e.is_active)}
                              onChange={(event) => {
                                e.is_active = event ? 1 : 0
                                const newE = { ...e, is_active: e.is_active };
                                setLocalMarketData(oldMarket => {
                                  const market = [...oldMarket.market];
                                  market[i] = newE;
                                  return { ...oldMarket, market }
                                });
                                updateMarket(e.id, "is_active");
                              }}
                            />
                          </td>

                        </tr>
                      ))
                    ) : <ErrorBoundary />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          { marketAssignData.allow_declare_result === 1 &&
          <div className="bookMaker-container">				
            <div className="bookMaker-header">
              <div className="bookMaker-heading">
                <p> FANCY RESULT DECLARE </p>
              </div>
            </div>
            <div className="bookMaker-body">
              <form>
                <div className="book-maker-content">
                  <div className='book-maker-select-container'>
                     <div className='book-maker-select'>
                        <label htmlFor='fancy-dropdown'> Select Fancy </label>
                        <Select
                          id='fancy-dropdown'
                          value={fancy}
                          onChange={(selectedOption) => handleFancyResult(selectedOption)}
                          options={marketAssignData?.market?.length > 0 ? [
                            ...marketAssignData?.market?.map((el) => ({ value: JSON.stringify(el), label: el.market_name }))
                          ] : []}
                          placeholder="Select Fancy"
                          styles={selectStyles}
                        />
                     </div>

                     <div className="book-maker-select" id='bookmaker-select'>
                        <label htmlFor="result-dropdown"> Select Result </label>
                        {
                          (['odd_even_markets']).includes(localMarketData?.market_id) ?
                          <Select
                          id='result-dropdown'
                          value={result ? { value: result, label: result } : null}
                          onChange={(selectedOption) => handleResult(selectedOption.value) }
                          options={[
                            { value: "even", label: "Even" },
                            { value: "odd", label: "Odd" },
                            { value: "abandoned", label: "Abandoned" }
                          ]}
                          placeholder="Select Result"
                          styles={selectStyles}
                        />

                        : <input type="text" autoComplete="off"
                        name="result"
                        value={result ?? ""}
                      onChange={(e) => handleResult(e.target.value)}
                        className='select-field' id="" />}
                       
                    </div>
                    <div className='book-maker-select'>
                      <label> Select Operators </label>
                      <Select
                          onChange={ handleOperator }
                          options={getOptions(operator)}
                          value={ operator } 
                          placeholder="Select Operators"
                          isMulti
                      />
                    </div>
                    <div className="book-maker-btn-container">
                          <Button className='submit' name='Submit Result' disabled={!result.length || !operator.length || !runnerType} type='submit' onClick={onSubmit} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          }
        </div>

        <div className="pageMain-right">
          {marketAssignData.show_bet === 1 &&
              <div className='filter-container'>
                <div className='filter-title-wrap'>
                  <span className='filter-title'>Bets</span>
                  <span className='betsCount'>{totalBets}</span>
                </div>
                <div className='filter-wrap'>
                  <div className="filter-icon" onClick={toggleVisibility}>
                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.94 22.65C10.4537 22.6492 9.97555 22.5254 9.55003 22.29C9.12221 22.0573 8.76529 21.7133 8.51707 21.2943C8.26885 20.8753 8.13857 20.397 8.14003 19.91V14.61C8.08574 14.0169 7.86376 13.4516 7.50003 12.98L3.76003 9.02001C3.10439 8.33721 2.71123 7.44463 2.65003 6.50001V4.20001C2.63964 3.83132 2.70293 3.46426 2.8362 3.12034C2.96947 2.77642 3.17003 2.46256 3.42614 2.19714C3.68225 1.93171 3.98875 1.72007 4.3277 1.57461C4.66664 1.42915 5.0312 1.35279 5.40003 1.35001H18.6C18.9612 1.34974 19.319 1.4207 19.6527 1.5588C19.9865 1.69691 20.2897 1.89946 20.5452 2.15488C20.8006 2.41029 21.0031 2.71355 21.1412 3.04732C21.2793 3.38108 21.3503 3.7388 21.35 4.10001V6.30001C21.2891 7.35753 20.8571 8.35967 20.13 9.13001L15.8 12.96C15.3332 13.4204 15.0642 14.0445 15.05 14.7V19C15.0368 19.4612 14.916 19.913 14.6972 20.3193C14.4784 20.7255 14.1678 21.0751 13.79 21.34L12.41 22.23C11.9689 22.5046 11.4596 22.6501 10.94 22.65ZM5.40003 2.85001C5.22876 2.85486 5.06018 2.89383 4.90417 2.96465C4.74815 3.03546 4.60783 3.13669 4.49142 3.26241C4.37502 3.38813 4.28486 3.53581 4.22624 3.69681C4.16762 3.8578 4.14171 4.02887 4.15003 4.20001V6.50001C4.21548 7.0496 4.45356 7.56428 4.83003 7.97001L8.64003 11.98C9.24265 12.7261 9.59591 13.6425 9.65003 14.6V19.9C9.64844 20.1199 9.70729 20.336 9.82015 20.5246C9.93302 20.7133 10.0956 20.8674 10.29 20.97C10.4917 21.0841 10.7197 21.1432 10.9514 21.1414C11.183 21.1396 11.4101 21.0771 11.61 20.96L13 20.07C13.1621 19.9399 13.2954 19.7776 13.3918 19.5935C13.4882 19.4094 13.5455 19.2073 13.56 19V14.7C13.565 14.1589 13.6801 13.6244 13.8985 13.1292C14.1168 12.6341 14.4338 12.1886 14.83 11.82L19.11 8.03001C19.5424 7.54689 19.8057 6.93608 19.86 6.29001V4.10001C19.86 3.76849 19.7283 3.45054 19.4939 3.21612C19.2595 2.9817 18.9415 2.85001 18.61 2.85001H5.40003Z" fill="currentColor" />
                      <path d="M6.00001 10.746C5.85952 10.7437 5.7219 10.7059 5.60001 10.636C5.43116 10.5292 5.31126 10.36 5.26633 10.1653C5.2214 9.97061 5.25506 9.76605 5.36001 9.59601L10.29 1.69601C10.3968 1.53214 10.5636 1.41665 10.7546 1.37429C10.9456 1.33193 11.1456 1.36607 11.3117 1.4694C11.4778 1.57272 11.5968 1.73701 11.6432 1.92704C11.6896 2.11708 11.6598 2.31774 11.56 2.48601L6.63001 10.386C6.56489 10.4952 6.47268 10.5857 6.36231 10.6488C6.25193 10.7119 6.12713 10.7453 6.00001 10.746V10.746Z" fill="currentColor" />
                    </svg> {`${isVisible ? 'Hide' : 'Show'} Filter`}
                  </div>
                  <div className={`filter-live ${isLive ? 'isLive' : 'isNotLive'}`} >Live Bets<span></span></div>
                  <div className={`filter-clear ${!isLive ? 'filterOn' : 'filterOff'}`} onClick={handleClearField}>Clear Filter</div>
                </div>
              </div>
          }
            
            <div className='market-dataWrap'>
              <div className="market-odds-body">
                <div className="">
                  <p>{marketAssignData?.market_id.split("_").join(" ").toUpperCase()}</p>
                </div>
                {marketAssignData?.market_id === "khado_markets" ? (
                  <div className="market-odds-back">
                    <div style={{ marginRight: ".5rem" }}>
                      <p>SIZE</p>
                    </div>
                  </div>
                ) : (
                  <div className="market-odds-back">
                    <div style={{ marginRight: ".5rem" }}>
                      <p>BACK</p>
                    </div>
                    <div>
                      <p>LAY</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="boder-odd">
                {marketAssignData?.market?.map((e, i) => (
                  <div className="odds-container" key={i}>
                    <div className="box-odd-name" data-label="#">
                      {e.market_name}
                    </div>

                    <div className="odds-container-1">
                      {e.is_sus === 1 ? (<div className="suspend-box"> Suspended </div>) : null}
                      {marketAssignData?.market_id === "khado_markets" ? (
                        <div className="back-box-container-one" data-label="#">
                          <div className="box" style={{ backgroundColor: "#88cdf4",  display: "flex", justifyContent: "center", alignItems: "center",  textAlign: "center" }}>
                            <p>{e?.odds.size}</p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="back-box-container-one" data-label="#">
                            <div className="box" style={ marketAssignData?.market_id === "odd_even_markets" ? { backgroundColor: "#88cdf4", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" } : { backgroundColor: "#88cdf4" }}>
                              {marketAssignData?.market_id === "odd_even_markets" ? (
                                <p>{e?.odds.back}</p>
                              ) : (
                                <>
                                  <p>{e?.odds.runNo}</p>
                                  <p>{e?.odds.oddsNo}</p>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="back-box-container-one">
                            <div className="box" style={{ backgroundColor: "#d7787d" }}>
                              {marketAssignData?.market_id === "odd_even_markets" ? (
                                <p>{e?.odds.lay}</p>
                              ) : (
                                <>
                                  <p>{e?.odds.runYes}</p>
                                  <p>{e?.odds.oddsYes}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            </div>
      </div>
     </div>
    </div>
  )
};

export default AgentGetFancy;