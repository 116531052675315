import moment from 'moment';
import '../addEvent/addevent.css';
import Select from 'react-select';
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";
import React, { useState, useEffect } from 'react';
import Button from '../../component/button/Button';
import { selectStyles } from '../../services/utils';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../error/ErrorBoundary';
import { CustomInput } from '../../utilities/customInput';
import { getCaller, postCaller } from '../../services/api';
import { showToast, showSuccessMessage, showConfirmationDialog } from '../../utilities/toastHelper';

const AddEvent = () => {
    const [sportList, setSportList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [leagueList, setLeagueList] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().add(5, 'days').startOf('day').toDate());
    const [sport, setSport] = useState("");
    const [league, setLeague] = useState("");
    const [eventList, setEventList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOptionLeague, setSelectedOptionLeague] = useState(null);
    const [selectedOptionSport, setSelectedOptionSport] = useState(null);
    const minDate = moment().subtract(7, 'days').startOf('day').toDate();
    
    const handleDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("MM/DD/YYYY");
    };

    useEffect(() => {
        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data.filter((el) => el.is_custom === 0));
        }
        getSport();
    }, []);

    const handleSport = async (event) => {
        setSport(event.value);
        setSelectedOptionLeague(null);
        setLeagueList([]);
        setEventList([]);
        setChecked([]);

        if (event.value === "") {
            return;
        } else {
            await getLeague(event.value);
        }
    };

    const getLeague = async (sportId) => {
        let data = {
            from: handleDate(startDate),
            to: handleDate(endDate),
        };
        
        const leagueResponse = await postCaller(`api/v1/leagues/management/getLeague?sport_id=${sportId}`, data);
        if (leagueResponse.success) {
            setLeagueList(leagueResponse.data);
        }
    };

    const handleLeague = async (event) => {
        setLeague(event.value);
        if (event.value === "") {
            return;
        } else {
            await getEvents(event.value);
        }
    };

    const handleSelectChange = async (option) => {
        setSelectedOptionLeague(option);
        handleLeague(option);
    };

    const getEvents = async (leagueId, sportId) => {
        try {
            setChecked([]);
            let leagueData = {
                from: handleDate(startDate),
                to: handleDate(endDate),
                sport_id: sport,
                league_id: leagueId
            }
            setLoading(true);

            const eventResponse = await postCaller(`api/v1/events/management/getEvent`, leagueData);
            setEventList(eventResponse.data);
            setChecked(eventResponse.data.map(e => parseInt(e.event.id)));
            setLoading(false);

            if (eventResponse.success === true) {
                setLoading(false);
            } else {
                showToast (eventResponse.message , true);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleMatchCheck = (event, value) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
        }
        setChecked(updatedList);
    };

    const handleAddMatch = async (event) => {
        event.preventDefault();

        let body = {
            from: handleDate(startDate),
            to: handleDate(endDate),
            events: checked ?? []
        };
        
        await showConfirmationDialog(`You want to Add these Matches`)
        .then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const res = await postCaller(`api/v1/events/management/addEvents?sport_id=${sport}&league_id=${league}`, body)
                if (res.success) {
                    showSuccessMessage('Your Match Added Successfully')
                    setLeague(""); 
                    setChecked([]);
                    setEventList([])
                    setLoading(false)
                    setSelectedOptionLeague(null);
                    // setSelectedOptionSport(null);
                }
            }
        }).catch(err => console.error(err))
    };

    const handleDatesChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSelectedOptionSport(null);
        setSelectedOptionLeague(null);
        setEventList([]);
        setChecked([]);
    };
  
    return (
        <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
            <div className="wrapper-head">
                <h3> ADD EVENTS </h3>
            </div>
            <div className="result-sport-container">
                <div className="result-sport-content" style={{ 'justifyContent': 'flex-start', gap: '15px' }}>
                    <div className="select-container containerStyle" style={{ maxWidth: '240px' }}>
                        <label htmlFor="date" id="date-label"> Select Date </label> <br />
                        <DatePicker
                            selected={startDate}
                            onChange={handleDatesChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            minDate={minDate}
                            dateFormat="dd/MM/yyyy"
                            dropdownMode="select"
                            customInput={<CustomInput />}
                        />
                    </div>

                    <div className="select-container" style={{ maxWidth: '280px' }}>
                        <label htmlFor="sport"> Select Sport </label> <br />
                        <Select placeholder="Select Sport" value={selectedOptionSport} styles={ selectStyles } options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                            onChange={async (selectedOption) => {
                                setSelectedOptionSport(selectedOption);
                                await handleSport(selectedOption);
                            }}
                        />
                    </div>
                    
                    <div className="select-container" style={{ maxWidth: '280px' }}>
                        <label htmlFor="league"> Select League </label> <br />
                        <Select placeholder="Select League" value={selectedOptionLeague} styles={ selectStyles }  options={leagueList?.map((el) => ({ value: el.id, label: el.name }))} onChange={handleSelectChange}/>
                    </div>
                </div>
            </div>
            {
                loading ? 
                    <div className="loader-wrapper">
                        <div className='loader'></div> 
                    </div>
                : 
                <div className="table-border">
                    {
                        eventList && <div className="wrapper-body">
                            <div className='wrapper-content' >
                                <h2> Manage <span className='event-head'> { } </span> Matches <span className='ten'> {eventList.length > 0 ? eventList.length : 0} </span>
                                <span className='zero'> {checked.length} </span> </h2>
                                {
                                    eventList?.length === 0 ? '' :
                                    <div className="">
                                        <Button type='button' name='Add Matches' className='green' onClick={handleAddMatch} disabled={checked.length === 0}/>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        eventList?.length === 0 && <ErrorBoundary />
                    }
                    <table width="100%" style={{ overflowX: "auto" }}>
                        <tbody>
                            {eventList?.length > 0 &&
                                eventList?.map((el,i) =>
                                    <tr className='custom-row' key={i}>
                                        <td>
                                            <input type="checkbox" checked={checked.includes(parseInt(el.event.id))} value={el.event.id} onChange={(event) => handleMatchCheck(event, el.id)} className='check-container' style={{marginRight: '-29px'}}/>
                                        </td>
                                        <td className='name-container' >{el.event.name}</td>
                                        <td className='date-container' >{moment(el.event.openDate).format("DD/MM/YYYY hh:mm") } </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            }
        </motion.div>
    )
};

export default AddEvent;