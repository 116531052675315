import '../customGames/custom.css';
import { motion } from "framer-motion";
import CustomGamesTab from './CustomGamesTab';
import { useNavigate } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';
import { getCaller, updateCaller } from '../../services/api';
import Pagination from '../../component/pagination/Pagination';
import { showDeleteMessage, showConfirmationDialog, showToast } from "../../utilities/toastHelper";

const CustomMatches = () => {
    const [customMatches, setCustomMatches] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 100;
    const navigate = useNavigate();

    const getCustomMatches = async (page = 1) => {
        if (fetchedPages[page]) {
            setCustomMatches(fetchedPages[page]);
            return;
        }
        const res = await getCaller(`api/v1/events/showMatch?custom=1&page=${page}&limit=${limit}`);
        const filteredData = res.data?.filter((el) => el.is_custom === 1);
        setCustomMatches(filteredData);
        setTotalPage(res?.pagination?.totalPages);
        setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
    };

    const handlePageChange = async(page) => {
        setCurrentPage(page);
        await getCustomMatches(page);
    };

    useEffect(() => {
        getCustomMatches();
    }, []);

    const deleteMatches = async (item) => {
        const result = await showConfirmationDialog(`You want to delete this match (${item.event_name})?`);

        if (result.isConfirmed) {
            const deleteResponse = await updateCaller(`api/v1/events/deleteMatch?id=${item.id}&league_id=${item.league_id}&sport_id=${item.sports_id}`);
            
            if(deleteResponse.success === true){
                showDeleteMessage("Your match deleted successfully!");
                setTimeout(() => {
                    setCustomMatches((prev) => prev.filter((el) => el.id !== item.id));
                }, 0);
            } else {
                showToast(deleteResponse.message, true);
            }
        }
    };

    return (
        <>
            <CustomGamesTab/>
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className='table-border'>
                    <div className="wrapper-body">
                        <div className="wrapper-content">
                            <h3> Custom Match </h3>
                            <div className="custom-btn">
                                <Button className={'submit'} name={'ADD MATCH'} type={'type'} onClick={()=> navigate('/custom_add_match')}/> 
                            </div>
                        </div>
                    </div>

                    <table width="100%">
                        <thead>
                            <tr className='custom-row'>
                                <th> # </th>
                                <th> SPORT </th>
                                <th> LEAGUE </th>
                                <th> MATCH </th>
                                <th> ACTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customMatches?.length > 0 ? customMatches?.map((item, i) => (
                                    <tr className='custom-row' key={i}>
                                        <td> {item.id} </td>
                                        <td style={{textTransform:"capitalize"}}> {item.name} </td>
                                        <td> {item?.league_name !== "undefined" ? item.league_name : "N/A"} </td>
                                        <td> {item.event_name ? item.event_name : "N/A"} </td>
                                        <td>
                                            <div className='user-btn-action'>
                                                <div className='edit-btn' onClick={() => navigate('/custom_add_match', {state: { matchData: item }})}>
                                                    <MdEdit style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </div>

                                                <div className='edit-btn delete-btn' onClick={() => deleteMatches(item)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )) : <ErrorBoundary />
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange}/>
            </motion.div>
        </>
    )
};

export default CustomMatches;