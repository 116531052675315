import React, { useEffect, useRef } from 'react'
import Select from 'react-select'
import ResultTab from './ResultTab'
import FancyResultTable from './FancyResultTable'
import { useState } from 'react'
import { getCaller, postCaller } from '../../services/api'
import './result.css'
import Button from '../../component/button/Button'
import { motion } from "framer-motion";
import { useFormik } from 'formik'
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Result from './result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const FancyResult = () => {
  const htmlElRef = useRef(null)
  const [sportList, setSportList] = useState([]);
  const [resultData, setResultData] = useState([])
  const [result, setResult] = useState("");
  const [sport, setSport] = useState("")
  const [eventId, setEventId] = useState("")
  const [eventList, setEventList] = useState([])
  const [fancyResultTypeList, setFancyResultTypeList] = useState([])
  const [fancyResult, setFancyResult] = useState(null)
  const [fancyTypeList, setFancyTypeList] = useState([])
  const [oneMarketList, setOneMarketList] = useState([])
  const [fancyId, setFancyId] = useState("")
  const [isOpen, setOpen] = useState(false);
  const resultRef = useRef();
  const eventRef = useRef();
  const marketRef = useRef();
  const sportRef = useRef();
  const [callRef, setCallRef] = useState(false);
  const [matchID, setMatchID] = useState('');
  const [pagination, setPagination] = useState([])
  const [fetchedPages, setFetchedPages] = useState({});
  const [operatorList, setOperatorList] = useState([]);
  const [operatorId, setOperatorId] = useState([]);
  const [scope,setScope] = useState({})
  const  limit = 100;

  useEffect(() => {
    const {result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({result,userType})
    async function getSport() {
      let sportResponse = await getCaller('api/v1/sports/management/getSport');
      setSportList(sportResponse.data)
    }
    getSport()
  }, [])

  const handleSport = async (event) => {
    setEventList([]);
    setEventId("");   
    setMatchID("");   
    setFancyTypeList([]);
    setFancyResultTypeList([]);
    setFancyId("");
    setFancyResult("");
    setOperatorId([]);
    formik.values.result = "";
    const sport = event.value;
    setSport(event);
    const eventResponse = await getCaller(`api/v1/events/findEvents?id=${sport}`);
    setEventList(eventResponse.data);
  }

  const handleEvent = async (event) => {
    setFancyId("");
    setFancyResultTypeList([]);
    setOperatorId([]);
    formik.values.result = ""
    const Event = event.value;
    setEventId(event);

    if(Event){
      const matchID = ((eventList || []).find(m => m.event_id === Event))?.id;
      setMatchID(Event)
    }

    setFancyTypeList([])
  
    setFancyResult(null)
    if (Event === "") {
      setEventId("")
      setFancyTypeList([])
      setFancyResult(null)
      return;
    }
    setFancyTypeList([
      { value: "line_markets", name: "Line Markets" },
      { value: "session_markets", name: "Session Markets" },
      { value: "over_by_over_session_markets", name: "Over by Over Session Markets" },
      { value: "ball_by_ball", name: "Ball By Ball" },
      { value: "total_advance", name: "Total Advance" },
      { value: "fall_of_wicket", name: "Fall of Wicket" },
      { value: "meter_markets", name: "Meter Markets" },
      { value: "khado_markets", name: "Khado Markets" },
      { value: "odd_even_markets", name: "Odd Even Markets" },
      { value: "other_markets", name: "Other Markets" },
      { value: "exchange_multi_runner_markets", name: "Exchange Multi Runner Markets" },
      // { value: "sportsbook_multi_runner_markets", name: "Sportsbook Multi Runner Markets" },
      { value: "under_over_multi_runner_markets", name: "Under Over Multi Runner Markets" }
    ]);
  }

  const handleFancyType = async (event) => {
  setFancyResultTypeList([]);
  setFancyResult('');
  setOperatorId([]);
  formik.values.result = ""
  
  if (event?.value) {
    const fancy = event.value;
    setFancyId(event);

    try {
      const response = await getCaller(`api/v1/fancies/undeclared/${fancy}/match/${eventId?.value}`);
      setFancyResultTypeList(response.data);
    } 
    catch (error) {
      console.error("Failed to fetch fancy types:", error);
    }
  } 
  else {
    setFancyId(null);
    setFancyResultTypeList([]);
  }
}


  const handleFancyResultType = (event) => {
    setFancyResult(event);

    setOneMarketList([
      { id: "even", name: "Even" },
      { id: "odd", name: "Odd" },
      { id: "abandoned", name: "Abandoned" },
    ]);

    if (event) {
      const allOperators = operatorList.map(op => ({ value: op, label: op }));
      setOperatorId(allOperators);
    }
  }

  useEffect(() => {
    async function getOperators() {
      const res = await getCaller(`api/v1/results/operatorList`);
      const formattedOperators = res.data.operatorList;
      setOperatorList(formattedOperators);    
    }
    getOperators();
  }, []);

  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map(el => ({ value: el, label: el }))];
  const handleOperator = (selectedOptions) => {
    if (Array.isArray(selectedOptions)) {
      if (selectedOptions.some(option => option.value === 'all')) {
        if (selectedOptions.length === options.length) {
          setOperatorId([]);
        } else {
          setOperatorId(options.slice(1));
        }
      } else {
        setOperatorId(selectedOptions);
      }
    } else {
      setOperatorId([]);
    }
  };
  
  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  };
  
  useEffect(() => {
    allFancyResult()
  }, [])

  const allFancyResult = async (page = 1, sportId = '') => {
    if (fetchedPages[page]) {
      setResultData(fetchedPages[page]);
      if (page !==1) return;
  }
    const resultResponse = await getCaller(`api/v1/results/fancy?page=${page}&limit=${limit}&sport_id=${sportId}`)
    setResultData(resultResponse.data)
    setPagination(resultResponse.pagination)
    setFetchedPages((prev) => ({ ...prev, [page]: resultResponse.data }));
    if(fancyId!==""&&eventId!==""){
      const response = await getCaller(`api/v1/fancies/undeclared/${fancyId.value}/match/${eventId.value}`)
      setFancyResultTypeList(response.data)
    }
  }

  function isNumeric(value) {
    return /^\d+$/.test(value);
  }

  const formik = useFormik({
    initialValues: {
      result: "",
    },

    onSubmit: async (values, { resetForm }) => {
      if (!['odd_even_markets', 'exchange_multi_runner_markets', 'sportsbook_multi_runner_markets', 'under_over_multi_runner_markets'].includes(fancyId?.value) && (!values.result || (values.result !== 'abandoned' && !isNumeric(values.result)))) {
        showToast(`Only numeric values or 'abandoned' are allowed.`, true)
        return;
      }

      values.sport_id = parseInt(sport.value)
      values.event_id = parseInt(eventId.value)
      values.fancy_id = parseInt(fancyResult.id)

      const data = {
        sport_id: parseInt(sport.value),
        match_id: eventList.find(e => e.event_id === eventId.value).id,
        fancy_id: parseInt(fancyResult.id),
        result: values.result,
        operators_list: operatorId.map(op => op.value),
        market_name: fancyResultTypeList.find(e => e.id === parseInt(fancyResult.id)).market_name,
        selection: ""
      };

      await showConfirmationDialog(`You want to Submit this Result (${formik.values.result})?`)
      .then(async (result) => {
        if (result.isConfirmed) {
          setCallRef(true)
          const response = await postCaller(`api/v1/results/`, data)
          if (response.success) {
            showSuccessMessage(response.message)
            setFancyResultTypeList(f => {
              return [...f.filter(f => f.id !== data.fancy_id)]
            })
            htmlElRef?.current?.focus();

            setOneMarketList([])
            setCallRef(true)
            setFancyResult('')
            resetForm()
            allFancyResult(1)
            setOperatorId([]);
          } else {
            showToast (response.message , true)
          }
        }
      })
    },
  });

  useEffect(() => {
    if (callRef) {
      if (htmlElRef.current.Select || htmlElRef.current) {
        htmlElRef.current.setValue("");
      }
      setCallRef(false);
    }
  }, [callRef]);

  const handleResult = (event) => {
    setResult(event);
    formik.setFieldValue("result", event);
  };
  
  return (
    <>
      <Result />
      <ResultTab />
      <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
        <form onSubmit={(e) => { e.preventDefault(); setOpen(true) }}>
          <div className="result-sport-container">
            <div className="result-sport-content className-fix flexFix-width justify-start">
              <div className="result-event-select">
                <label htmlFor="date"> Select Sport </label> <br />
                <Select placeholder="Select Sport" value={sport} onChange={handleSport} options={sportList.map((el) => ({ value: el.id, label: el.name }))} ref={sportRef} />
              </div>

              <div className="result-event-select">
                <label htmlFor="date"> Select Events </label> <br />
                <Select placeholder="Select Event" value={eventId} onChange={ handleEvent} options={eventList.map((el) => ({ value: el.event_id, label: `${el.event_name} (${((el.event_date).split('.')[0])})` }))} ref={eventRef} />
              </div>

              <div className="result-event-select">
                <label htmlFor="date"> Select Market </label> <br />
                <Select placeholder="Select Market" value={fancyId} onChange={handleFancyType} options={fancyTypeList.map((el) => ({ value: el.value, label: el?.name ?? 'n/a' }))} ref={marketRef} />
              </div>

              <div className="result-event-select" >
                <label htmlFor="date"> Select Fancy </label> <br />
                <Select placeholder="Select Fancy" value={fancyResult} isClearable onChange={async (event) => { handleFancyResultType(event, fancyResult) }} tabIndex={0} ref={htmlElRef}
                  options={fancyResultTypeList}  getOptionLabel={o => o.market_name || o.id } getOptionValue={o => o.market_name || o.id}/>
              </div>
              {
                fancyId?.value === 'odd_even_markets' ? (
                  <div className="result-event-select">
                    <label htmlFor="date"> Select Result </label> <br />
                    <select name='result' className='select-fields' value={formik.values.result} onChange={formik.handleChange}>
                      <option value={""}>Select Result</option>
                      {oneMarketList.map((el, i) => <option value={el.id} key={i}> {el.name} </option>)}
                    </select>
                  </div>
                ) : (
                  fancyResult?.has_multi_runners === 1 ?
                  <div className="result-select-body fancy-result-select-body">
                    <label htmlFor="date"> Result </label> <br />
                    <Select id='result-dropdown' value={result ? { value: result, label: result } : null} onChange={(selectedOption) => handleResult(selectedOption.value)}
                      options={(() => {
                        const odds = typeof fancyResult.odds === "string" ? JSON.parse(fancyResult.odds) : fancyResult.odds;
                        return odds.map((e) => ({ value: e.selection_name, label: e.selection_name }));
                      })()}
                    /> 
                  </div> :
                  <div className="result-event-select widthSmall">
                    <label htmlFor="date"> Result </label> 
                    <input type="text" autoComplete="off" name="result" className='select-fields' id="" disabled={!fancyId} ref={resultRef} value={formik.values.result } onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.handleChange(event);
                        let inputValue = event.target.value;

                        if (inputValue === "a") inputValue = "abandoned";
                        formik.setFieldValue("result", inputValue);
                      }}
                    />
                  </div>
                )
              }
              <div className="result-event-select hFix-100">
                <label htmlFor="operators">Select Operators</label> <br />
                <Select tabIndex={-1} isMulti value={operatorId} onChange={handleOperator} options={getOptions(operatorId)}/>
              </div>
            </div>
            <div className="book-maker-btn">
              <Button className='submit' type='button' name='Submit Result' disabled={!(formik.values.result && formik.values.result !== 0 && operatorId.length && ( scope?.userType !== 'manager' || scope?.result?.add) && fancyResult)} onClick={() => { setOpen(true) }} />
            </div>
          </div>
        </form>
        <FancyResultTable resultData={resultData} setResultData={setResultData} setEventId={setEventId}
          allFancyResult={allFancyResult}
          pagination={pagination}
          setFancyId={setFancyId}
          setFancyResult={setFancyResult}
          sportList={sportList}
          setFetchedPages={setFetchedPages}
        />
      </motion.div>
      <SurveillanceModel isOpen={isOpen} setOpen={setOpen} onCloseModel={formik.handleSubmit} matchID={matchID} marketID={fancyResult?.external_id} marketType={3}></SurveillanceModel>
    </>
  )
};

export default FancyResult;