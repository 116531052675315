import '../downline.css';
import moment from 'moment-timezone';
import { IoMdSave } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDelete, MdPostAdd } from 'react-icons/md';
import FancyAssignAddOdds from './FancyAssignAddOdds';
import Button from '../../../component/button/Button';
import { handleKeyDown } from '../../../utilities/handleKeyDown';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import { postCaller, deleteCaller, updateCaller } from '../../../services/api';
import { showConfirmationDialog, showToast } from '../../../utilities/toastHelper';

const FancyAdd = (event) => {
    const [formMode, setFormMode] = useState('Add');
    const [fancyID, setFancyID] = useState(null);
    const [allowAddBookmaker, setAllowAddBookmaker] = useState(true);
    const [endDate, setEndDate] = useState(null);
    const [fancyAddOddsComponents, setFancyAddOddsComponents] = useState([]);;
    const [scope, setScope] = useState({});
    const [isAdded,setIsAdded] = useState(false);
    const [newOdds, setNewOdds] = useState({});

    const generateInitialOdds = (market_type) => {
        // const timestampInSeconds = new Date().getTime();
        // const selectionID = timestampInSeconds / 1000 + Math.floor(Math.random() *1000);
        const selectionID = new Date().getTime();

        if (market_type === "khado_markets") {
            return {
                size: '',
            };
        } else if (market_type === "odd_even_markets") {
            return {
                back:'',
                lay: '',
            };
        } else if (market_type === "exchange_multi_runner_markets") {
            return [{
                selection_name: '',
                runNo: '',
                runYes: '',
                oddsNo: '',
                oddsYes: '',
                suspend: 0,
                si:parseInt(selectionID)
            }];
        } else if (market_type === "sportsbook_multi_runner_markets") {
            return [{
                selection_name: '',
                back: '',
                suspend: 0,
                si:parseInt(selectionID)
            }];
        } else if (market_type === "under_over_multi_runner_markets") {
            return [{
                selection_name: '',
                line: '',
                over: '',
                under: '',
                suspend: 0,
                si:parseInt(selectionID)
            }];
        } else {
            return {
                runNo: '',
                runYes: '',
                oddsNo: '',
                oddsYes: '',
            }
        }
    };

    const [fancyData, setFancyData] = useState({
        event_id: event.event.event_id,
        match_id: event.event.id,
        sport_id: '',
        market_type: event.marketType,
        agent_id: event.uid,
        is_sus: event.suspend,
        is_active: 0,
        is_sportsbook : 0,
        is_com: event?.defaultLimits?.is_com || 0,
        msg: '',
        min_stake: event?.defaultLimits?.min_stake || 100,
        max_stake: event?.defaultLimits?.stake_limit || 0,
        bet_delay: event?.defaultLimits?.delay || 0,
        max_market_limit: event?.defaultLimits?.max_market_limit || 0,
        market_name: '',
        odds: generateInitialOdds(event.marketType),
        end_date: null,
        sus_time: event.susTime,
        has_multi_runners: ["exchange_multi_runner_markets", "sportsbook_multi_runner_markets", "under_over_multi_runner_markets"].includes(event.marketType) ? 1 : 0
    });

    useEffect(() => {
        const { markets, result } = getLocalData('scope');
        setScope({ markets, result });
    }, []);

    useEffect(() => {
        setFancyData((prevData) => ({
            ...prevData,
            is_sus: event.suspend
        }))
    }, [event.suspend]);

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setFancyData({ ...fancyData, end_date: date });
    };

    const handleSubmit = async () => {        
        const formattedEndDate = endDate ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') : null;

        if (formMode === 'Add') {
            let fancyOddsData = {}, updatedFancyData = {};
            
            const selectionValues = Object.values(newOdds);
                        
            if (selectionValues.length) {
                updatedFancyData = { ...fancyData };
                updatedFancyData.odds = [...updatedFancyData.odds];
                updatedFancyData.odds.push(...selectionValues);
                fancyOddsData = updatedFancyData.odds;
            } else {
                fancyOddsData = fancyData.odds;
                updatedFancyData = fancyData;
            }

            const response = await postCaller('api/v1/fancies', {
                event_id: event.event.event_id,
                match_id: event.event.id,
                sport_id: event.sport,
                market_type: event.marketType,
                agent_id: event.uid,
                is_sus: fancyData.is_sus,
                is_sportsbook : fancyData.is_sportsbook,
                is_active: fancyData.is_active,
                is_com: fancyData.is_com,
                msg: fancyData.msg,
                min_stake: fancyData.min_stake,
                max_stake: fancyData.max_stake,
                bet_delay: fancyData.bet_delay,
                max_market_limit: fancyData.max_market_limit,
                market_name: fancyData.market_name,
                odds: fancyOddsData,
                end_date: formattedEndDate,
                sus_time: event.susTime,
                has_multi_runners: fancyData.has_multi_runners
            });

            if (response.success) {
                showToast("Fancy Added Successfully.");
                setFancyData(updatedFancyData);
                const fancyId = response.data.fancyID;
                setFancyID(fancyId);
                setFormMode('Update');
                setFancyAddOddsComponents([]);
                setNewOdds({});
            } else {
                showToast(response.message, true);
            }
        } else if (formMode === 'Update') {
            const response = await updateCaller(`api/v1/fancies/${fancyID}`, {
                is_sus: fancyData.is_sus,
                is_active: fancyData.is_active,
                is_com: fancyData.is_com,
                is_sportsbook : fancyData.is_sportsbook,
                msg: fancyData.msg,
                min_stake: fancyData.min_stake,
                max_stake: fancyData.max_stake,
                bet_delay: fancyData.bet_delay,
                max_market_limit: fancyData.max_market_limit,
                market_name: fancyData.market_name,
                odds: fancyData.odds,
                end_date: formattedEndDate,
                sus_time: event.susTime
            });

            if (response.success) {
                showToast("Fancy Updated Successfully.");
            } else {
                showToast(response.message, true);
            }
        }
        setIsAdded(true);
    };

    const handleDelete = async(item) => {
        !isAdded && setIsAdded(false);
        if (!fancyID) {
            setAllowAddBookmaker(false);
            return
        }
        await showConfirmationDialog("You are about to delete a fancy market.")
        .then(async (result) => {
            if (result.isConfirmed){
                const deleteResponse =await deleteCaller(`api/v1/fancies/${fancyID}`)
                if(deleteResponse.success===true){
                    showToast("Your fancy market has been deleted.");
                    setAllowAddBookmaker(false)
                }
            }})
    };

    const handleOddsInputChange = (oddsIndex, field, value) => {
        const updatedOdds = [...fancyData.odds]; 
        const inputValue = field === 'selection_name' ? value : value;
      
        if ( field === 'selection_name' ||  inputValue >= 0) {
            if (!updatedOdds[oddsIndex]) {
                updatedOdds[oddsIndex] = {};
            }
            updatedOdds[oddsIndex][field] = inputValue;
            setFancyData({ ...fancyData, odds: updatedOdds });
        }

        if (field === 'suspend') {
            const message = value === 1 ? "Suspend Enabled" : "Suspend Disabled";
            showToast(message);
        }
    };

    const addFancyOdds = (index) => {
        // const timestampInSeconds = new Date().getTime();
        // const selectionID = timestampInSeconds / 1000 + Math.floor(Math.random() * 1000);
        const selectionID = new Date().getTime();

        setFancyAddOddsComponents((prevData) => ({
            ...prevData,
            [index]: [
                ...(prevData[index] || []),
                <FancyAssignAddOdds
                    key={prevData[index] ? prevData[index].length : 0}
                    market_type={fancyData.market_type}
                    si={parseInt(selectionID)}
                    onAddOdds={(newOdds) => handleAddOdds(index, newOdds)}
                    onRemoveOdds={() => removeFancyOdds(index, prevData[index] ? prevData[index].length : 0)}
                    setNewOdds={setNewOdds}
                    isSportsBook={fancyData.is_sportsbook ? true : false}
                    fancyId={fancyID}
                />
            ]
        }));
    };

    const removeFancyOdds = async(index, key) => {
        setFancyAddOddsComponents((prevData) => {
            const updatedData = { ...prevData };
            updatedData[index] = updatedData[index].filter((_, i) => i !== key);
            return updatedData;
        });
    };

    const handleAddOdds = async(index, runnerOdds) => {        
        const updatedFancyData = { ...fancyData };
        updatedFancyData.odds = [...updatedFancyData.odds];
        updatedFancyData.odds.push(runnerOdds);
        setFancyData(updatedFancyData);
        
        if(!fancyID){
            console.log('add the fancyID');
            return;
        }

        const formattedEndDate = endDate ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') : null;

        const response = await updateCaller(`api/v1/fancies/${fancyID}`,{
            is_sus: updatedFancyData.is_sus,
            is_active: updatedFancyData.is_active,
            is_com: updatedFancyData.is_com,
            is_sportsbook : updatedFancyData.is_sportsbook,
            msg: updatedFancyData.msg,
            min_stake: updatedFancyData.min_stake,
            max_stake: updatedFancyData.max_stake,
            bet_delay: updatedFancyData.bet_delay,
            max_market_limit: updatedFancyData.max_market_limit,
            market_name: updatedFancyData.market_name,
            odds:updatedFancyData.odds,
            end_date: formattedEndDate,
            sus_time: event.susTime
        });

        if(response.success){
            showToast("Fancy Updated Successfully.");
            setFancyAddOddsComponents([]);
            setNewOdds({});
        }
    };

    const handleDeleteOdds = async (item, oddsIndex) => {
        await showConfirmationDialog(`You are about to delete this fancy Selection Odds.`)
        .then(async (result) => {
            if (result.isConfirmed){
                const updatedFancyData = {...fancyData};
                updatedFancyData.odds = updatedFancyData.odds.filter((_, index) => index !== oddsIndex);
                setFancyData(updatedFancyData);
                await handleSubmit(item)
            }
        })
    };
    
    const handleInputChange = (field, value) => {  
        setFancyData((prevData) => ({
            ...prevData,
            odds: {
                ...prevData.odds,
                [field]: field === 'oddsNo' && value < 0 ? prevData.odds.oddsYes : value,
            },
        }));
    };
    
    const handleOddsChange = (field, value) => {
        setFancyData((prevData) => ({
            ...prevData,
            odds: {
                ...prevData.odds,
                [field]: field === 'oddsYes' && value < 0 ? prevData.odds.oddsYes : value,
            },
        }));
    };

    const handleFancyData = (key, value) => {
        let filterData = (value.replace(/[^0-9]/g, ''))
        setFancyData({ ...fancyData, [key]: filterData })
    };

    const handleToggleChange = (isEnabled) => {
        const updatedFancyData = { ...fancyData };
        updatedFancyData.is_sportsbook = isEnabled ? 1 : 0;

        if (isEnabled) {
            updatedFancyData.odds = [...updatedFancyData.odds];

            for (let i = 0; i < updatedFancyData.odds.length; i++) {
                updatedFancyData.odds[i]['oddsNo'] = '';
                updatedFancyData.odds[i]['oddsYes'] = '';
                updatedFancyData.odds[i]['runNo'] = '';
            }
        }

        setFancyData(updatedFancyData);      

        setNewOdds((prev) =>
            Object.fromEntries(
                Object.entries(prev).map(([key, val]) => [
                    key,
                    { ...val, oddsNo: "", oddsYes: "", runNo: "" },
                ])
            )
        );
        
        setFancyAddOddsComponents((prevState) =>
            Object.keys(prevState).reduce((acc, key) => ({
                ...acc,
                [key]: prevState[key]?.map((item) => ({
                    ...item,
                    props: {
                        ...item.props,
                        isSportsBook: isEnabled ? true : false,
                    },
                })),
            }), {})
        );
        
        showToast(isEnabled ? "Sportsbook Enabled" : "Sportsbook Disabled");
    };

    return (<>
        <div>
        { allowAddBookmaker && !["exchange_multi_runner_markets", "sportsbook_multi_runner_markets", "under_over_multi_runner_markets"].includes(fancyData.market_type) ?
        <div>
            <table width="100%">
                <thead>
                    <tr  id='fancy-head-coloumn'>
                        <th> SELECTION </th>
                        <th> STAKE </th>
                        <th> DELAY </th>
                        <th> END DATE </th>
                        {!["khado_markets", "odd_even_markets"].includes(fancyData.market_type) && (
                            <>
                                <th>NO (OUTCOME)</th>
                                <th>YES (OUTCOME)</th>
                                <th>NO (ODDS)</th>
                                <th>YES (ODDS)</th>
                            </>
                        )} 
                        {fancyData.market_type === "khado_markets" && <th>SIZE</th>}

                        {fancyData.market_type === "odd_even_markets" && (
                            <>
                                <th>BACK</th>
                                <th>LAY</th>
                            </>
                        )}
                        <th> SUSPEND </th>
                        <th> COMM </th>
                        <th> ACTIVE </th>
                        <th> ACTIONS </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='fancy-head-row'>
                        <td >
                            <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={fancyData.market_name} onChange={(e) => setFancyData({ ...fancyData, market_name: e.target.value })} placeholder='Fancy Name' /> <br />
                            <input type="text" autoComplete="off" name="msg"  className='fancy-stake-input' value={fancyData.msg} onChange={(e) => setFancyData({ ...fancyData, msg: e.target.value })} placeholder='Message' />
                        </td>

                        <td >
                            <input type="number" inputMode="numeric" autoComplete="off" name="min_stake" className='fancy-stake-input' value={fancyData.min_stake} onKeyDown={handleKeyDown} onChange={(e) =>{ const value = e.target.value;
                            if (value >= 0) {
                                handleFancyData('min_stake', value);
                            }}} placeholder="Min Stake"  disabled/> <br />

                            <input type="number" inputMode="numeric" autoComplete="off" name="max_stake" className='fancy-stake-input' value={fancyData.max_stake} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleFancyData('max_stake', value);
                            }}} placeholder='Max Stake' size="5" />
                        </td>

                        <td >
                            <input type="number" inputMode="numeric" autoComplete="off" name="bet_delay" className='fancy-stake-input' value={fancyData.bet_delay} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleFancyData('bet_delay', value);
                            }}} placeholder='Bet Delay ' /> <br />

                            <input type="number" inputMode="numeric" autoComplete="off" name="max_market_limit" className='fancy-stake-input' value={fancyData.max_market_limit} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleFancyData('max_market_limit', value);
                            }}} placeholder={'Max Market Limit'} />
                        </td>
                        <td>
                        <DatePicker
                            name="end_date"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText="End Date & Time"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className='fancy-stake-input'
                            onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                        /> <br />
                        </td>
                        
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" className='fancy-input' value={fancyData.odds.runNo} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleOddsChange('runNo', value);
                            }}} name="runNo" />
                        </td>)}

                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="runYes" className='fancy-input' value={fancyData.odds.runYes} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleOddsChange('runYes', value);
                            }}}/>
                        </td>)}

                        {!["khado_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="size" className='fancy-input' value={fancyData.odds.size} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleOddsChange('size', value);
                            }}}/>
                        </td>)}

                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="back" className='fancy-input' value={fancyData.odds.back} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleOddsChange('back', value);
                            }}}/>
                        </td>)}

                        {!["odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="lay" className='fancy-input' value={fancyData.odds.lay} onKeyDown={handleKeyDown} onChange={(e) => { const value = e.target.value;
                            if (value >= 0) {
                                handleOddsChange('lay', value);
                            }}}/>
                        </td>)}
                        
                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="oddsNo" className='fancy-input' value={fancyData.odds.oddsNo} onKeyDown={handleKeyDown} onChange={(e) => handleInputChange('oddsNo', e.target.value)}/>
                        </td>)}

                        {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : ( <td>
                            <input type="number" inputMode="numeric" autoComplete="off" name="oddsYes" className=' fancy-input' value={fancyData.odds.oddsYes} onKeyDown={handleKeyDown} onChange={(e) => handleOddsChange('oddsYes', e.target.value)}/>
                        </td>)}

                        <td>
                            <div className="toggle-password">
                                <ToogleButton  defaultChecked={fancyData.is_sus===1}  value={fancyData.is_sus} onChange={(e) => setFancyData({ ...fancyData, is_sus: e ? 1 : 0 })} />
                            </div>
                        </td>

                        <td>
                            <div className="toggle-password">
                                <ToogleButton defaultChecked={fancyData.is_com===1}  value={fancyData.is_com} onChange={(e) => setFancyData({ ...fancyData, is_com: e ? 1 : 0 })} />
                            </div>
                        </td>

                        <td>
                            <div className="toggle-password">
                                <ToogleButton defaultChecked={fancyData.is_active===1}  value={fancyData.is_active} onChange={(e) => setFancyData({ ...fancyData, is_active: e ? 1 : 0 })} />
                            </div>
                        </td>

                        <td>
                            <div className="toggle-password">
                                <div className='actions_tab'>
                                    <div className='fancy-submit'>
                                        {formMode === 'Add' ? (
                                            <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }} />
                                        ) : (
                                            <IoMdSave tabIndex={0} className="icon" onClick={handleSubmit} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }} />
                                        )}
                                    </div>

                                    <div className='fancy-cancel'>
                                        <MdDelete tabIndex={0} className="icon" onClick={handleDelete} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete() } }} />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> : null}
        </div>
        <div>
            { 
                allowAddBookmaker && ["exchange_multi_runner_markets", "sportsbook_multi_runner_markets", "under_over_multi_runner_markets"].includes(fancyData.market_type) ?
                    <div>
                        <table width="100%">
                            <div>
                                <>
                                    <div className="market-book-maker-border" style={{"background-color": '#f1f8ff'}}>
                                        <div className='market-bookmaker-popup' >
                                            <div className="">
                                                <label htmlFor="marketName">Market Name</label> <br />
                                                <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={fancyData.market_name} onChange={(e) => setFancyData({ ...fancyData, market_name: e.target.value })} placeholder='Fancy Selection Name' /> <br />
                                                
                                                <label htmlFor="stake_limit">Min Stake</label> <br />
                                                <input type="number" inputMode="numeric" autoComplete="off" name="min_stake" className='fancy-stake-input' value={fancyData.min_stake} onKeyDown={handleKeyDown} onChange={(e) => handleFancyData('min_stake', e.target.value)} placeholder="Min Stake" /> <br />
                                                
                                                <label htmlFor="stake_limit">Max Stake</label> <br />
                                                <input type="number" inputMode="numeric" autoComplete="off" name="max_stake" className='fancy-stake-input' value={fancyData.max_stake} onKeyDown={handleKeyDown} onChange={(e) => handleFancyData('max_stake', e.target.value)} placeholder='Max Stake' size="5" /><br />
         
                                            </div>
                                            <div style={{"bottom": "4px", "position": "relative" }}>
                                                
                                                <label htmlFor="marketName"></label> <br />
                                                <label htmlFor="marketName"></label> <br />
                                                <label htmlFor="marketName"></label> <br />
                                                
                                                <label htmlFor="msg">Message</label> <br />
                                                <input type="text" autoComplete="off" name="msg" className='fancy-stake-input' value={fancyData.msg} onChange={(e) => setFancyData({ ...fancyData, msg: e.target.value })} placeholder='Message' /><br />
                                                
                                                <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                                                <input type="number" inputMode="numeric" autoComplete="off" name="max_market_limit" className='fancy-stake-input' value={fancyData.max_market_limit} onKeyDown={handleKeyDown} onChange={(e) => handleFancyData('max_market_limit', e.target.value)} placeholder={'Max Market Limit'} /><br />
                                            </div>
                    
                                            <div>
                                                <div className="suspend-container">
                                                    {fancyData.market_type === "exchange_multi_runner_markets" &&
                                                        <div className="suspend-container-body">
                                                            <p className='suspend-para'>Sportsbook</p>
                                                            <ToogleButton defaultChecked={fancyData.is_sportsbook === 1} value={fancyData.is_sportsbook} disabled={formMode === 'Add' ? false : true} onChange={(e) => handleToggleChange(e)} />
                                                        </div>
                                                    }
                                                    <div className="suspend-container-body"><p className='suspend-para'>Suspend</p><ToogleButton defaultChecked={fancyData.is_sus===1} value={fancyData.is_sus} 
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_sus: newValue });
                                                            showToast(newValue ? "Suspend Enabled" : "Suspend Disabled");
                                                        }}/>
                                                    </div>

                                                    <div className="suspend-container-body"><p className='suspend-para'>Commission</p><ToogleButton defaultChecked={fancyData.is_com===1} value={fancyData.is_com} 
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_com: newValue });
                                                            showToast(newValue ? "Commission Enabled" : "Commission Disabled");
                                                        }}/>   
                                                    </div>

                                                    <div className="suspend-container-body"><p className='suspend-para'>Active</p><ToogleButton defaultChecked={fancyData.is_active===1} value={fancyData.is_active} 
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_active: newValue });
                                                            showToast(newValue ? "Active Enabled" : "Active Disabled");
                                                        }}/>  
                                                    </div>

                                                    <div className="toggle-password">
                                                        <div className='actions_tab'>
                                                            <button className='fancy-submit' disabled={isAdded && !scope?.markets?.update}>
                                                                { 
                                                                    formMode === 'Add' ? ( 
                                                                        <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                    ) : (
                                                                        <IoMdSave className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                    )
                                                                }
                                                            </button>
                                                            <button className='fancy-cancel' disabled={isAdded && !scope?.markets?.delete}>
                                                                <MdDelete className="icon" onClick={handleDelete} disabled={isAdded && !scope?.markets?.delete} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete() } }}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <label htmlFor=""></label> <br />
                                                <div style={{"top": "11px", "position": "relative" }}>
                                                    <label htmlFor="bet_delay">Bet delay</label> <br />
                                                    <input type="number" inputMode="numeric" autoComplete="off" name="bet_delay"  className='fancy-stake-input' value={fancyData.bet_delay} onKeyDown={handleKeyDown} onChange={(e) => handleFancyData('bet_delay', e.target.value)} placeholder='Bet Delay' /> <br />
                                                </div>
                                                <div style={{"top": "12px", "position": "relative" }}>
                                                    <label htmlFor="end_date">End Date</label> <br />
                                                    <DatePicker
                                                        name="end_date"
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        placeholderText="End Date & Time"
                                                        timeIntervals={15}
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        className='fancy-stake-input'
                                                        value={fancyData?.end_date != null ? moment(fancyData.end_date).format('YYYY-MM-DD HH:mm:ss') : ""}
                                                        /> <br />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div style={{ overflowX: "auto" }}>
                                            <div className="book-maker-match">
                                                <div className="book-input-submit-1" >
                                                    <div>
                                                        <Button className='submit' type={"button"} name={"ADD FANCY ODDS"}  onClick={() => addFancyOdds(-1)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                                            <table width="100%">
                                                <thead id='fancy-head-coloumn'>
                                                    <tr className=''>
                                                        <th align='left'>SELECTION NAME</th>
                                                        {fancyData.market_type === "under_over_multi_runner_markets" ? (
                                                            <>
                                                                <th>LINE</th>
                                                                <th>UNDER</th>
                                                                <th>OVER</th>
                                                            </>
                                                        ) : fancyData.market_type === "sportsbook_multi_runner_markets" ? (
                                                            <>
                                                                <th>BACK</th>
                                                            </>
                                                        ) : !["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? (
                                                            <>
                                                                <th>NO (OUTCOME)</th>
                                                                <th>YES (OUTCOME)</th>
                                                                <th>NO (ODDS)</th>
                                                                <th>YES (ODDS)</th>
                                                            </>
                                                        ) : null}
                                                        <th> SUSPEND </th>
                                                        <th> ACTION </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        fancyData.odds.map((oddsItem, oddsIndex) => (
                                                            <tr key={oddsIndex}>
                                                            <td>
                                                                <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={oddsItem.selection_name || ''} onChange={(e) => handleOddsInputChange(oddsIndex, 'selection_name', e.target.value)} placeholder='Selection Name' />
                                                            </td>
                                                            {fancyData.market_type === "under_over_multi_runner_markets" ? (
                                                                <>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="line" className="fancy-input" value={oddsItem.line} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'line', e.target.value)} placeholder="Line"/></td>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="under" className="fancy-input" value={oddsItem.under} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'under', e.target.value)} placeholder="Under"/></td>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="over" className="fancy-input" value={oddsItem.over} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'over', e.target.value)} placeholder="Over"/></td>
                                                                </>
                                                            ) : fancyData.market_type === "sportsbook_multi_runner_markets" ? (
                                                                <>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="back" className="fancy-input" value={oddsItem.back} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'back', e.target.value)} placeholder="Back"/></td>
                                                                </>
                                                            ) : !["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? (
                                                                <>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="runNo" className=' fancy-input' value={oddsItem.runNo} disabled={fancyData.is_sportsbook} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'runNo', e.target.value)} placeholder='runNo'/></td>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="runYes" className="fancy-input" value={oddsItem.runYes} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'runYes', e.target.value)} placeholder='runYes'/></td>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off"  name="oddsNo" className="fancy-input" value={oddsItem.oddsNo} disabled={fancyData.is_sportsbook} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'oddsNo', e.target.value)} placeholder='oddsNo'/></td>
                                                                    <td><input type="number" inputMode="numeric" autoComplete="off" name="oddsYes" className="fancy-input" value={oddsItem.oddsYes} disabled={fancyData.is_sportsbook} onKeyDown={handleKeyDown} onChange={(e) => handleOddsInputChange(oddsIndex, 'oddsYes', e.target.value)} placeholder='oddsYes'/></td>
                                                                </>
                                                            ) : null}

                                                            <td>
                                                                <div className="toggle-password">
                                                                    <ToogleButton defaultChecked={oddsItem.suspend===1} value={oddsItem.suspend} onChange={(e) => handleOddsInputChange(oddsIndex, 'suspend', e ? 1 : 0 )} />
                                                                </div>
                                                            </td>
                                                            
                                                            <td>
                                                                <div className="toggle-password">
                                                                    <div className='actions_tab'>
                                                                        <button className='fancy-submit' disabled={isAdded && !scope?.markets?.update}>
                                                                            {
                                                                                formMode === 'Add' ? (
                                                                                    <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                                ) : (
                                                                                    <IoMdSave className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                                )
                                                                            }
                                                                        </button>

                                                                        <button className='fancy-cancel' disabled={isAdded && !scope?.markets?.delete}>
                                                                            <MdDelete className="icon" onClick={() => handleDeleteOdds(fancyData, oddsIndex)} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>  
                                            </div>
                                            <div className="bookmaker-table-container" >
                                                {
                                                    Object.keys(fancyAddOddsComponents).map((key) => (
                                                        <div key={key}> {
                                                            fancyAddOddsComponents[key]?.map((component, idx) => (
                                                            <div key={idx}>{component}</div>))}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </table>
                    </div>
                : null
            }
        </div>
        </>
    )
};

export default FancyAdd;