import '../pagination/page.css';
import { useLocation } from 'react-router-dom';
import React, {useEffect ,useState} from 'react';
import { showToast } from '../../utilities/toastHelper';

 const Paginations = ({page, perPage, setPage, setPerPage, maximo}) => {
  const [input, setInput] = useState (1);
  const [inputPerPage, setInputPerPage] = useState(perPage);
  const location = useLocation();
  const resultTabData = {  route: "/results/racing" }
  
  useEffect(() => {
    setInput(page);
  }, [page]);

  useEffect(() => {
    setInputPerPage(perPage);
  }, [perPage]);


  const nextPage = () => {
    setInput (parseInt(input) + 1);
    setPage (parseInt(page) + 1);
  };

  const previousPage = () => {
    setInput (parseInt(input) - 1);
    setPage (parseInt(page) - 1);
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      setPage (parseInt (e.target.value));
      if (
        parseInt (e.target.value < 1) ||
        parseInt (e.target.value) > Math.ceil (maximo) ||
        isNaN (parseInt (e.target.value))
      ) {
        setPage (1);
        setInput (1);
      } else {
        setPage (parseInt (e.target.value));
      }
    }
  };

  const onChange = e => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setInput (value);
  };

  
  const onChangePerPage = (e) => {
    const newPerPage = e.target.value;
    if(newPerPage > 200){
      showToast('Limit should not be more than 200', true);
    } else if (newPerPage > 0) {
        setPerPage(newPerPage);
    }
  };

  const onBlurPerPage = () => {
    setPerPage(inputPerPage);
    setPage(1); 
  };

  return (
    <div className='custom-page' style={{ justifyContent: 'space-between', marginLeft: '4px' }}>
        <label  >{`Total page ${input} of  ${maximo} `}</label> 
      <div className='page-container' >
      {  location.pathname === resultTabData.route ? null : <>
        <label>Rows per page:</label>
      <input
         onChange={onChangePerPage}
         onBlur={onBlurPerPage} 
        name="page"
        autoComplete="off"
        value={inputPerPage}
        className="custom-input"
        style={{width: "10%"}}
      /> </> }
      <button to="#" disabled={page === 1 || page < 1} onClick={previousPage}>
       Prev
      </button>
      <input
        // onChange={e => onChange (e)}
        // onKeyDown={e => onKeyDown (e)}
        name="page"
        autoComplete="off"
        value={input}
        className="custom-input"
        style={{width: "10%"}}
        readOnly
      />
      {/* <p> de {maximo} </p> */}
      <button to="#"
        disabled={page === Math.ceil (maximo) || page > Math.ceil (maximo)}
        onClick={nextPage}
      >
      Next
      </button>
    </div>
    </div>
  );
};
export default Paginations