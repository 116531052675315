import moment from 'moment';
import LogTab from './LogTab';
import '../addEvent/addevent.css';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { postCaller } from '../../services/api';
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { selectStyles } from '../../services/utils';
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast } from '../../utilities/toastHelper';
import { CustomInput } from '../../utilities/customInput';
import Pagination from '../../component/pagination/Pagination';

const UserLogs = () => {
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').startOf('day').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [selectedType, setSelectedType] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 100;

    const handleDate = (date) => {
        const formattedDate = moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
        return formattedDate.format("YYYY-MM-DD");
    };

    const typeOptions = [
        { value: 'admin_password', label: 'Admin Password' },
        { value: 'manager_password', label: 'Manager Password' },
        { value: 'agent_password', label: 'Agent Password' },
        { value: 'bookmaker_add', label: 'Bookmaker Add' },
        { value: 'bookmaker_remove', label: 'Bookmaker Remove' },
        { value: 'fancy_add', label: 'Fancy Add' },
        { value: 'fancy_remove', label: 'Fancy Remove' },
    ];

    useEffect(() => {
        getUserLogs(); /* eslint-disable-next-line */
    }, []);

    const getUserLogs = async (page = 1) => {
        if (page > 1 && fetchedPages[page]) {
            setLogs(fetchedPages[page]);
            return;
        }

        const data = {
            from: handleDate(startDate),
            to: handleDate(endDate),
            ...(selectedType && { type: selectedType.value }),
            ...(searchText && { id: searchText }), 
        };

        const response = await postCaller(`api/v1/user-logs?page=${page}&limit=${limit}`, data);
        if (response.success) {
            setLogs(response.data);
            setTotalPage(response?.pagination?.totalPages);
            setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
        } else {
            showToast(response.message, true);
        }
    };

    useEffect(() => {
        setFetchedPages({});
    }, [startDate, endDate, selectedType, searchText]);

    const handleDatesChange = async (dates) => {        
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const getLogTypeLabel = (logType) => {
        const option = typeOptions.find((type) => type.value === logType);
        return option ? option.label : logType;
    };

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await getUserLogs(page);
        }
    };

    return (
        <>
            <div className="wrapper">
                <LogTab />

                <div className="result-sport-container">
                    <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px' }}>
                        <div className="select-container containerStyle" style={{ marginLeft: '8px', maxWidth: '240px' }}>
                            <label htmlFor="date" id="date-label">Select Date</label> <br />
                            <DatePicker
                                selected={startDate}
                                onChange={handleDatesChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                customInput={<CustomInput />}
                            />
                        </div>

                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="sport">Select Type</label> <br />
                            <Select
                                styles={selectStyles}
                                placeholder="Select Type"
                                options={typeOptions}
                                value={selectedType}
                                onChange={handleTypeChange}
                                isClearable={true}
                            />
                        </div>

                        <div className="select-container" style={{ width: '200px' }}>
                            <label htmlFor="search">Search by ID</label> <br />
                            <input
                                type="search"
                                autoComplete="off"
                                placeholder="Enter ID"
                                className="result-input"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>

                        <div className="wrapper-content">
                            <div className="custom-btn" style={{ marginTop: '20px' }}>
                                <Button className='submit' type='button' name='Search' onClick={async() => await getUserLogs(1)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-border" style={{ padding: '0px' }}>
                    <div className="wrapper-body" >
                        <div className="wrapper-content" style={{ width: "100%" }}>
                            <h2>List of User Logs</h2>
                        </div>
                    </div>

                    {logs.length === 0 ? (
                        <ErrorBoundary />
                    ) : (
                        <div className='tableResponsive-wrap' >
                            <table width="100%" className="odds-gap-table-client">
                                <thead>
                                    <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                        <th>Manager ID</th>
                                        <th>Agent ID</th>
                                        <th>Log Type</th>
                                        <th>Description</th>
                                        <th>IP Address</th>
                                        <th>Log Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.map((log, index) => (
                                        <tr key={log.id}>
                                            <td>{log.manager_id}</td>
                                            <td>{log.agent_id}</td>
                                            <td style={{whiteSpace: 'nowrap'}}>{getLogTypeLabel(log.log_type)}</td>
                                            <td>{log.log_data}</td>
                                            <td>{log.ip_address}</td>
                                            <td style={{whiteSpace: 'nowrap'}}>{moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
            </div>
        </>
    );
};

export default UserLogs;