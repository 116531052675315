import React, { useEffect, useState, useRef } from 'react'
import { getCaller, postCaller } from '../../services/api';
import ResultTab from './WorliMatkaResultTab';
import { motion } from "framer-motion";
import MatchResultTable from './MatchResultTable';
import Button from '../../component/button/Button';
import Select from 'react-select';
import moment from 'moment';
import CONSTANTS from '../../utilities/constants';
import Result from '../result/result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showConfirmationDialog, showSuccessMessage } from '../../utilities/toastHelper';
import { disableFutureDates } from '../../services/utils';

const WorliMatkaMatchResult = () => {
    const [matchList, setMatchList] = useState([]);
    const [match, setMatch] = useState([]);
    const [matchType, setMatchType] = useState([]);
    const [selectedMatches, setSelectedMatches] = useState(0)
    const [matchTypesList, setMatchTypesList] = useState([])
    const [selectedMatchTypes, setSelectedMatchTypes] = useState("")
    const [resultValue, setResultValue] = useState("")
    const [resultData, setResultData] = useState([])
    const [operatorList, setOperatorList] = useState([]);
    const [operatorId, setOperatorId] = useState([]);
    const [matchDate, setMatchDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'))
    const matchSelectRef = useRef();
    const matchTypeSelectRef = useRef();
    const [totalPage, setTotalPage] = useState(0)
    const [fetchedPages, setFetchedPages] = useState({});
    const [scope, setScope] = useState({})
    const  limit = 100;

    useEffect(() => {
        const fetchData = async () => {
            const matchListResponse = await getCaller(`api/v1/worli/matches/${selectedMatches}/markets/matchtype/${matchDate}`);

            if (matchListResponse.success) {
                setMatchTypesList(matchListResponse.data)
            } else {
                showToast(matchListResponse.message, true)
            }
        };
        
        if (selectedMatches !== 0) {
            fetchData()
        }
    }, [selectedMatches]);

    useEffect(() => {
        const { result } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({result, userType})
        async function getMatchDataCb() {
            await getMatchData()
        }
        getMatchDataCb()
    }, [])

    useEffect(() => {
        async function getOperators() {
          const res = await getCaller(`api/v1/worli/operatorList`);
          const formattedOperators = res.data.operatorList;
          setOperatorList(formattedOperators);    
        }
        getOperators();
      }, []);


    const handleMatchTypes = async (event) => {
        setMatchType('')
        setResultValue('')
        setMatch(event.value)
        const selectedMatch = (event != 0) ? event.value : event
        setSelectedMatches(selectedMatch)
        setOperatorId([]);
    }


    const handleMatchTypesSelected = (event) => {
        setResultValue('')
        const matchTypeSelected = event.value
        setMatchType(matchTypeSelected)
        setSelectedMatchTypes(matchTypeSelected)
        setOperatorId([]);
        
    }

    const getMatchData = async (event) => {
        let dateToBeConsidered = `${new Date(matchDate).getFullYear()}-${(new Date(matchDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(matchDate).getDate().toString().padStart(2, '0')}`;
        if (event) {
            const matchDate = event.target.value;
            const formattedDate = `${new Date(matchDate).getFullYear()}-${(new Date(matchDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(matchDate).getDate().toString().padStart(2, '0')}`;
            dateToBeConsidered = formattedDate;
            setMatchDate(formattedDate);
        }
        
        const response = await getCaller(`api/v1/worli/matches/${dateToBeConsidered}/pendingresult`)

        if (response.success == true) {
            setMatchList(response.data)
        } else if (event) {
            showToast (response.message , true)
        }
    }

    const handleMatches = async (event) => {
        setMatchList([])
        setMatch('')
        setMatchType('')
        setMatchTypesList([])
        setResultValue('')
        setSelectedMatches(0)
        await getMatchData(event)
    }

    async function handleResultAdd() {
        if (CONSTANTS.WORLI.ALLOWED_RESULT.length > 0 && (!CONSTANTS.WORLI.ALLOWED_RESULT.includes(parseInt(resultValue)))) {
            const title = 'Invalid Result Value';
            showToast(title, true);
            return;
        }

        const data = {
            matchTime: matchDate, 
            result: resultValue,
            operators_list: operatorId.map(op => op.value),
        }

        await showConfirmationDialog(`You want to Submit this Result (${resultValue})`).then(async (result) => {
            if (result.isConfirmed) {
                const response = await postCaller(`api/v1/worli/matches/result/${selectedMatchTypes}`,data)
                if (response.success) {
                    // setMatchList([]);
                    setSelectedMatches(0);
                    setMatchTypesList([]);
                    setSelectedMatchTypes("");
                    setResultValue("");
                    // setMatchDate("");
                    await getMatchData();

                    const title = "Match result has been added";
                    showSuccessMessage(title);
                    await allResult();
                }
                else {
                    showToast(response.message, true)
                }
            }
        })
    }

    useEffect(() => {
        allResult()
    }, [])

    const allResult = async (page = 1) => {
        if (fetchedPages[page] ) {
            setResultData(fetchedPages[page]);
            if(page !==1) return
        }
        const resultResponse = await getCaller(`api/v1/worli/result/all?page=${page}&limit=${limit}`)
        const filteredData =resultResponse.data.result.sort((a, b) => b.id - a.id)
        setResultData(filteredData)
        setTotalPage(resultResponse.pagination?.totalPages)
        setFetchedPages((prev) => ({ ...prev, [page]: filteredData }));
        matchSelectRef.current.setValue(0);
        matchTypeSelectRef.current.setValue("");
    }
    
    const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map(el => ({ value: el, label: el }))];
    const handleOperator = (selectedOptions) => {
        if (Array.isArray(selectedOptions)) {
        if (selectedOptions.some(option => option.value === 'all')) {
            if (selectedOptions.length === options.length) {
            setOperatorId([]);
            } else {
            setOperatorId(options.slice(1));
            }
        } else {
            setOperatorId(selectedOptions);
        }
        } else {
        setOperatorId([]);
        }
    };
    
    const getOptions = (selectedOptions) => {
        if (selectedOptions?.length === operatorList.length) {
        return operatorList.map((el) => ({ value: el, label: el }));
        }
        return options;
    };

    const handleResult = (event) => {
        try {
            const selectedResult = event ? (event?.target.value) : ''

            if (/^\d*$/.test(selectedResult)) {
                setResultValue(selectedResult);
                setOperatorId([]);

                if (selectedResult) {
                    const allOperators = operatorList.map(op => ({ value: op, label: op }));
                    setOperatorId(allOperators);
                }
            }
        } catch (error) {
            console.error("Failed to parse result:", error);
        }
    };

    /*
    const selectStyles ={
        control: (provided, state) => ({
            ...provided,
            outline: "none",
            height: "2.5rem",
            marginTop: "0.4rem",
            border: "2px solid #cbcbcb",
            borderRadius: "0.25rem",
            width: "100%"
        }),
    }
    */

    return (
        <>  
            <Result />
            <ResultTab />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content className-fix flexFix-width justify-start">
                        <div className="result-event-select">
                            <label htmlFor="date"> Date </label> <br />
                            <input type='date' autoComplete="off" name='event_date' style= {{ marginTop: "0px" }} value={matchDate} max={disableFutureDates()} onChange={(event) => { handleMatches(event) }} className='select-field' placeholder='YYYY/MM/DD' onKeyDown={(e) => e.preventDefault()}/>
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Match </label> <br />
                            <Select placeholder="Select Match" value={match ? { value: match, label: matchList.find(el => el.id === match)?.match_title } : null} ref={matchSelectRef} onChange={(selectedOption) => handleMatchTypes(selectedOption)}
                                options={matchList.map(match => ({
                                    value: match.id,
                                    label: `${match.match_title}`
                                }))}
                                isSearchable={true}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Match Type </label> <br />
                            <Select placeholder="Select Match Type" value={matchType ?  { value: matchType, label: matchTypesList.find(el => el.id === matchType)?.match_type } : null} ref={matchTypeSelectRef} onChange={(selectedOption) => handleMatchTypesSelected(selectedOption.value ? selectedOption : "")}
                                options={matchTypesList.map(matchType => ({
                                    value: matchType.id,
                                    label: `${matchType.match_type}  ( ${matchType?.match_time})`
                                }))}
                                isSearchable={true}
                            />
                        </div>

                        <div className="result-event-select" >
                            <label htmlFor="date"> Match Result </label> <br />
                            <input type='number' style={{ width: "100%", marginTop: "0px", fontSize: "medium" }} autoComplete="off" className='select-field' value={resultValue} placeholder='Enter Result' onChange={handleResult} onKeyDown={(e) => {
                                if (e.key === '-' || e.key === '+' || e.key === 'e') {
                                    e.preventDefault();
                                }
                            }} />
                        </div>

                        <div className="result-event-select hFix-100">
                            <label htmlFor="operators">Select Operators</label> <br />
                            <Select
                                tabIndex={-1}
                                isMulti
                                value={operatorId}
                                onChange={handleOperator}
                                options={getOptions(operatorId)}
                            />
                        </div>
                    </div>
                    <div className="book-maker-btn">
                        <Button className='submit' type='button' name='Submit Result' disabled={!(resultValue !== "" && matchType && (scope?.userType !== 'manager' || scope?.result?.add))} onClick={handleResultAdd} />
                    </div>
                </div>
                <MatchResultTable resultData={resultData} setResultData={setResultData} allResult={allResult} totalPage={totalPage}/>
            </motion.div>
        </>
    )
};

export default WorliMatkaMatchResult;