import { getCaller } from "../../services/api";
import { AiOutlineClose } from 'react-icons/ai';
import ErrorBoundary from '../../error/ErrorBoundary';
import Pagination from '../../component/pagination/Pagination';
import React, { useEffect, useState, useCallback } from "react";

const BookModal = ({ isOpen, onClose, matchID, marketID, runners }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [books, setBooks] = useState([]);
    const limit = 25;

    const fetchBetDetails = useCallback(async (page) => {
        const type = 1;
        const operator = "CLICKBET";

        try {
            const response = await getCaller(`api/v1/markets/getUserBooksForSportsMarket/${matchID}/${marketID}/${type}/${operator}?page=${page}&limit=${limit}`);
            if (response.success) {
                setBooks(response?.data || []);
                setTotalPages(response?.pagination?.pages || 0);
                setCurrentPage(response?.pagination?.page || 1);
            } else {
                setBooks([]);
                setTotalPages(0);
                setCurrentPage(1);
            }
        } catch (err) {
            console.error("Error fetching book details:", err);
        }
    }, [matchID, marketID, limit]);
    
    useEffect(() => {
        if (isOpen) {
            fetchBetDetails(currentPage);
        }
    }, [isOpen, currentPage, fetchBetDetails]);
    
    const handlePageChange = async (page = 1) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            await fetchBetDetails(page);
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                <div className="modal-content" style={{ position: 'relative', display: 'flex', flexDirection: 'column', minWidth: 'fit-content', width: '50%' }}>
                    <div className="wrapper-body bgstyle">
                        <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>User Book</span>
                            <button className="ai-close closeBtn-modal crossBtn" onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>
                                <AiOutlineClose />
                            </button>
                        </div>
                    </div>

                    <div className='table-responsive'>
                        <table className="odds-gap-table table-border-1">
                            <thead>
                                <tr className="hedStyle">
                                    <th style={{ textAlign: 'center' }}>Client</th>
                                    <th style={{ textAlign: 'center' }}>{runners?.[0]?.RN || ""}</th>
                                    <th style={{ textAlign: 'center' }}>{runners?.[1]?.RN || ""}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {books.length > 0 ? (
                                    books.map((book, index) => {
                                        const bookEntries = Object.entries(book.books);
                                        const bookRunner1 = bookEntries.length > 0 ? bookEntries[0][1] : "-";
                                        const bookRunner2 = bookEntries.length > 1 ? bookEntries[1][1] : "-";

                                        return (
                                            <tr key={book.parent_id}>
                                                <td style={{ textAlign: 'center' }}>{book.userid}</td>
                                                <td style={{ textAlign: 'center', color: bookRunner1 < 0 ? 'red' : 'green' }}>{Math.abs(bookRunner1)}</td>
                                                <td style={{ textAlign: 'center', color: bookRunner2 < 0 ? 'red' : 'green' }}>{Math.abs(bookRunner2)}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: "center", padding: "10px" }}>
                                            <ErrorBoundary text="Book Data" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                    {totalPages > 0 && (
                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    )}
                </div>
            </div>

        </>
    );
};

export default BookModal;
