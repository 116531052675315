import React, { useEffect, useState } from "react";
import ToogleButton from "../../../component/toggleButton/ToogleButton";
import { getCaller, updateCaller, deleteCaller } from "../../../services/api";
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { motion } from "framer-motion";
import '../leagueFromSport/leagueManage.css'
import CONSTANTS from "../../../utilities/constants";
import { handleKeyDown } from '../../../utilities/handleKeyDown';
import getLocalData from "../../../utilities/LocalStorageData.utilities";
import { showSuccessMessage, showConfirmationDialog, showToast} from '../../../utilities/toastHelper';

const MatchAccordian = ({ match, setMatches, matches, index, sportId }) => {
  const [open, setOpen] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [openMain, setOpenMain] = useState(false);
  const [openFancy, setOpenFancy] = useState(false);
  const [openBookmaker, setOpenBookmaker] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const [scope,setScope] = useState({})

  useEffect(()=>{
    const {event} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({event,userType})
  },[])

  const getMarketFromMatches = async () => {
    if (markets.length === 0) {
      const matchResponse = await getCaller(`api/v1/markets/marketFindById?id=${match.id}`)
      setMarkets(matchResponse.data);
    }
    setOpen(!open);
  }

  const handleMarketAccordian = () => {
    setOpenMain(!openMain);

  };

  const handleFancyAccordian = () => {
    setOpenFancy(!openFancy)
  };

  const handleBookmakerAccordian = () => {
    setOpenBookmaker(!openBookmaker)
  };
  const handleMoreOptionsAccordian = () => {
    setMoreOptions(!moreOptions)
  };

  const handleMatchCity = (fieldName, index, value) => {
    let newFormValues = [...matches]
    newFormValues[index][fieldName] = value;
    setMatches(newFormValues)
  }

  const handleMatchStatuses = async (fieldName, index, value, item) => {
    let newFormValues = [...matches];
    const fieldValue = value ? 1 : 0;
    newFormValues[index][fieldName] = fieldValue;
    setMatches(newFormValues);

    let title = "";

    switch (fieldName) {
      case "is_active":
        title = value ? "Active" : "Inactive";
        break;
      case "is_populer":
        title = value ? "Marked popular" : "Marked unpopular";
        break;
      default:
        title = CONSTANTS.LEAGUE_STATUS[fieldName].concat(value ? " Enabled" : " Disabled");
        break;
    };

    try {
      const res = await updateCaller(`api/v1/events/matchActive?id=${item.id}&league_id=${item.league_id}&sport_id=${item.sport_id}`, { [fieldName]: fieldValue });
      if (res) {
        const response = await getCaller(`api/v1/events/matchFindById?id=${match.league_id}`);
        setMatches(response.data);
        showToast(title);
      }
    } catch (error) {
      showToast("Failed to update status. Please try again.", true);
    }
  };

  const updateCity = async (item) => {
    const response = await updateCaller(`api/v1/events/matchActive?id=${item.id}&league_id=${item.league_id}&sport_id=${item.sport_id}`, { city: item.city || '' })

    if (response && response.success) { 
      showToast("City has Changed Successfully", false)
    }
  };
  
  const handleChangeFancy = (fieldName, index, value) => {
    if(value < 0) value = 0;
    match.fancy_limits[index] = { ...match.fancy_limits[index] };
    match.fancy_limits[index][fieldName] = value;
    matches[index] = match;
    setMatches([...matches]);

    /*
    if ((fieldName === "is_active" || fieldName === "is_com") && (value === 0 || value === 1)) {
      let title = value ? (fieldName === "is_active" ? "Market Active" : "Commission Active") : (fieldName === "is_active" ? "Market Inactive" : "Commission Inactive");
      showToast(title, false);
    }
    */

    if (fieldName === "is_com" && (value === 0 || value === 1)) {
      let title = value ? "Commission Active" : "Commission Inactive";
      showToast(title, false);
    }
  }

  const handleChangeBookmaker = (index, field, value) => {
    if(value < 0) value = 0;
    let updatedLimits = { ...matches[index].bookmaker_limits };
    updatedLimits[field] = value;
  
    if (field === 'is_com') {
      let title = value ? "Commission Enabled" : "Commission Disabled";
      showToast(title, false);
    }

    let updatedMatch = { ...matches[index], bookmaker_limits: updatedLimits };
    let updatedMatches = [...matches];
    updatedMatches[index] = updatedMatch;

    setMatches(updatedMatches);
  }

  const handleChangeScore = (index, field, value) => {
    let updatedLimits = { ...matches[index]};
    updatedLimits[field] = parseInt(value);
    let updatedMatch = { ...matches[index], sportsradar_id: parseInt(value) };
    let updatedMatches = [...matches];
    updatedMatches[index] = updatedMatch;
    setMatches(updatedMatches);
  }

  const handleChangeTV = (index, field, value) => {
    let updatedLimits = { ...matches[index]};
    updatedLimits[field] = value;
    let updatedMatch = { ...matches[index], tv_radar_id: value };
    let updatedMatches = [...matches];
    updatedMatches[index] = updatedMatch;
    setMatches(updatedMatches);
  }

  const hasEmptyFields = (jsonObject, limitType) => {
    if (limitType === 'fancy') {
      return Object.entries(jsonObject).some(([key, values]) =>
        Object.values(values).some(value => value === '')
      );
    } else if (limitType === 'bookmaker') {
      return Object.values(jsonObject).some(value => value === '');
    }
    return false;
  };

  const updateMatchLeagueSettings = async (id, limitType) => {
    let jsonObject;

    if (limitType === 'market') {
      jsonObject = JSON.parse(JSON.stringify(match.market_limits));
    } else if (limitType === 'fancy') {
      jsonObject = JSON.parse(JSON.stringify(match.fancy_limits));
    } else if (limitType === 'bookmaker') {
      jsonObject = JSON.parse(JSON.stringify(match.bookmaker_limits));
    } else if (limitType === 'more') {
      jsonObject = {
        sportsradar_id : match.sportsradar_id ,
        tv_radar_id : match.tv_radar_id
      };
    }else {
      console.error('Invalid limit type');
      return;
    }

    if (hasEmptyFields(jsonObject, limitType)) {
      showToast('All fields are mandatory.', true)
      return;
    }

    const endpoint = `api/v1/events/${id.id}/limits/${limitType}`;
    const response = await updateCaller(endpoint, jsonObject);
    if (response) {
      showToast("Market Updated Successfully", false)
    }
  };

  const handleDeleteMatch = async(match) => {
    await showConfirmationDialog(`You want to delete this match?`)
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteCaller(`api/v1/events/${match.id}`)
        if (response.success === true) {
          showSuccessMessage('Your Match is deleted Successfully')
          const newList = [...matches];
          newList.splice(index, 1);
          setMatches(newList)
        }
      }
    })
  }

  const updateMarket = async (j) => {
    const market = markets[j];
    const { odd_limit, is_active, max_market_limit, inplay_stake_limit, inplay_max_odd_limit,max_stake_limit,inplay_max_market_limit, bet_delay } = market;
    const response = await updateCaller(`api/v1/markets/updateMarket?match_id=${match.id}&id=${market.marketId}`, { odd_limit, is_active, max_market_limit, inplay_stake_limit, inplay_max_odd_limit,max_stake_limit,inplay_max_market_limit, bet_delay });

    if (response.success) {
      showToast("Market updated successfully!", false)
    }
  }


  return (
    <div className="match-accordian-body">
    <table width="95%">
      <tbody>
        <tr>  
          <td align="left" width="230px">
          <div onClick={getMarketFromMatches} style={{ display: "flex", alignItems: "center",width:"230px", gap: "5px" }}>   
            <div >
              {!open ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }} />}
            </div>
           <div className="acc-para"  >
              <p >
                {match.event_name ? match.event_name : "Events not available"}
              </p>
            </div>
          </div>
          </td>
          <td align="left" style={{width:"160px"}} >
            <div className="acc-para">
              <p>
                {match.is_custom === 1
                  ? new Date(match?.event_date?.slice(0, match?.event_date?.length - 5)?.replace('T', " ")).toLocaleString('en-GB')
                  : new Date(match?.event_date).toLocaleString('en-GB')}
              </p>
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <h5>👤</h5>
              <p className='para-btn newSty'>{match?.created_by || 'admin'}</p>
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>Active</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.is_active !== 0} onChange={(event) => handleMatchStatuses("is_active", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>Recommended</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)}defaultChecked={match.is_populer !== 0} onChange={(event) => handleMatchStatuses("is_populer", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn' style={{ whiteSpace: 'nowrap' }}>In play</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.inplay_status !== 0} onChange={(event) => handleMatchStatuses("inplay_status", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>B</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_bookmaker !== 0} onChange={(event) => handleMatchStatuses("has_bookmaker", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>F</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_fancy !== 0} onChange={(event) => handleMatchStatuses("has_fancy", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>P</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_premium_fancy !== 0} onChange={(event) => handleMatchStatuses("has_premium_fancy", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>3rd_B</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_3rdparty_Bookmaker !== 0} onChange={(event) => handleMatchStatuses("has_3rdparty_Bookmaker", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>3rd_F</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_3rdparty_Fancy !== 0} onChange={(event) => handleMatchStatuses("has_3rdparty_Fancy", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>Volume check</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.is_odds_check !== 0} onChange={(event) => handleMatchStatuses("is_odds_check", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>Accept Any Odds</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.accept_any_odds !== 0} onChange={(event) => handleMatchStatuses("accept_any_odds", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <div className="table-flex">
              <p className='para-btn'>Has Pool</p>
              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.has_pool !== 0} onChange={(event) => handleMatchStatuses("has_pool", index, event, match)} />
            </div>
          </td>
          <td align="left">
            <input
              type="text"
              autoComplete="off"
              name="city"
              placeholder="City"
              value={match?.city ?? ""}
              onChange={(event) => handleMatchCity("city", index, event.target.value)}
              onBlur={() => updateCity(match)}
              className="city-input"
              disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
            />
          </td>
          
          <td align="left">
            <button className="delete-icon" 
              disabled={scope?.userType === 'manager' && !scope?.event?.delete}>
              <MdDelete
                style={{ fontSize: "1rem", color: "white", border:'none !important' }}
                onClick={() => handleDeleteMatch(match)}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                    e.preventDefault(); handleDeleteMatch(match);
                  }
                }}
              />
            </button>
          </td>
          <td align="left">
            <div className="ten-one">{match.total_markets}</div>
          </td>
        </tr>
      </tbody>
    </table>
  
    {open && (
        <div className="market-accordian-body" style={{ minWidth: "1704px" }}>
        <table width="100%">
          <tbody>
            <tr>
              <td style={{ border: 'none' }}>
                <motion.div
                  className="league-setting-container"
                  style={{ backgroundColor: '#c6c6c6' }}
                  initial={{ opacity: 0, x: '-100vh' }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  <button
                    className={`accordion ${openMain ? 'active' : 'inactive'}`}
                    onClick={handleMarketAccordian}
                  >
                    {!openMain ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} />}
                    Main Market
                  </button>
                  {openMain && (
                    <div className="table-border">
                      <table width="100%">
                        <tbody>
                          {(markets || []).map((market, j) => (
                            <tr className='custom-row' key={market.marketId}>
                              <td>{market.market_name}</td>
                              <td>
                                <ToogleButton
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                    defaultChecked={parseInt(market.is_active) === 1}
                                    onChange={(value) => {
                                    market.is_active = value ? 1 : 0;
                                    setMarkets(old => { old[j] = market; return [...old]; });
                                    updateMarket(j);                                     
                                  }}
                                />
                              </td>
                              <td>
                                <label htmlFor={`stake_limit_${j}`}>Stake Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.max_stake_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.max_stake_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='Stake limit'
                                  className='league-detail-input'
                                  id={`stake_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`odd_limit_${j}`}>Odd Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.odd_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.odd_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='Odd limit'
                                  className='league-detail-input'
                                  id={`odd_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`inplay_stake_limit_${j}`}>Inplay Stake Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.inplay_stake_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.inplay_stake_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='Inplay stake limit'
                                  className='league-detail-input'
                                  id={`inplay_stake_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`inplay_max_odds_limit_${j}`}>Inplay Max Odd Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.inplay_max_odd_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.inplay_max_odd_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='inplay max odd limit'
                                  className='league-detail-input'
                                  id={`inplay_max_market_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`max_market_limit_${j}`}>Max Market Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.max_market_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.max_market_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='Max market limit'
                                  className='league-detail-input'
                                  id={`max_market_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`inplay_max_market_limit_${j}`}>Inplay Max Market Limit</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.inplay_max_market_limit}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.inplay_max_market_limit = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='inplay max market limit'
                                  className='league-detail-input'
                                  id={`inplay_max_market_limit_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                              <td>
                                <label htmlFor={`bet_delay_${j}`}>Delay</label> <br />
                                <input
                                  autoComplete="off"
                                  type='number'
                                  disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(market.is_active) === 0}
                                  value={market.bet_delay}
                                  onChange={(event) => {
                                    const newMarket = { ...market };
                                    newMarket.bet_delay = event.target.value;
                                    setMarkets(old => { old[j] = newMarket; return [...old]; });
                                  }}
                                  onBlur={() => updateMarket(j)}
                                  placeholder='Delay'
                                  className='league-detail-input'
                                  id={`bet_delay_${j}`}
                                  onKeyDown={handleKeyDown}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button
                    className={`accordion ${openFancy ? 'active' : 'inactive'}`}
                    onClick={handleFancyAccordian}
                  >
                    {!openFancy ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} />}
                    Fancy Market
                  </button>
                  {openFancy && (
                    <div className="table-border">
                      <table width="100%">
                        <tbody>
                          {Object.entries(match.fancy_limits).map(([fieldName, keyIndex], j) => {
                            const isFieldActive = parseInt(keyIndex.is_active) === 1;
                            return (
                              <tr className='custom-row' key={j}>
                                <td>{fieldName.split("_").join(" ")}</td>
                                <td>
                                  <ToogleButton
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                    defaultChecked={isFieldActive}
                                    onChange={(value) => handleChangeFancy("is_active", fieldName, value ? 1 : 0)}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`stake_limit_${fieldName}`}>Stake Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.stake_limit}
                                    onChange={(event) => handleChangeFancy('stake_limit', fieldName, event.target.value)}
                                    placeholder='Stake limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`stake_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`odd_limit_${fieldName}`}>Odd Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.odd_limit}
                                    onChange={(event) => handleChangeFancy('odd_limit', fieldName, event.target.value)}
                                    placeholder='Odd limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`odd_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`inplay_stake_limit_${fieldName}`}>Inplay Stake Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.inplay_stake_limit}
                                    onChange={(event) => handleChangeFancy('inplay_stake_limit', fieldName, event.target.value)}
                                    placeholder='Inplay Stake Limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`inplay_stake_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`inplay_max_odds_limit_${fieldName}`}>Inplay Max Odd Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.inplay_max_odd_limit}
                                    onChange={(event) => handleChangeFancy('inplay_max_odd_limit', fieldName, event.target.value)}
                                    placeholder='Inplay Max Odd Limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`inplay_max_odds_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`max_market_limit_${fieldName}`}>Max Market Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.max_market_limit}
                                    onChange={(event) => handleChangeFancy('max_market_limit', fieldName, event.target.value)}
                                    placeholder='Max market limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`max_market_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`inplay_max_market_limit_${fieldName}`}>Inplay Max Market Limit</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.inplay_max_market_limit}
                                    onChange={(event) => handleChangeFancy('inplay_max_market_limit', fieldName, event.target.value)}
                                    placeholder='Inplay Max Market Limit'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`inplay_max_market_limit_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`delay_${fieldName}`}>Delay</label> <br />
                                  <input
                                    autoComplete="off"
                                    type='number'
                                    value={keyIndex.delay}
                                    onChange={(event) => handleChangeFancy('delay', fieldName, event.target.value)}
                                    placeholder='Delay'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive}
                                    id={`delay_${fieldName}`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <label htmlFor={`commission_${fieldName}`}>Commission</label> <br />
                                  <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update) || !isFieldActive} defaultChecked={keyIndex.is_com === 1} onChange={(value) => handleChangeFancy("is_com", fieldName, value ? 1 : 0)} />
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td>
                              <button
                                className='update-league-btn'
                                type='button'
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(match.is_active) === 0}
                                onClick={() => updateMatchLeagueSettings(match, 'fancy')}
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <button
                    className={`accordion ${openBookmaker ? 'active' : 'inactive'}`}
                    onClick={handleBookmakerAccordian}
                  >
                    {!openBookmaker ? <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /> : <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} />}
                    Bookmaker Market
                  </button>
                  {openBookmaker && (
                    <div className="table-border">
                      <table width="100%">
                        <tbody>
                          <tr className='custom-row'>
                            <td>
                              <label htmlFor="stake_limit_bookmaker">Stake Limit</label> <br />
                              <input
                                type='number'
                                autoComplete="off"
                                value={match.bookmaker_limits.stake_limit}
                                onChange={(event) => handleChangeBookmaker(index, 'stake_limit', event.target.value)}
                                placeholder='Stake limit'
                                className='league-detail-input'
                                id="stake_limit_bookmaker"
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="odd_limit_bookmaker">Odd Limit</label> <br />
                              <input
                                type='number'
                                autoComplete="off"
                                value={match.bookmaker_limits.odd_limit}
                                onChange={(event) => handleChangeBookmaker(index, 'odd_limit', event.target.value)}
                                placeholder='Odd Limit'
                                className='league-detail-input'
                                id="odd_limit_bookmaker"
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_stake_limit_bookmaker">Inplay Stake Limit</label> <br />
                              <input
                                autoComplete="off"
                                type='number'
                                value={match.bookmaker_limits.inplay_stake_limit}
                                onChange={(event) => handleChangeBookmaker(index,'inplay_stake_limit',  event.target.value)}
                                placeholder='Inplay Stake Limit'
                                className='league-detail-input'
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update) }
                                id="inplay_stake_limit_bookmaker"
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_odd_limit_bookmaker">Inplay Max Odd Limit</label> <br />
                              <input
                                autoComplete="off"
                                type='number'
                                value={match.bookmaker_limits.inplay_max_odd_limit}
                                onChange={(event) => handleChangeBookmaker(index,'inplay_max_odd_limit',  event.target.value)}
                                placeholder='Inplay Max Odd Limit'
                                className='league-detail-input'
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update) }
                                id="inplay_max_odd_limit_bookmaker"
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="max_market_limit_bookmaker">Max Market Limit</label> <br />
                              <input
                                type='number'
                                autoComplete="off"
                                value={match.bookmaker_limits.max_market_limit}
                                onChange={(event) => handleChangeBookmaker(index, 'max_market_limit', event.target.value)}
                                placeholder='Max market limit'
                                className='league-detail-input'
                                id="max_market_limit_bookmaker"
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="inplay_max_market_limit_bookmaker">Inplay Max Market Limit</label> <br />
                              <input
                                type='number'
                                autoComplete="off"
                                value={match.bookmaker_limits.inplay_max_market_limit}
                                onChange={(event) => handleChangeBookmaker(index, 'inplay_max_market_limit', event.target.value)}
                                placeholder='Inplay Max Market Limit'
                                className='league-detail-input'
                                id="inplay_max_market_limit_bookmaker"
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="delay_bookmaker">Delay</label> <br />
                              <input
                                type='number'
                                autoComplete="off"
                                value={match.bookmaker_limits.delay}
                                onChange={(event) => handleChangeBookmaker(index, 'delay', event.target.value)}
                                placeholder='Delay'
                                className='league-detail-input'
                                id="delay_bookmaker"
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                onKeyDown={handleKeyDown}
                              />
                            </td>
                            <td>
                              <label htmlFor="commission_bookmaker">Commission</label> <br />
                              <ToogleButton disabled={(scope?.userType ==='manager' && !scope?.event?.update)} defaultChecked={match.bookmaker_limits.is_com === 1} onChange={(value) => handleChangeBookmaker(index, "is_com", value ? 1 : 0)} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <button
                                className='update-league-btn'
                                type='button'
                                disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(match.is_active) === 0}
                                onClick={() => updateMatchLeagueSettings(match, 'bookmaker')}
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  
                  <button className={`accordion ${moreOptions ? 'active' : 'inactive'}`}
                    onClick={handleMoreOptionsAccordian}>
                    {!moreOptions ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>}
                    More Options
                  </button>
                  
                    <div>
                      {moreOptions && (
                        <div className="table-border" >
                        <div className={`market-accordian-body`} style={{ minWidth: "1704px" }}>
                          <table width="100%" >
                            <tbody>

                              <tr className='custom-row'>
                                <td>
                                  <label htmlFor="stake_limit">TV Management</label> <br />
                                  <input
                                    type='string'
                                    autoComplete="off"
                                    value={match.tv_radar_id}
                                    onChange={(event) =>
                                      handleChangeTV(index, 'tv_radar_id', event.target.value)
                                    }
                                    placeholder='TV Management'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                  />
                                </td>

                                <td>
                                  <label htmlFor="max_market_limit">Score Management</label> <br />
                                  <input
                                    type='number'
                                    autoComplete="off"
                                    value={match.sportsradar_id}
                                    onChange={(event) =>
                                      handleChangeScore( index,'sportsradar_id', event.target.value)
                                    }
                                    placeholder='Score Management'
                                    className='league-detail-input'
                                    disabled={(scope?.userType ==='manager' && !scope?.event?.update)}
                                  />
                                </td>

                              </tr>

                              <tr>
                                <td> <button className='update-league-btn' type='button' disabled={(scope?.userType ==='manager' && !scope?.event?.update) || parseInt(match.is_active) === 0} onClick={() => updateMatchLeagueSettings(match, 'more')}> Update </button></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        </div>
                      )}

                    </div>

                  
                </motion.div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )}
  </div>
  
  )
}
export default MatchAccordian