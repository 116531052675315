import React from 'react';
import '../result/result.css';
import { useLocation, useNavigate } from 'react-router-dom';

const MarketTab = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const markets = [
        { name: "Sports Market", route: "/my-market/sports-market" }
    ];

    return (
        <div className="wrapper">
            <ul className="tab-container">
                {markets.map((el, i) => (
                    <li key={i} className={`tab-box ${location.pathname === el.route ? 'active-result-tab' : null}`} onClick={() => navigate(el.route)}> {el.name} </li>
                ))}
            </ul>
        </div>
    )
};

export default MarketTab;